import * as Types from '../../../../@types/graphql-types';

import gql from 'graphql-tag';
import { ProfileInfoFragmentDoc } from '../fragments/ProfileInfo.fragment';
import { TextRecommendationFragmentDoc } from '../../../why-to-watch/graphql/fragments/TextRecommendation.fragment';
export type GetProfileByUrlQueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	fullPath?: Types.InputMaybe<Types.Scalars['String']['input']>;
	firstArticles: Types.Scalars['Int']['input'];
	articlesAfter?: Types.InputMaybe<Types.Scalars['String']['input']>;
	firstWtw: Types.Scalars['Int']['input'];
	wtwAfter?: Types.InputMaybe<Types.Scalars['String']['input']>;
	platform?: Types.Platform;
	excludeTextRecommendationTitle?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type GetProfileByUrlQuery = {
	__typename?: 'Query';
	profile?: {
		__typename: 'Profile';
		displayName: string;
		firstName: string;
		lastName: string;
		location?: string | null;
		country: any;
		bio?: string | null;
		avatarUrl?: string | null;
		isComplete: boolean;
		ownedByUser: boolean;
		profileUrl: string;
		profileType: Types.ProfileType;
		id: string;
		articles: {
			__typename?: 'ArticlesConnection';
			totalCount: number;
			pageInfo: { __typename?: 'PageInfo'; endCursor: string; hasNextPage: boolean };
			edges?: Array<{
				__typename?: 'ArticlesEdge';
				node: {
					__typename?: 'Article';
					id: string;
					content: {
						__typename?: 'ArticleContent';
						articleType: Types.ArticleType;
						fullPath: string;
						title?: string | null;
						subtitle?: string | null;
						snippet?: string | null;
						paragraph1?: string | null;
						synopsis?: string | null;
					};
					mainObjects: Array<{
						__typename?: 'ArticleLinkedObject';
						node:
							| { __typename?: 'Article' }
							| { __typename?: 'Author' }
							| { __typename?: 'Bundle' }
							| { __typename?: 'Device' }
							| { __typename?: 'Episode' }
							| {
									__typename: 'GenericTitleList';
									id: string;
									titles: {
										__typename?: 'GenericTitleListConnection';
										totalCount: number;
										edges?: Array<{
											__typename?: 'GenericTitleListEdge';
											nodeV2:
												| {
														__typename: 'Movie';
														id: string;
														content: {
															__typename?: 'MovieContent';
															title: string;
															fullPath: string;
															posterUrl?: string | null;
														};
												  }
												| {
														__typename: 'Season';
														id: string;
														content: {
															__typename?: 'SeasonContent';
															title: string;
															fullPath: string;
															posterUrl?: string | null;
														};
												  }
												| {
														__typename: 'Show';
														id: string;
														content: {
															__typename?: 'ShowContent';
															title: string;
															fullPath: string;
															posterUrl?: string | null;
														};
												  };
										}> | null;
									};
							  }
							| { __typename?: 'Genre' }
							| {
									__typename: 'Movie';
									id: string;
									content: {
										__typename?: 'MovieContent';
										title: string;
										fullPath: string;
										posterUrl?: string | null;
									};
							  }
							| { __typename?: 'MultiStepSportEvent' }
							| { __typename?: 'Offer' }
							| { __typename?: 'Package' }
							| { __typename?: 'Page' }
							| { __typename?: 'Person' }
							| {
									__typename: 'Season';
									id: string;
									content: {
										__typename?: 'SeasonContent';
										title: string;
										fullPath: string;
										posterUrl?: string | null;
									};
							  }
							| {
									__typename: 'Show';
									id: string;
									content: {
										__typename?: 'ShowContent';
										title: string;
										fullPath: string;
										posterUrl?: string | null;
									};
							  }
							| { __typename?: 'SingleStepSportEvent' }
							| { __typename?: 'SportCompetitionV2' }
							| { __typename?: 'SportCompetitorV2' }
							| { __typename?: 'Url' };
					}>;
				};
			}> | null;
		};
		textRecommendations: {
			__typename?: 'ProfileTextRecommendationConnection';
			totalCount: number;
			moviesCount: number;
			showsCount: number;
			pageInfo: { __typename?: 'PageInfo'; endCursor: string; hasNextPage: boolean };
			edges: Array<{
				__typename?: 'ProfileTextRecommendationEdge';
				node: {
					__typename: 'TextRecommendation';
					id: string;
					headline?: string | null;
					body: string;
					originalHeadline?: string | null;
					originalBody?: string | null;
					customProfileType?: string | null;
					watchedAt: any;
					likeCount: number;
					likedByUser: boolean;
					ownedByUser: boolean;
					updatedAt: any;
					tags?: Array<{ __typename?: 'Tag'; technicalName: string; translatedName: string }> | null;
					watchedOn?: {
						__typename?: 'Package';
						clearName: string;
						id: string;
						shortName: string;
						technicalName: string;
						packageId: number;
						selected: boolean;
						monetizationTypes: Array<Types.MonetizationType>;
						icon: string;
						addonParent?: { __typename?: 'Package'; id: string } | null;
					} | null;
					profile: {
						__typename: 'Profile';
						displayName: string;
						firstName: string;
						lastName: string;
						location?: string | null;
						country: any;
						bio?: string | null;
						avatarUrl?: string | null;
						isComplete: boolean;
						ownedByUser: boolean;
						profileUrl: string;
						profileType: Types.ProfileType;
						id: string;
						externalUrls?: Array<{
							__typename?: 'ProfileSpecificLink';
							type: Types.ProfileSpecificLinkType;
							name?: string | null;
							url: string;
						}> | null;
					};
					title?:
						| {
								__typename: 'Movie';
								id: string;
								objectId: number;
								objectType: Types.ObjectType;
								content: {
									__typename?: 'MovieContent';
									title: string;
									posterUrl?: string | null;
									fullPath: string;
									upcomingReleases: Array<{
										__typename?: 'UpcomingRelease';
										releaseDate?: any | null;
										releaseCountDown?: number | null;
									}>;
									scoring: {
										__typename?: 'Scoring';
										imdbScore?: number | null;
										imdbVotes?: number | null;
										tmdbPopularity?: number | null;
										tmdbScore?: number | null;
										jwRating?: number | null;
										tomatoMeter?: number | null;
										certifiedFresh?: boolean | null;
									};
								};
								watchNowOffer?: {
									__typename: 'Offer';
									id: string;
									standardWebURL?: string | null;
									preAffiliatedStandardWebURL?: string | null;
									streamUrl?: string | null;
									retailPrice?: string | null;
									retailPriceValue?: number | null;
									lastChangeRetailPriceValue?: number | null;
									currency?: string | null;
									presentationType: Types.PresentationType;
									monetizationType: Types.MonetizationType;
									availableTo?: any | null;
									dateCreated?: any | null;
									newElementCount?: number | null;
									package: {
										__typename?: 'Package';
										id: string;
										icon: string;
										packageId: number;
										clearName: string;
										shortName: string;
										technicalName: string;
										iconWide: string;
										hasRectangularIcon: boolean;
									};
								} | null;
								availableTo: Array<{
									__typename?: 'AvailableTo';
									availableToDate: any;
									availableCountDown: number;
								}>;
								watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
								customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
								dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
								likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						  }
						| {
								__typename: 'Season';
								id: string;
								objectId: number;
								objectType: Types.ObjectType;
								content: {
									__typename?: 'SeasonContent';
									seasonNumber: number;
									title: string;
									posterUrl?: string | null;
									fullPath: string;
									upcomingReleases: Array<{
										__typename?: 'UpcomingRelease';
										releaseDate?: any | null;
										releaseCountDown?: number | null;
									}>;
									scoring: {
										__typename?: 'Scoring';
										imdbScore?: number | null;
										imdbVotes?: number | null;
										tmdbPopularity?: number | null;
										tmdbScore?: number | null;
										jwRating?: number | null;
										tomatoMeter?: number | null;
										certifiedFresh?: boolean | null;
									};
								};
								show: {
									__typename: 'Show';
									id: string;
									objectId: number;
									objectType: Types.ObjectType;
									content: { __typename?: 'ShowContent'; title: string; fullPath: string };
									watchNowOffer?: {
										__typename: 'Offer';
										id: string;
										standardWebURL?: string | null;
										preAffiliatedStandardWebURL?: string | null;
										streamUrl?: string | null;
										retailPrice?: string | null;
										retailPriceValue?: number | null;
										lastChangeRetailPriceValue?: number | null;
										currency?: string | null;
										presentationType: Types.PresentationType;
										monetizationType: Types.MonetizationType;
										availableTo?: any | null;
										dateCreated?: any | null;
										newElementCount?: number | null;
										package: {
											__typename?: 'Package';
											id: string;
											icon: string;
											packageId: number;
											clearName: string;
											shortName: string;
											technicalName: string;
											iconWide: string;
											hasRectangularIcon: boolean;
										};
									} | null;
									watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									seenState: { __typename?: 'ShowSeenState'; progress: number };
									tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
									customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
								};
								watchNowOffer?: {
									__typename: 'Offer';
									id: string;
									standardWebURL?: string | null;
									preAffiliatedStandardWebURL?: string | null;
									streamUrl?: string | null;
									retailPrice?: string | null;
									retailPriceValue?: number | null;
									lastChangeRetailPriceValue?: number | null;
									currency?: string | null;
									presentationType: Types.PresentationType;
									monetizationType: Types.MonetizationType;
									availableTo?: any | null;
									dateCreated?: any | null;
									newElementCount?: number | null;
									package: {
										__typename?: 'Package';
										id: string;
										icon: string;
										packageId: number;
										clearName: string;
										shortName: string;
										technicalName: string;
										iconWide: string;
										hasRectangularIcon: boolean;
									};
								} | null;
								availableTo: Array<{
									__typename?: 'AvailableTo';
									availableToDate: any;
									availableCountDown: number;
								}>;
								seenState: { __typename?: 'ShowSeenState'; progress: number };
								dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
								likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						  }
						| {
								__typename: 'Show';
								id: string;
								objectId: number;
								objectType: Types.ObjectType;
								content: {
									__typename?: 'ShowContent';
									title: string;
									posterUrl?: string | null;
									fullPath: string;
									upcomingReleases: Array<{
										__typename?: 'UpcomingRelease';
										releaseDate?: any | null;
										releaseCountDown?: number | null;
									}>;
									scoring: {
										__typename?: 'Scoring';
										imdbScore?: number | null;
										imdbVotes?: number | null;
										tmdbPopularity?: number | null;
										tmdbScore?: number | null;
										jwRating?: number | null;
										tomatoMeter?: number | null;
										certifiedFresh?: boolean | null;
									};
								};
								watchNowOffer?: {
									__typename: 'Offer';
									id: string;
									standardWebURL?: string | null;
									preAffiliatedStandardWebURL?: string | null;
									streamUrl?: string | null;
									retailPrice?: string | null;
									retailPriceValue?: number | null;
									lastChangeRetailPriceValue?: number | null;
									currency?: string | null;
									presentationType: Types.PresentationType;
									monetizationType: Types.MonetizationType;
									availableTo?: any | null;
									dateCreated?: any | null;
									newElementCount?: number | null;
									package: {
										__typename?: 'Package';
										id: string;
										icon: string;
										packageId: number;
										clearName: string;
										shortName: string;
										technicalName: string;
										iconWide: string;
										hasRectangularIcon: boolean;
									};
								} | null;
								availableTo: Array<{
									__typename?: 'AvailableTo';
									availableToDate: any;
									availableCountDown: number;
								}>;
								watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
								customlistEntries: Array<{ __typename?: 'TitleListEntry'; createdAt: any }>;
								seenState: { __typename?: 'ShowSeenState'; progress: number };
								tvShowTrackingEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
								dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
								likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						  };
				};
			}>;
		};
		externalUrls?: Array<{
			__typename?: 'ProfileSpecificLink';
			type: Types.ProfileSpecificLinkType;
			name?: string | null;
			url: string;
		}> | null;
	} | null;
};

export type ProfileDataFragment = {
	__typename?: 'Profile';
	bio?: string | null;
	displayName: string;
	avatarUrl?: string | null;
	profileUrl: string;
	id: string;
	externalUrls?: Array<{
		__typename?: 'ProfileSpecificLink';
		type: Types.ProfileSpecificLinkType;
		name?: string | null;
		url: string;
	}> | null;
};

export type ArticlesDataFragment = {
	__typename?: 'ArticlesConnection';
	totalCount: number;
	pageInfo: { __typename?: 'PageInfo'; endCursor: string; hasNextPage: boolean };
	edges?: Array<{
		__typename?: 'ArticlesEdge';
		node: {
			__typename?: 'Article';
			id: string;
			content: {
				__typename?: 'ArticleContent';
				articleType: Types.ArticleType;
				fullPath: string;
				title?: string | null;
				subtitle?: string | null;
				snippet?: string | null;
				paragraph1?: string | null;
				synopsis?: string | null;
			};
			mainObjects: Array<{
				__typename?: 'ArticleLinkedObject';
				node:
					| { __typename?: 'Article' }
					| { __typename?: 'Author' }
					| { __typename?: 'Bundle' }
					| { __typename?: 'Device' }
					| { __typename?: 'Episode' }
					| {
							__typename: 'GenericTitleList';
							id: string;
							titles: {
								__typename?: 'GenericTitleListConnection';
								totalCount: number;
								edges?: Array<{
									__typename?: 'GenericTitleListEdge';
									nodeV2:
										| {
												__typename: 'Movie';
												id: string;
												content: {
													__typename?: 'MovieContent';
													title: string;
													fullPath: string;
													posterUrl?: string | null;
												};
										  }
										| {
												__typename: 'Season';
												id: string;
												content: {
													__typename?: 'SeasonContent';
													title: string;
													fullPath: string;
													posterUrl?: string | null;
												};
										  }
										| {
												__typename: 'Show';
												id: string;
												content: {
													__typename?: 'ShowContent';
													title: string;
													fullPath: string;
													posterUrl?: string | null;
												};
										  };
								}> | null;
							};
					  }
					| { __typename?: 'Genre' }
					| {
							__typename: 'Movie';
							id: string;
							content: {
								__typename?: 'MovieContent';
								title: string;
								fullPath: string;
								posterUrl?: string | null;
							};
					  }
					| { __typename?: 'MultiStepSportEvent' }
					| { __typename?: 'Offer' }
					| { __typename?: 'Package' }
					| { __typename?: 'Page' }
					| { __typename?: 'Person' }
					| {
							__typename: 'Season';
							id: string;
							content: {
								__typename?: 'SeasonContent';
								title: string;
								fullPath: string;
								posterUrl?: string | null;
							};
					  }
					| {
							__typename: 'Show';
							id: string;
							content: {
								__typename?: 'ShowContent';
								title: string;
								fullPath: string;
								posterUrl?: string | null;
							};
					  }
					| { __typename?: 'SingleStepSportEvent' }
					| { __typename?: 'SportCompetitionV2' }
					| { __typename?: 'SportCompetitorV2' }
					| { __typename?: 'Url' };
			}>;
		};
	}> | null;
};

export type ArticleMainObjectTitle_Movie_Fragment = {
	__typename: 'Movie';
	id: string;
	content: { __typename?: 'MovieContent'; title: string; fullPath: string; posterUrl?: string | null };
};

export type ArticleMainObjectTitle_Season_Fragment = {
	__typename: 'Season';
	id: string;
	content: { __typename?: 'SeasonContent'; title: string; fullPath: string; posterUrl?: string | null };
};

export type ArticleMainObjectTitle_Show_Fragment = {
	__typename: 'Show';
	id: string;
	content: { __typename?: 'ShowContent'; title: string; fullPath: string; posterUrl?: string | null };
};

export type ArticleMainObjectTitleFragment =
	| ArticleMainObjectTitle_Movie_Fragment
	| ArticleMainObjectTitle_Season_Fragment
	| ArticleMainObjectTitle_Show_Fragment;

export const ProfileDataFragmentDoc = gql`
	fragment ProfileData on Profile {
		id: uuid
		bio
		displayName
		avatarUrl
		profileUrl
		externalUrls {
			type
			name
			url
		}
	}
`;
export const ArticleMainObjectTitleFragmentDoc = gql`
	fragment ArticleMainObjectTitle on MovieOrShowOrSeason {
		id
		__typename
		content(country: $country, language: $language) {
			title
			fullPath
			posterUrl
		}
	}
`;
export const ArticlesDataFragmentDoc = gql`
	fragment ArticlesData on ArticlesConnection {
		totalCount
		pageInfo {
			endCursor
			hasNextPage
		}
		edges {
			node {
				id
				content {
					articleType
					fullPath
					title
					subtitle
					snippet
					paragraph1
					synopsis
				}
				mainObjects: linkedObjects(priority: 1) {
					node {
						... on MovieOrShowOrSeason {
							...ArticleMainObjectTitle
						}
						... on GenericTitleList {
							id
							__typename
							titles(country: $country, first: 1, sortBy: NATURAL) {
								totalCount
								edges {
									nodeV2 {
										...ArticleMainObjectTitle
									}
								}
							}
						}
					}
				}
			}
		}
	}
	${ArticleMainObjectTitleFragmentDoc}
`;
export const GetProfileByUrlDocument = gql`
	query GetProfileByUrl(
		$country: Country!
		$language: Language!
		$fullPath: String
		$firstArticles: Int!
		$articlesAfter: String
		$firstWtw: Int!
		$wtwAfter: String
		$platform: Platform! = WEB
		$excludeTextRecommendationTitle: Boolean = false
	) {
		profile(fullPath: $fullPath) {
			...ProfileInfo
			articles(
				country: $country
				first: $firstArticles
				after: $articlesAfter
				filter: { articleTypes: NEWS_ARTICLE }
			) {
				...ArticlesData
			}
			textRecommendations(country: $country, language: $language, first: $firstWtw, after: $wtwAfter) {
				totalCount
				moviesCount
				showsCount
				pageInfo {
					endCursor
					hasNextPage
				}
				edges {
					node {
						...TextRecommendation
					}
				}
			}
		}
	}
	${ProfileInfoFragmentDoc}
	${ArticlesDataFragmentDoc}
	${TextRecommendationFragmentDoc}
`;
