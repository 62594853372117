import * as Types from '../../../../@types/graphql-types';

import gql from 'graphql-tag';
export type ProfileInfoFragment = {
	__typename: 'Profile';
	displayName: string;
	firstName: string;
	lastName: string;
	location?: string | null;
	country: any;
	bio?: string | null;
	avatarUrl?: string | null;
	isComplete: boolean;
	ownedByUser: boolean;
	profileUrl: string;
	profileType: Types.ProfileType;
	id: string;
	externalUrls?: Array<{
		__typename?: 'ProfileSpecificLink';
		type: Types.ProfileSpecificLinkType;
		name?: string | null;
		url: string;
	}> | null;
};

export const ProfileInfoFragmentDoc = gql`
	fragment ProfileInfo on Profile {
		__typename
		id: uuid
		displayName
		firstName
		lastName
		location
		country
		bio
		avatarUrl
		isComplete
		externalUrls {
			type
			name
			url
		}
		ownedByUser
		profileUrl
		profileType
	}
`;
