export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string };
	String: { input: string; output: string };
	Boolean: { input: boolean; output: boolean };
	Int: { input: number; output: number };
	Float: { input: number; output: number };
	/**
	 * An https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 country code.
	 *
	 * e.g. 'US', 'GB'
	 */
	Country: { input: any; output: any };
	/**
	 * Represents a date.
	 *
	 * e.g. '2006-01-02'
	 */
	Date: { input: any; output: any };
	/**
	 * Represents a non typed JSON object.
	 *
	 * e.g. '{"foo":"bar"}'
	 */
	Json: { input: any; output: any };
	/**
	 * A https://en.wikipedia.org/wiki/IETF_language_tag language code.
	 *
	 * e.g. 'pt-BR', 'en-US'
	 */
	Language: { input: any; output: any };
	/** Maps an arbitrary GraphQL value to a map[string]interface{} Go type. */
	Map: { input: any; output: any };
	/**
	 * Represents a time as a RFC3339 timestamp.
	 *
	 * e.g. '2006-01-02T15:04:05Z07:00'
	 */
	Time: { input: any; output: any };
};

/** Represents an age certification on JustWatch. */
export type AgeCertification = {
	__typename?: 'AgeCertification';
	/** The unique id for the certificate */
	ageCertificationId: Scalars['Int']['output'];
	/** The objectType in the country responsible for the certification(movie/show). */
	objectType: ObjectType;
	/** The organization in the country responsible for the certification. */
	organization: Scalars['String']['output'];
	/** The age certification technical name. */
	technicalName: Scalars['String']['output'];
};

/** The content types supported by the age certification queries. */
export enum AgeCertificationObjectType {
	/** That's a movie. */
	Movie = 'MOVIE',
	/** That's a show. */
	Show = 'SHOW',
}

/** An aggregated subgenre */
export type AggregatedSubgenre = {
	__typename?: 'AggregatedSubgenre';
	/** The amount of titles */
	count: Scalars['Int']['output'];
	/** The subgenre list */
	subgenre: GenericTitleList;
};

export type AppDev = {
	__typename?: 'AppDev';
	/** The graphQl endpoint of the app dev */
	endpoint: Scalars['String']['output'];
	/** The name of the app dev */
	name: Scalars['String']['output'];
};

/** The ApplicationContextInput to add to an SRA tracking event */
export type ApplicationContextInput = {
	/** The ID of the application, in this case the client. */
	appID?: InputMaybe<Scalars['String']['input']>;
	/** Name of the app - webapp, ios, android, firetv. */
	application?: InputMaybe<Scalars['String']['input']>;
	/** The build name of the appliction */
	build?: InputMaybe<Scalars['String']['input']>;
	/** Extra properties about the client, k:v pairs encoded as json string. */
	extras?: InputMaybe<Scalars['String']['input']>;
	/** Flag to mark test/dev build traffic */
	isTestBuild?: InputMaybe<Scalars['Boolean']['input']>;
	/** The platform of the app - web, mob, tv, srv. */
	platform?: InputMaybe<Scalars['String']['input']>;
	/** The version number of the application. */
	version?: InputMaybe<Scalars['String']['input']>;
};

/** An article is a piece of SEO comment written by an author. */
export type Article = Node & {
	__typename?: 'Article';
	/** The author that wrote the article. */
	author: Author;
	/** The content of the article. */
	content: ArticleContent;
	/** The global node ID. */
	id: Scalars['ID']['output'];
	/** The objects linked to the article. */
	linkedObjects: Array<ArticleLinkedObject>;
	/** Articles about the same objects than the linked objects. */
	linkedObjectsArticles: ArticlesConnection;
};

/** An article is a piece of SEO comment written by an author. */
export type ArticleLinkedObjectsArgs = {
	priority?: InputMaybe<Scalars['Int']['input']>;
};

/** An article is a piece of SEO comment written by an author. */
export type ArticleLinkedObjectsArticlesArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<ArticleFilter>;
	first?: Scalars['Int']['input'];
	offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The article content type. It contains the information of the article. */
export type ArticleContent = {
	__typename?: 'ArticleContent';
	/** The type of the article. */
	articleType: ArticleType;
	/** The image url for the article. */
	coverUrl?: Maybe<Scalars['String']['output']>;
	/** The created_at timestamp of the article. */
	createdAt: Scalars['Time']['output'];
	/** The url of the article. */
	fullPath: Scalars['String']['output'];
	/** The highlight label of the article. */
	highlightLabel?: Maybe<Scalars['String']['output']>;
	/** The highlight title of the article. */
	highlightTitle?: Maybe<Scalars['String']['output']>;
	/** The backdrop that we want to use as an image of the article. */
	mainImageEntityBackdrop?: Maybe<Backdrop>;
	/** A first part of the main content of the article. */
	paragraph1?: Maybe<Scalars['String']['output']>;
	/** A second part of the main content of the article. */
	paragraph2?: Maybe<Scalars['String']['output']>;
	/** A timeline of news leading up to the release. */
	productionNews?: Maybe<Scalars['String']['output']>;
	/** Short summary of the article. */
	snippet?: Maybe<Scalars['String']['output']>;
	/** The providers on which the title is available. */
	streamedOn: Array<Scalars['String']['output']>;
	/** An subtitle for the article. */
	subtitle?: Maybe<Scalars['String']['output']>;
	/** The synopsys of a title. */
	synopsis?: Maybe<Scalars['String']['output']>;
	/** The title of the article. */
	title?: Maybe<Scalars['String']['output']>;
	/** the updated_at timestamp of the article. */
	updatedAt: Scalars['Time']['output'];
	/** Info like who the actors are, what else the director worked on etc. */
	whatToKnow?: Maybe<Scalars['String']['output']>;
	/** Includes streaming information about a title. */
	whereToWatch?: Maybe<Scalars['String']['output']>;
};

/** The article content type. It contains the information of the article. */
export type ArticleContentMainImageEntityBackdropArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<BackdropProfile>;
};

/** Defines the high level type of an entity type. */
export enum ArticleEntityType {
	/** A generic title list. */
	TitleList = 'TITLE_LIST',
}

/** ALlows to filter by a placement of where the article is to be featured */
export enum ArticleFeaturedInType {
	EditorialHighlight = 'EDITORIAL_HIGHLIGHT',
	HomeHighlight = 'HOME_HIGHLIGHT',
	HomeMain = 'HOME_MAIN',
}

/** The main article filter. */
export type ArticleFilter = {
	/** Keep only the articles having the following type. */
	articleTypes?: InputMaybe<Array<ArticleType>>;
	/** Keep only the articles written by the given authors. */
	authorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	/** Keep only the articles which have the following `featured_in` set */
	featuredIn?: InputMaybe<Array<ArticleFeaturedInType>>;
	/** Keep only the articles linked to the following ids. */
	linkedEntityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	/** Keep only the articles linked to the following types. */
	linkedEntityTypes?: InputMaybe<Array<ArticleEntityType>>;
	/** Keep only the articles linked to the EntityID via technical user title list */
	titleListEntryEntityId?: InputMaybe<Scalars['ID']['input']>;
};

/** An object linked to an article. */
export type ArticleLinkedObject = {
	__typename?: 'ArticleLinkedObject';
	node: Node;
	priority: Scalars['Int']['output'];
};

/** Defines the high level type of an article. */
export enum ArticleType {
	/** A news article. */
	NewsArticle = 'NEWS_ARTICLE',
	/** An article reviewing a title. */
	ReviewArticle = 'REVIEW_ARTICLE',
	/** An article written for a detail page. */
	TitleDetailArticle = 'TITLE_DETAIL_ARTICLE',
}

/** A connection object for the articles. */
export type ArticlesConnection = {
	__typename?: 'ArticlesConnection';
	/** The edges for each of the items. */
	edges?: Maybe<Array<ArticlesEdge>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** The total number of items. */
	totalCount: Scalars['Int']['output'];
};

/** An edge object for the articles */
export type ArticlesEdge = {
	__typename?: 'ArticlesEdge';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The node represented by this edge. */
	node: Article;
};

export type AudienceCheckQueryInput = {
	ListIDs: Array<Scalars['String']['input']>;
	UserID: Array<Scalars['String']['input']>;
};

export type AudienceMapResponse = {
	__typename?: 'AudienceMapResponse';
	result: Array<UserMap>;
};

/** Audio technologies. */
export enum AudioTechnology {
	/** Dolby 5.1 audio. */
	Dolby_5Point_1 = 'DOLBY_5_POINT_1',
	/** Dolby Atmos surround sound. */
	DolbyAtmos = 'DOLBY_ATMOS',
	/** Generic 5.1 audio. */
	'5Point_1' = '_5_POINT_1',
}

/**
 * The author type.
 * The author is a person who writes the articles.
 */
export type Author = Node & {
	__typename?: 'Author';
	/** The articles that are written by the author. */
	articles: ArticlesConnection;
	/** The content of the author. */
	content: AuthorContent;
	/** The global node ID. */
	id: Scalars['ID']['output'];
};

/**
 * The author type.
 * The author is a person who writes the articles.
 */
export type AuthorArticlesArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<ArticleFilter>;
	first?: Scalars['Int']['input'];
	offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * The author type.
 * The author is a person who writes the articles.
 */
export type AuthorContentArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
};

/** The author content type. It contains the information of the author. */
export type AuthorContent = {
	__typename?: 'AuthorContent';
	/** The author's alumni */
	alumniOf: Array<Scalars['String']['output']>;
	/** The detailed biography of the author. */
	bio: Scalars['String']['output'];
	/** The author's brand */
	brand: Scalars['String']['output'];
	/** The display name of the author. */
	clearName: Scalars['String']['output'];
	/** The url of the author page. */
	fullPath: Scalars['String']['output'];
	/**
	 * The image source of the author.
	 * The content URL is drectly forwarded without any modification.
	 */
	imageUrl?: Maybe<Scalars['String']['output']>;
	/** A list of languages spoken by the author */
	languagesSpoken: Array<Scalars['Language']['output']>;
	/** The author's occupation */
	occupation: Array<Scalars['String']['output']>;
	/** The publications information of the author. */
	publications?: Maybe<Array<AuthorSpecificLink>>;
	/** Social media handles of the author. */
	socialHandles?: Maybe<Array<AuthorSpecificLink>>;
};

/**
 * The author specific links type.
 * It contains the name and the url of the segment.
 */
export type AuthorSpecificLink = {
	__typename?: 'AuthorSpecificLink';
	/** The name of the segment relevant to the author. */
	name: Scalars['String']['output'];
	/** The link for the segment that can be accessed by public */
	url: Scalars['String']['output'];
};

/** Title Leaving Soon. */
export type AvailableTo = {
	__typename?: 'AvailableTo';
	/** Number of days remaining for the content available in the package */
	availableCountDown: Scalars['Int']['output'];
	/** The last date that the content is available in the package */
	availableToDate: Scalars['Date']['output'];
	/** The related package object. */
	package: Package;
};

/** Title Leaving Soon. */
export type AvailableToAvailableCountDownArgs = {
	country: Scalars['Country']['input'];
};

/** A backdrop image. */
export type Backdrop = {
	__typename?: 'Backdrop';
	/**
	 * The blur hash representation of the backdrop.
	 *
	 * Generated using https://github.com/woltapp/blurhash.
	 */
	backdropBlurHash?: Maybe<Scalars['String']['output']>;
	/** The image url. */
	backdropUrl: Scalars['String']['output'];
};

/** A list of image sizes supported by the scaler for backdrops. */
export enum BackdropProfile {
	/** 480x225 */
	S480 = 'S480',
	/** 640x333 */
	S640 = 'S640',
	/** 1440x750 */
	S1440 = 'S1440',
	/** 1920x900 */
	S1920 = 'S1920',
}

/** Represents a background image for a campaign. */
export type BackgroundImage = {
	__typename?: 'BackgroundImage';
	/** The image URL. */
	imageURL: Scalars['String']['output'];
	/** The image size. */
	size: BackgroundImageSize;
};

/** Represents the background image size. */
export enum BackgroundImageSize {
	/** The large size. */
	Large = 'LARGE',
	/** The medium size. */
	Medium = 'MEDIUM',
	/** The small size. */
	Small = 'SMALL',
}

/** A bucket aggregation key. */
export type BucketAggregationKey = {
	__typename?: 'BucketAggregationKey';
	/** The bucket type of the aggregation denotes the date range category. */
	bucketType: NewDateRangeBucket;
};

/** A bucket package aggregation key. */
export type BucketPackageAggregationKey = {
	__typename?: 'BucketPackageAggregationKey';
	/** The bucket type of the aggregation denotes the date range category. */
	bucketType: NewDateRangeBucket;
	/** The package of the bucket. */
	package: Package;
};

/** An offer package bundle. Defined by Content. */
export type Bundle = Node & {
	__typename?: 'Bundle';
	/** The Content ID of the bundle. */
	bundleId: Scalars['Int']['output'];
	/**
	 * The clear human readable name of the bundle.
	 *
	 * e.g. 'Netflix', 'Amazon Prime'
	 */
	clearName: Scalars['String']['output'];
	/**
	 * The bundle icon URL.
	 * It takes a desired size (called profile) and desired format as parameter.
	 *
	 * e.g. 's40' + 'webp' -> '/icon/207360008/s40/netflix.webp'
	 */
	icon: Scalars['String']['output'];
	/**
	 * The blur hash representation of the icon.
	 *
	 * Generated using https://github.com/woltapp/blurhash.
	 */
	iconBlurHash?: Maybe<Scalars['String']['output']>;
	/** The global node ID. */
	id: Scalars['ID']['output'];
	/** The packages included in the bundle. */
	packages: Array<Package>;
	/** Indicates if the bundle was selected by the user. */
	selected: Scalars['Boolean']['output'];
	/**
	 * The short version of the technical name using 3 letters.
	 *
	 * e.g. 'netflix' -> 'nfx'
	 */
	shortName: Scalars['String']['output'];
	/**
	 * A unique string identifier for the bundle.
	 *
	 * e.g. 'netflix', 'amazonprime'
	 */
	technicalName: Scalars['String']['output'];
};

/** An offer package bundle. Defined by Content. */
export type BundleIconArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<IconProfile>;
};

/** An offer package bundle. Defined by Content. */
export type BundlePackagesArgs = {
	country: Scalars['Country']['input'];
	platform: Platform;
};

/** A subheading of the caught up list. */
export type CaughtUpSubheading = {
	__typename?: 'CaughtUpSubheading';
	/** The unique id of the subheading. */
	technicalName: CaughtUpSubheadingTechnicalName;
	/** The translatable of the subheading. */
	translatable: Scalars['String']['output'];
};

/** The different subheadings possible on the edges of a caught up list. */
export enum CaughtUpSubheadingTechnicalName {
	/** The show is not airing anymore. */
	Finished = 'FINISHED',
	/** The show is coming back (new episode or season). */
	Upcoming = 'UPCOMING',
}

/** The input when clearing / emptying a custom list. */
export type ClearCustomlistInput = {
	/** The list id to clear. */
	id: Scalars['ID']['input'];
};

/** The payload response of a custom list clearing / emptying. */
export type ClearCustomlistPayload = {
	__typename?: 'ClearCustomlistPayload';
	/** The cleared / emptied title list. */
	titleList: GenericTitleList;
};

/** A clip/trailer. */
export type Clip = {
	__typename?: 'Clip';
	/**
	 * The external ID of the trailer/clip.
	 *
	 * e.g '1g3_CFmnU7k' for YouTube.
	 */
	externalId: Scalars['String']['output'];
	/** The name of the clip. */
	name: Scalars['String']['output'];
	/** The provider of the clip/trailer. */
	provider: ClipProvider;
	/** The date at which the clip uploaded */
	publishedAt: Scalars['Time']['output'];
	/**
	 * The absolute URL of the trailer/clip.
	 *
	 * e.g https://www.youtube.com/watch?v=1g3_CFmnU7k
	 */
	sourceUrl: Scalars['String']['output'];
	/** The url of the stream from dailymotion */
	streamUrl?: Maybe<Scalars['String']['output']>;
};

/** The different supported clip/trailer providers. */
export enum ClipProvider {
	/** Dailymotion. */
	Dailymotion = 'DAILYMOTION',
	/** We get money on these :) */
	Videobuster = 'VIDEOBUSTER',
	/** Default one. */
	Youtube = 'YOUTUBE',
}

export type Config = {
	__typename?: 'Config';
	/** The config */
	Config: Scalars['String']['output'];
};

/** The code we're displaying to the user. */
export type ConnectTvInput = {
	/** The code we're displaying to the user. */
	code: Scalars['String']['input'];
};

/** The payload response of the TV account linking endpoint. */
export type ConnectTvPayload = {
	__typename?: 'ConnectTvPayload';
	/** The login id of the linked user. */
	loginId: Scalars['String']['output'];
};

/** The input when creating new custom lists. */
export type CreateCustomlistInput = {
	/** The name of the custom list. */
	name: Scalars['String']['input'];
	/** The visibility of the list, i.e is it public or private. */
	visibility: GenericTitleListVisibility;
};

/** The payload response of a custom list creation. */
export type CreateCustomlistPayload = {
	__typename?: 'CreateCustomlistPayload';
	/** The created title list. */
	titleList: GenericTitleList;
};

/**
 * If the textRecommendation field is null, we just increase the count of recommendations of this entity.
 * If textRecommendation is not null, we add a new text recommendation to the entity.
 */
export type CreateRecommendationInput = {
	/** The user's country. */
	country: Scalars['Country']['input'];
	/** The entity id of the title that the recommendation is related to. */
	jwEntityID: Scalars['String']['input'];
	/** Optional text recommendation. */
	textRecommendation?: InputMaybe<CreateTextRecommendationInput>;
};

export type CreateRecommendationPayload = {
	__typename?: 'CreateRecommendationPayload';
	/** The ID of the text recommendation being mutated - TO BE DEPRECATED */
	id: Scalars['String']['output'];
	/** The newly created text recommendation */
	textRecommendation: TextRecommendation;
};

export type CreateTextRecommendationInput = {
	/** Main body text of the text recommendation */
	body: Scalars['String']['input'];
	/** Headline, short-text of the text recommendation */
	headline: Scalars['String']['input'];
	/** Language - used to determine the locale for tag translations. Defaults to en */
	language?: InputMaybe<Scalars['Language']['input']>;
	/** Tags for the text recommendation. technicalNames are expected */
	tags?: InputMaybe<Array<Scalars['String']['input']>>;
	/** Time at which the entity was watched. Expected format is 2006-01-02T15:04:05.000Z */
	watchedAt: Scalars['Date']['input'];
	/** ID of the package on which this was watched. Note, if ommitted this defaults to netflix */
	watchedOn: Scalars['Int']['input'];
};

/** Represent the creative type of the campaign." */
export enum CreativeType {
	/** The justwatch image creative type. */
	Image = 'IMAGE',
	/** The justwatch_premium_image_creative type. */
	PremiumImage = 'PREMIUM_IMAGE',
	/** The justwatch_premium_video_creative type. */
	PremiumVideo = 'PREMIUM_VIDEO',
	/** The justwatch titlelist creative type. */
	Titlelist = 'TITLELIST',
	/** The justwatch video creative type. */
	Video = 'VIDEO',
}

/** A credit is info about a Person involved in a Movie/Show. */
export type Credit = {
	__typename?: 'Credit';
	/** The character that the Person played in the Movie/Show (ACTOR role). */
	characterName?: Maybe<Scalars['String']['output']>;
	/** The name of the Person. */
	name: Scalars['String']['output'];
	/**
	 * The related person.
	 *
	 * Please use the direct 'name' field instead of using the 'person' when possible to not trigger extra DB queries.
	 */
	person: Person;
	/** The Content person ID. */
	personId: Scalars['Int']['output'];
	/** The role that the Person had in the Movie/Show. */
	role: CreditRole;
};

/** The role that a Person had in a Movie/Show. */
export enum CreditRole {
	/** That's an actor. */
	Actor = 'ACTOR',
	/** That's a director. */
	Director = 'DIRECTOR',
}

/** The input refresh token. */
export type CustomTokenFromRefreshTokenInput = {
	/** A Firebase refresh token. */
	refreshToken: Scalars['String']['input'];
};

/** The payload response of refresh token exchange. */
export type CustomTokenFromRefreshTokenPayload = {
	__typename?: 'CustomTokenFromRefreshTokenPayload';
	/** A Firebase custom token. */
	customToken: Scalars['String']['output'];
};

/** A date aggregation key. */
export type DateAggregationKey = {
	__typename?: 'DateAggregationKey';
	/** The date of the bucket. */
	date: Scalars['Date']['output'];
};

/** A Date range Title filter. . */
export type DateFilter = {
	/** The from value. */
	from?: InputMaybe<Scalars['Date']['input']>;
	/** The to value. */
	to?: InputMaybe<Scalars['Date']['input']>;
};

/** A date/package aggregation key. */
export type DatePackageAggregationKey = {
	__typename?: 'DatePackageAggregationKey';
	/** The date of the bucket. */
	date: Scalars['Date']['output'];
	/** The package of the bucket. */
	package: Package;
};

/** The input when deleting a custom list. */
export type DeleteCustomlistInput = {
	/** The list id to delete. */
	id: Scalars['ID']['input'];
};

/** The payload response of a custom list deletion. */
export type DeleteCustomlistPayload = {
	__typename?: 'DeleteCustomlistPayload';
	/** The impacted list id. */
	id: Scalars['ID']['output'];
};

export type DeleteTextRecommendationInput = {
	/** Country - used for triggering the SSR cache refresher. Defaults to US */
	country?: InputMaybe<Scalars['Country']['input']>;
	/** The ID of the text recommendation being mutated */
	id: Scalars['String']['input'];
	/** Language - used for triggering the SSR cache refresher. Defaults to en */
	language?: InputMaybe<Scalars['Language']['input']>;
};

export type DeleteTextRecommendationPayload = {
	__typename?: 'DeleteTextRecommendationPayload';
	/** The ID of the text recommendation being mutated */
	id: Scalars['String']['output'];
};

/** Represents a user device. */
export type Device = Node & {
	__typename?: 'Device';
	/** The global node ID. */
	id: Scalars['ID']['output'];
	/** The name of the device. */
	name: Scalars['String']['output'];
};

/** A discovery module is a list of recommended titles anchored to a certain category of content. */
export type DiscoveryModule = {
	__typename?: 'DiscoveryModule';
	/** Some arbitrary custom data returned for custom modules. */
	data: Scalars['Map']['output'];
	/** THe UUID of the discovery session. */
	discoverySessionId: Scalars['String']['output'];
	/** The id of the module. */
	id: Scalars['String']['output'];
	/** A bunch of metadata about how the module was generated. */
	metadata: DiscoveryModuleMetadata;
	/** A discovery module template is a description of the 'type' of the module. It's an empty module shell. */
	template: DiscoveryModuleTemplate;
	/** The titles of the module. */
	titles: Array<MovieOrShow>;
};

export type DiscoveryModuleLayout = {
	__typename?: 'DiscoveryModuleLayout';
	mainModules: Array<Module>;
	/** @deprecated Use sponsoredModule instead */
	promotedModule?: Maybe<Module>;
	sponsoredModule?: Maybe<SponsoredModule>;
	spotlightModules: Array<Module>;
};

/** A discovery module metadata contains a bunch of info about how the module was generated. */
export type DiscoveryModuleMetadata = {
	__typename?: 'DiscoveryModuleMetadata';
	/** Information about the search that was used when calculating the module. */
	search: DiscoveryModuleSearchMetadata;
	/** Information useful when tracking on the front-end side. */
	tracking: DiscoveryModuleTrackingMetadata;
};

/** Information about the search that was used when calculating the module. */
export type DiscoveryModuleSearchMetadata = {
	__typename?: 'DiscoveryModuleSearchMetadata';
	contentTypes?: Maybe<Array<Scalars['String']['output']>>;
	excludeGenres?: Maybe<Array<Scalars['String']['output']>>;
	excludeProviders?: Maybe<Array<Scalars['String']['output']>>;
	genres?: Maybe<Array<Scalars['String']['output']>>;
	locale?: Maybe<Scalars['String']['output']>;
	monetizationTypes?: Maybe<Array<Scalars['String']['output']>>;
	offerDateCreatedFrom?: Maybe<Scalars['String']['output']>;
	offerDateCreatedTo?: Maybe<Scalars['String']['output']>;
	personID?: Maybe<Scalars['Int']['output']>;
	providers?: Maybe<Array<Scalars['String']['output']>>;
	releaseYearFrom?: Maybe<Scalars['Int']['output']>;
	releaseYearUntil?: Maybe<Scalars['Int']['output']>;
	scoringFilterTypes: Scalars['Map']['output'];
	sortBy?: Maybe<Scalars['String']['output']>;
};

/** A discovery module template is a description of the 'type' of the module. It's an empty module shell. */
export type DiscoveryModuleTemplate = {
	__typename?: 'DiscoveryModuleTemplate';
	/** The anchor is a subtype of a template in a way. e.g technicalName='MOVIES_GENRE'-> anchor='act' */
	anchor?: Maybe<Scalars['String']['output']>;
	/**
	 * TechnicalName uniquely identifies a module template.
	 * TechnicalName is built this way:
	 * - <namespace>
	 * - <namespace>:<subtype>
	 * - <namespace>:<subtype>:<subtype>
	 * - <namespace>:<subtype>:<subtype>:...
	 * The namespace is the first name part. It exists for every module template.
	 * Subtypes are the following name parts. They are optional. There can be 0 to n subtypes.
	 * ":" is the separator between each name part.
	 * For details on TechnicalName, namespace, subtype see:
	 * https://trello.com/c/gmMbeE37/452-consumer-api-module-template-namespace
	 */
	technicalName: Scalars['String']['output'];
	/** Translations for the module. */
	translations: Scalars['Map']['output'];
};

/** Information useful when tracking on the front-end side. */
export type DiscoveryModuleTrackingMetadata = {
	__typename?: 'DiscoveryModuleTrackingMetadata';
	/** The level of popularity sprinkling. */
	popularitySprinkling?: Maybe<Scalars['String']['output']>;
	/** The commit of the deployed recommender. */
	recommenderCommit?: Maybe<Scalars['String']['output']>;
	/** The deployed model version of the recommender. */
	recommenderModelIteration?: Maybe<Scalars['String']['output']>;
	/**
	 * The tracking tokens given by the recommender are not exposed publicly in this tracking blob.
	 * It gets decorated per title at title decoration time.
	 * We cannot use `json:"-"` because we still need to marshal it and store it in the user profile api.
	 */
	recommenderTrackingTags: Scalars['Map']['output'];
	/** The level of release sprinkling. */
	releaseSprinkling?: Maybe<Scalars['String']['output']>;
};

/** A connection object for a discovery module. */
export type DiscoveryModulesConnection = {
	__typename?: 'DiscoveryModulesConnection';
	/**
	 * Indicates if a sponsored recommendation is currently active for the user.
	 * @deprecated Use 'sponsoredAd' instead.
	 */
	activeCampaign?: Maybe<SponsoredRecommendationCampaign>;
	/** The edges for each of the items. */
	edges?: Maybe<Array<DiscoveryModulesEdge>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** Indicates if there's a Sponsored Recommendation Ad to display. */
	sponsoredAd?: Maybe<SponsoredRecommendationAd>;
};

/** An edge object for a discovery module node. */
export type DiscoveryModulesEdge = {
	__typename?: 'DiscoveryModulesEdge';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The node represented by this edge. */
	node: DiscoveryModule;
};

/** A filter used to refilter dynamically last minute a set of module titles. */
export type DiscoveryModulesPostFilter = {
	/** Keep only the titles having an offer with the given packages. Short names are expected (e.g. 'nfx' for Netflix). */
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
};

/**
 * Ephemeral module is a list of recommended titles at the run time based on certain input
 * which inlcludes locale and user preferred mood.
 */
export type EphemeralModule = {
	__typename?: 'EphemeralModule';
	/** THe UUID of the discovery session. */
	currentDiscoverySessionId: Scalars['String']['output'];
	/** The index id for the current requested session. */
	currentDiscoverySessionIndex: Scalars['Int']['output'];
	/** The list of items recommended to the user based on the Ephemeral selection filters. */
	items: Array<DiscoveryModule>;
};

/** Template input used to fetch the ephemeral module. */
export type EphemeralModuleTemplateInput = {
	/** The anchor is a subtype of a template in a way. e.g technicalName='MOVIES_GENRE'-> anchor='act' */
	anchor?: InputMaybe<Scalars['String']['input']>;
	/** technicalName uniquely identifies a module template. */
	technicalName: Scalars['String']['input'];
};

/** Personalized episode aggregating user data and static Content data. */
export type Episode = MovieOrShowOrSeasonOrEpisode &
	Node &
	Title & {
		__typename?: 'Episode';
		/** The bundles for this title. Calculated from the offers. Depends on the country being set. */
		bundles: Array<PromotedBundleEdge>;
		/** The title data. It is localized by country and language. */
		content: EpisodeContent;
		/** The global node ID. */
		id: Scalars['ID']['output'];
		/** The recent updated time on the packages for the title. Depends on the country being set.  */
		maxOfferUpdatedAt?: Maybe<Scalars['Time']['output']>;
		/** The Content ID of the title. */
		objectId: Scalars['Int']['output'];
		/** The Content type of the title. */
		objectType: ObjectType;
		/** The offer count available on packages for the title. Depends on the country being set.  */
		offerCount: Scalars['Int']['output'];
		/** The offers available on packages for the title. Depends on the country being set. */
		offers: Array<Offer>;
		/** The parent season of this episode. */
		season: Season;
		/** The entry for the episode in the seenlist. */
		seenlistEntry?: Maybe<TitleListEntry>;
		/** The parent show of this episode. */
		show: Show;
		/**
		 * The default offer that should be used to play the title. Can be null if there are no matching offers.
		 *
		 * A default monetization type filter is applied to only keep flatrate/free offers.
		 */
		watchNowOffer?: Maybe<Offer>;
	};

/** Personalized episode aggregating user data and static Content data. */
export type EpisodeBundlesArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized episode aggregating user data and static Content data. */
export type EpisodeContentArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
};

/** Personalized episode aggregating user data and static Content data. */
export type EpisodeMaxOfferUpdatedAtArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized episode aggregating user data and static Content data. */
export type EpisodeOfferCountArgs = {
	country: Scalars['Country']['input'];
	distinctPackages?: Scalars['Boolean']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized episode aggregating user data and static Content data. */
export type EpisodeOffersArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized episode aggregating user data and static Content data. */
export type EpisodeWatchNowOfferArgs = {
	country: Scalars['Country']['input'];
	filter?: WatchNowOfferFilter;
	platform: Platform;
};

/** Unpersonalized episode data common to all the users, as defined by Content. It is localized by country and language. */
export type EpisodeContent = MovieOrShowOrSeasonOrEpisodeContent & {
	__typename?: 'EpisodeContent';
	/**
	 * The main article about this title
	 * @deprecated Use the articles field :)
	 */
	article?: Maybe<Article>;
	articles: Array<Article>;
	/** The episode number. */
	episodeNumber: Scalars['Int']['output'];
	/**
	 * Whether we consider the title as released or not.
	 * @deprecated Use the V2 instead :)
	 */
	isReleased: Scalars['Boolean']['output'];
	/** Whether we consider the title as released or not. */
	isReleasedV2: Scalars['Boolean']['output'];
	/** The date when the title was first released in the country. */
	localizedReleaseDate?: Maybe<Scalars['Date']['output']>;
	/** The date when the title was originally first released. */
	originalReleaseDate?: Maybe<Scalars['Date']['output']>;
	/** The year when the title was originally first released. */
	originalReleaseYear?: Maybe<Scalars['Int']['output']>;
	/** The duration of the title. For shows and seasons, it's the average runtime of an episode. */
	runtime?: Maybe<Scalars['Int']['output']>;
	/** The season number. */
	seasonNumber: Scalars['Int']['output'];
	/** A short description of the title. */
	shortDescription?: Maybe<Scalars['String']['output']>;
	tags?: Maybe<Array<Tag>>;
	/** The localized title of the title. Depends on the language being set. */
	title: Scalars['String']['output'];
	/** The title upcoming releases on streaming or cinema. Sorted by release date. */
	upcomingReleases: Array<UpcomingRelease>;
};

/** Unpersonalized episode data common to all the users, as defined by Content. It is localized by country and language. */
export type EpisodeContentArticlesArgs = {
	articleTypes?: InputMaybe<Array<ArticleType>>;
};

/** Unpersonalized episode data common to all the users, as defined by Content. It is localized by country and language. */
export type EpisodeContentIsReleasedV2Args = {
	country: Scalars['Country']['input'];
};

/** Unpersonalized episode data common to all the users, as defined by Content. It is localized by country and language. */
export type EpisodeContentUpcomingReleasesArgs = {
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	releaseTypes?: InputMaybe<Array<ReleaseType>>;
};

/** The payload response of a legacy JWT token exchange. */
export type ExchangeLegacyJwtPayload = {
	__typename?: 'ExchangeLegacyJwtPayload';
	/** The firebase JWT. */
	firebaseJwt: Scalars['String']['output'];
	/** The login id of the user. */
	loginId: Scalars['Boolean']['output'];
};

/** The experimentGroup object. */
export type ExperimentGroup = {
	/** The id of the experiment. */
	experimentID?: InputMaybe<Scalars['String']['input']>;
	/** The variant of the experiment. */
	experimentVariant?: InputMaybe<Scalars['String']['input']>;
};

/** A set of IDs of the title on external platforms. */
export type ExternalIds = {
	__typename?: 'ExternalIds';
	/** The IMDB ID. */
	imdbId?: Maybe<Scalars['String']['output']>;
	/** The TMDB ID. */
	tmdbId?: Maybe<Scalars['String']['output']>;
	/** The wikidata ID. */
	wikidataId?: Maybe<Scalars['String']['output']>;
};

/** Represents an external tracker. */
export type ExternalTracker = {
	__typename?: 'ExternalTracker';
	/** The tracker data. */
	data: Scalars['String']['output'];
	/** The type of the tracker. */
	type: ExternalTrackerType;
};

/** The type of an external tracker. */
export enum ExternalTrackerType {
	/** A clickout url tracker. */
	ClickoutUrl = 'CLICKOUT_URL',
	/** An impression html tracker. */
	ImpressionHtml = 'IMPRESSION_HTML',
	/** An impression url tracker. */
	ImpressionUrl = 'IMPRESSION_URL',
}

/** An float range filter. Filter is disabled is null or zero. */
export type FloatFilter = {
	/** The max value. */
	max?: InputMaybe<Scalars['Float']['input']>;
	/** The min value. */
	min?: InputMaybe<Scalars['Float']['input']>;
};

/** An entry in the user followed list. */
export type FollowedListEntry = {
	__typename?: 'FollowedListEntry';
	/** The time at which the entry was created. */
	createdAt: Scalars['Time']['output'];
	/** The override name of the title list, given by the follower of the list. */
	name: Scalars['String']['output'];
};

/** A generic title list. */
export type GenericTitleList = Node & {
	__typename?: 'GenericTitleList';
	/** The title data. It is localized by country and language. Provider shortnames are expected. */
	content: GenericTitleListContent;
	/** The entry for the title list in the followedlist. */
	followedlistEntry?: Maybe<FollowedListEntry>;
	/** The global node ID. */
	id: Scalars['ID']['output'];
	/**
	 * The name of the title list, given by the creator of the list.
	 * @deprecated Use the localized 'content' instead.
	 */
	name: Scalars['String']['output'];
	/**
	 * Flag indicating if the list is owned by the current user.
	 * @deprecated Use the localized 'content' instead.
	 */
	ownedByUser: Scalars['Boolean']['output'];
	/** The titles inside of the title list. */
	titles: GenericTitleListConnection;
	/** Indicates the type of the list. Usually tied to the source of the list (made by users or internal JW lists). */
	type: GenericTitleListType;
	/**
	 * The visibility of a generic title list. Impacts who's allowed to access the list.
	 * @deprecated Use the localized 'content' instead.
	 */
	visibility: GenericTitleListVisibility;
};

/** A generic title list. */
export type GenericTitleListContentArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
	provider?: InputMaybe<Scalars['String']['input']>;
};

/** A generic title list. */
export type GenericTitleListTitlesArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<TitleFilter>;
	first?: Scalars['Int']['input'];
	language?: InputMaybe<Scalars['Language']['input']>;
	sortBy?: GenericTitleListSorting;
	sortRandomSeed?: Scalars['Int']['input'];
};

/** A connection object for the external title list titles. */
export type GenericTitleListConnection = {
	__typename?: 'GenericTitleListConnection';
	/** The edges for each of the items. */
	edges?: Maybe<Array<GenericTitleListEdge>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** The total number of items. */
	totalCount: Scalars['Int']['output'];
};

/** The content of a generic title list. */
export type GenericTitleListContent = {
	__typename?: 'GenericTitleListContent';
	/** The localized website detail page URL of the title list. Depends on the country being set. Optional. */
	fullPath?: Maybe<Scalars['String']['output']>;
	/** The type of list - e.g. permanent audience. */
	listType?: Maybe<GenericTitleListType>;
	/** This contains all provider-specific URLs available for this subgenre, for movies. If a provider shortname is passed, only that provider's URL is returned. */
	moviesProvidersUrls?: Maybe<Array<ProviderUrl>>;
	/** This contains the generic URL for this subgenre, for movies. If a provider shortname is passed, that provider-specific URL is returned. If it doesn't exist, an error is returned. */
	moviesUrl?: Maybe<Url>;
	/** The name of the title list, given by the creator of the list. */
	name: Scalars['String']['output'];
	/** Flag indicating if the list is owned by the current user. */
	ownedByUser: Scalars['Boolean']['output'];
	/** A short name for the title list. Optional. */
	shortName?: Maybe<Scalars['String']['output']>;
	/** This contains all provider-specific URLs available for this subgenre, for shows. If a provider shortname is passed, only that provider's URL is returned. */
	showsProvidersUrls?: Maybe<Array<ProviderUrl>>;
	/** This contains the generic URL for this subgenre, for shows. If a provider shortname is passed, that provider-specific URL is returned. If it doesn't exist, an error is returned. */
	showsUrl?: Maybe<Url>;
	/** The visibility of a generic title list. Impacts who's allowed to access the list. */
	visibility: GenericTitleListVisibility;
};

/** The content of a generic title list. */
export type GenericTitleListContentMoviesProvidersUrlsArgs = {
	country: Scalars['Country']['input'];
	platform: Platform;
	provider?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** The content of a generic title list. */
export type GenericTitleListContentMoviesUrlArgs = {
	provider?: InputMaybe<Scalars['String']['input']>;
};

/** The content of a generic title list. */
export type GenericTitleListContentShowsProvidersUrlsArgs = {
	country: Scalars['Country']['input'];
	platform: Platform;
	provider?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** The content of a generic title list. */
export type GenericTitleListContentShowsUrlArgs = {
	provider?: InputMaybe<Scalars['String']['input']>;
};

/** An edge object for the external title list titles. */
export type GenericTitleListEdge = {
	__typename?: 'GenericTitleListEdge';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/**
	 * The node represented by this edge.
	 * @deprecated Use nodeV2 instead.
	 */
	node: MovieOrShow;
	/** The node represented by this edge. */
	nodeV2: MovieOrShowOrSeason;
};

/** The field/info to order titles by. */
export enum GenericTitleListSorting {
	/** Sorted by title. */
	Alphabetical = 'ALPHABETICAL',
	/** Sorted by IMDB score. */
	ImdbScore = 'IMDB_SCORE',
	/** Not reordered. We follow the natural order of the list. */
	Natural = 'NATURAL',
	/** Sorted by JW popularity score. */
	Popular = 'POPULAR',
	/** Sorted randomly, based on the provided randomSeed. */
	Random = 'RANDOM',
	/** Sorted by release year. */
	ReleaseYear = 'RELEASE_YEAR',
	/** Sorted by runtime. */
	Runtime = 'RUNTIME',
	/** Sorted by TMDB popularity. */
	TmdbPopularity = 'TMDB_POPULARITY',
	/** Sorted by JW trending score. */
	Trending = 'TRENDING',
}

/** The type of a GenericTitleList object. Usually tied to the source of the list. */
export enum GenericTitleListType {
	/** That's a list also used in the campaign department, stored in the user profile api as 'internal title lists' and maintained in the NCMS. */
	PermanentAudienceList = 'PERMANENT_AUDIENCE_LIST',
	/** Stored in the user profile api as 'technical user title lists' and maintained in the Audience Builder. */
	TechnicalUserList = 'TECHNICAL_USER_LIST',
	/** Stored in the user profile api as 'user title lists' and maintained by users. */
	UserList = 'USER_LIST',
}

/** The visibility of a generic title list. */
export enum GenericTitleListVisibility {
	/** Only visible to the owner. */
	Private = 'PRIVATE',
	/** Visible and searchable by everyone. */
	Public = 'PUBLIC',
	/** Visible by everyone with a direct link. */
	Unlisted = 'UNLISTED',
}

/** A connection object for generic title lists. */
export type GenericTitleListsConnection = {
	__typename?: 'GenericTitleListsConnection';
	/** The edges for each of the items. */
	edges?: Maybe<Array<GenericTitleListsEdge>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** The total number of items. */
	totalCount: Scalars['Int']['output'];
};

/** An edge object for generic title lists. */
export type GenericTitleListsEdge = {
	__typename?: 'GenericTitleListsEdge';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The node represented by this edge. */
	node: GenericTitleList;
};

/** The content types supported by the age certification queries. */
export enum GenericTitleListsType {
	/** The custom lists made by the user. */
	CustomLists = 'CUSTOM_LISTS',
	/** The lists followed by the user. */
	FollowedLists = 'FOLLOWED_LISTS',
}

/** A title genre. Defined by Content. */
export type Genre = Node & {
	__typename?: 'Genre';
	/** The global node ID. */
	id: Scalars['ID']['output'];
	/**
	 * The short version of the technical name using 3 letters.
	 *
	 * e.g. 'action' -> 'act'
	 */
	shortName: Scalars['String']['output'];
	/**
	 * The short label for the genre, to be used in the URL (helps SEO)
	 *
	 * e.g. 'action' -> 'action-and-adventure'
	 */
	slug: Scalars['String']['output'];
	/**
	 * An array of subgenre id's for the genre. Returns an empty array if there are no subgenres.
	 *
	 * e.g. '[tl-pa-12415b46-6819-4d98-b4f9-8dcc123c86f8, tl-pa-922025bb-68fe-4c20-ac75-ece0977279ff]'
	 */
	subgenres: Array<GenericTitleList>;
	/**
	 * A unique string identifier for the genre.
	 *
	 * e.g. 'action', 'comedy'
	 */
	technicalName: Scalars['String']['output'];
	/**
	 * The name of the genre to be displayed in the required language (for display)
	 *
	 * e.g. 'Action & Adventure'
	 */
	translation: Scalars['String']['output'];
};

/** A title genre. Defined by Content. */
export type GenreSlugArgs = {
	language: Scalars['Language']['input'];
};

/** A title genre. Defined by Content. */
export type GenreSubgenresArgs = {
	objectType: Scalars['String']['input'];
};

/** A title genre. Defined by Content. */
export type GenreTranslationArgs = {
	language: Scalars['Language']['input'];
};

/** The payload response for GetFinalClickoutUrl containing the redirect response. */
export type GetFinalClickoutUrlPayload = {
	__typename?: 'GetFinalClickoutUrlPayload';
	/** The url to redirect to. */
	url: Scalars['String']['output'];
};

/** The potential existing IDs of the user. */
export type GetNewDeviceIdInput = {
	/** The user indicated that they would like to not be tracked. */
	doNotTrack: Scalars['Boolean']['input'];
	/**
	 * A list of known IDs of the device.
	 *
	 * e.g. A Google device ID, etc...
	 */
	ids: Array<Scalars['String']['input']>;
};

/** The payload response of the TV JWT polling. */
export type GetNewDeviceIdPayload = {
	__typename?: 'GetNewDeviceIdPayload';
	/** The found Device ID for the user. */
	deviceId: Scalars['String']['output'];
	/** The IP of the HTTP request sender. */
	ip: Scalars['String']['output'];
	/** The locale in which the IP of the sender is located. */
	locale?: Maybe<LocaleState>;
};

/** The payload response of TV code request. */
export type GetTvCodePayload = {
	__typename?: 'GetTvCodePayload';
	/** The code to display to the user. */
	code: Scalars['String']['output'];
	/** A security UUID to store. */
	uuid: Scalars['String']['output'];
};

/** A href lang tag. Defined by Content. */
export type HrefLangTag = {
	__typename?: 'HrefLangTag';
	/** The full path of the URL variation in the given locale. */
	href: Scalars['String']['output'];
	/**
	 * The href lang attached to the tag.
	 *
	 * e.g. 'en-US'
	 */
	hrefLang: Scalars['String']['output'];
	/**
	 * The locale attached to the tag.
	 *
	 * e.g. 'en_US'
	 */
	locale: Scalars['String']['output'];
};

/** The release state of the locale. */
export enum I18nState {
	/** Fully released. */
	Active = 'ACTIVE',
	/** On its way to be released. */
	Staging = 'STAGING',
}

/** A list of image sizes supported by the scaler for icons. */
export enum IconProfile {
	/** 20x20 */
	S20 = 'S20',
	/** 25x25 */
	S25 = 'S25',
	/** 40x40 */
	S40 = 'S40',
	/** 50x50 */
	S50 = 'S50',
	/** 100x100 */
	S100 = 'S100',
	/** 166x166 */
	S166 = 'S166',
	/** 332x332 */
	S332 = 'S332',
}

/** A list of image sizes supported by the scaler for wide icons. */
export enum IconWideProfile {
	/** 160x64 */
	S160 = 'S160',
}

/** A list of image formats supported by the scaler. */
export enum ImageFormat {
	/** That's for .jpg */
	Jpg = 'JPG',
	/** That's for .png */
	Png = 'PNG',
	/** That's for .webp */
	Webp = 'WEBP',
}

/** The input when importing an IMDB list. */
export type ImportImdbListInput = {
	/** The name of the target list if the target type is NEW_CUSTOMLIST */
	createTargetListWithName?: InputMaybe<Scalars['String']['input']>;
	/** The target list id (when TargetType is e.g. EXISTING_CUSTOMLIST) */
	targetList?: InputMaybe<Scalars['ID']['input']>;
	/** Type of target list */
	targetType: TargetType;
	/**
	 * The URL of the IMDB list
	 * example: https://www.imdb.com/user/ur163600461/watchlist
	 */
	url: Scalars['String']['input'];
};

/** The payload response of an IMDB import. */
export type ImportImdbListPayload = {
	__typename?: 'ImportIMDBListPayload';
	/** The target list */
	importedList: GenericTitleList;
	/** The IMDB IDs that we could not find in our database */
	notFoundIds: Array<Scalars['String']['output']>;
};

/** An integer range filter. Filter is disabled is null or zero. */
export type IntFilter = {
	/** The max value. */
	max?: InputMaybe<Scalars['Int']['input']>;
	/** The min value. */
	min?: InputMaybe<Scalars['Int']['input']>;
};

/** The JW user interactions for a title. */
export type InteractionAttributes = {
	__typename?: 'InteractionAttributes';
	/** The number of times the title was added to a dislikelist. */
	dislikelistAdditions: Scalars['Float']['output'];
	/** The number of times the title was added to a likelist. */
	likelistAdditions: Scalars['Float']['output'];
	/** The number of votes for the title. */
	votesNumber: Scalars['Int']['output'];
};

/** The different types of issues to report on the platform. */
export enum IssueReportType {
	/** Any abusive content observed on the platform. */
	Abuse = 'ABUSE',
	/** Feedback related to JW account. */
	Account = 'ACCOUNT',
	/** Any bug observed in the current section/page. */
	Bug = 'BUG',
	/** Any feature request for the product. */
	FeatureRequest = 'FEATURE_REQUEST',
	/** Wrong link takes to different content */
	Mismatch = 'MISMATCH',
	/** The content is available elsewhere but not mentioned on the platform. */
	MissingOffer = 'MISSING_OFFER',
	/** Any other issue that is not listed above. */
	Other = 'OTHER',
	/** Feedback reported relating to JW pro subscription. */
	ProSubscription = 'PRO_SUBSCRIPTION',
	/** Content Gap feedback, relating to backdrop */
	SubmissionBackdrop = 'SUBMISSION_BACKDROP',
	/** Content Gap feedback, relating to poster */
	SubmissionPoster = 'SUBMISSION_POSTER',
	/** Content Gap feedback, relating to release year */
	SubmissionReleaseYear = 'SUBMISSION_RELEASE_YEAR',
	/** Content Gap feedback, relating to synopsis */
	SubmissionSynopsis = 'SUBMISSION_SYNOPSIS',
	/** Content Gap feedback, relating to titles */
	SubmissionTitle = 'SUBMISSION_TITLE',
	/** The content is not available. */
	TitleNotAvailable = 'TITLE_NOT_AVAILABLE',
	/** The mentioned movie/show information is wrong. */
	WrongInfo = 'WRONG_INFO',
	/** The mentioned offer is wrong/ incorrect. */
	WrongOffer = 'WRONG_OFFER',
}

/** The user tracking consent. */
export type JwConsents = {
	__typename?: 'JWConsents';
	/** That's for everything else. */
	allother?: Maybe<Scalars['Boolean']['output']>;
	/** The user agreed to receive emails from JW. */
	emails?: Maybe<Scalars['Boolean']['output']>;
	/** That's for Facebook. */
	facebook?: Maybe<Scalars['Boolean']['output']>;
	/** That's for Google. */
	google?: Maybe<Scalars['Boolean']['output']>;
	/** That's for Snapchat. */
	snapchat?: Maybe<Scalars['Boolean']['output']>;
	/** The user agreed to the JW terms and conditions. */
	termsAndConditions?: Maybe<Scalars['Boolean']['output']>;
	/** That's for TikTok. */
	tiktok?: Maybe<Scalars['Boolean']['output']>;
	/** That's for Twitter. */
	twitter?: Maybe<Scalars['Boolean']['output']>;
};

/** The user tracking consent. */
export type JwConsentsInput = {
	/** That's for everything else. */
	allother?: InputMaybe<Scalars['Boolean']['input']>;
	/** The user agreed to receive emails from JW. */
	emails?: InputMaybe<Scalars['Boolean']['input']>;
	/** That's for Facebook. */
	facebook?: InputMaybe<Scalars['Boolean']['input']>;
	/** That's for Google. */
	google?: InputMaybe<Scalars['Boolean']['input']>;
	/** That's for Snapchat. */
	snapchat?: InputMaybe<Scalars['Boolean']['input']>;
	/** The user agreed to the JW terms and conditions. */
	termsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
	/** That's for TikTok. */
	tiktok?: InputMaybe<Scalars['Boolean']['input']>;
	/** That's for Twitter. */
	twitter?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Represents a language on JustWatch. */
export type LanguageState = {
	__typename?: 'LanguageState';
	/** If the language is enabled or in staging mode. */
	i18nState: I18nState;
	/** The ISO language. */
	language: Scalars['Language']['output'];
};

export type LikeTextRecommendationInput = {
	/** Country - used to determine the locale for tag translations. Defaults to US */
	country?: InputMaybe<Scalars['Country']['input']>;
	/** Language - used to determine the locale for tag translations. Defaults to en */
	language?: InputMaybe<Scalars['Language']['input']>;
	/** True : like, False : unlike */
	like: Scalars['Boolean']['input'];
	/** UUID of the text recommendation to be liked */
	uuid: Scalars['String']['input'];
};

export type LikeTextRecommendationPayload = {
	__typename?: 'LikeTextRecommendationPayload';
	/** Whether the like/unlike operation was successful */
	result: Scalars['Boolean']['output'];
	/** The newly liked/disliked text recommendation */
	textRecommendation: TextRecommendation;
};

export type ListMap = {
	__typename?: 'ListMap';
	list: Scalars['String']['output'];
	result: Scalars['String']['output'];
};

/** Represents a locale on JustWatch. */
export type LocaleState = {
	__typename?: 'LocaleState';
	/** The country linked to the locale. */
	country: Scalars['Country']['output'];
	/**
	 * The translated country name.
	 * @deprecated Some internal meta data that nobody should use
	 */
	countryName?: Maybe<Scalars['String']['output']>;
	/** The currency ISO code. e.g. 'EUR' */
	currency: Scalars['String']['output'];
	/** The currency symbol if we know it. Fallsback to ISO currency. */
	currencySymbol: Scalars['String']['output'];
	/** The locale namespace used to build the website URLs. e.g. 'us' -> '/us/popular' */
	exposedUrlPart: Scalars['String']['output'];
	/** The ISO locale. */
	fullLocale: Scalars['String']['output'];
	/** If the locale is enabled or in staging mode. */
	i18nState: I18nState;
	/** The timezone (long name) e.g. 'Europe/Berlin' */
	timezone: Scalars['String']['output'];
	/** The timezone (short name) e.g. 'CET' */
	timezoneAbbreviation: Scalars['String']['output'];
	/** The timezone offset of locale. */
	timezoneOffset: Scalars['Int']['output'];
	/** Indicates if the locale has detail page urls enabled. */
	titleURLsEnabled: Scalars['Boolean']['output'];
};

/** Represents a locale on JustWatch. */
export type LocaleStateCountryNameArgs = {
	language: Scalars['Language']['input'];
};

/** A list of image sizes supported by the scaler for logos. */
export enum LogoProfile {
	/** 200x80 */
	S200 = 'S200',
	/** 400x160 */
	S400 = 'S400',
}

/** A discovery module is a module made of a template, a content, translations and a score. */
export type Module = {
	__typename?: 'Module';
	/** Content of the module. */
	content?: Maybe<ModuleContent>;
	/** The FOMO score of the module. Mainly used for filtering/sorting. */
	fomoScore: Scalars['Int']['output'];
	/** Template of the module. */
	template: ModuleTemplate;
	/** Translations for the module. */
	translations: ModuleTranslations;
};

/** A discovery module is a module made of a template, a content, translations and a score. */
export type ModuleTranslationsArgs = {
	language: Scalars['Language']['input'];
};

/** The content of a module. */
export type ModuleContent =
	| ModuleContentSports
	| ModuleContentTextRecommendation
	| ModuleContentTitles
	| ModuleContentTitlesArticle
	| ModuleContentTitlesPerPackage
	| ModuleContentTitlesRelatedToTitle;

/** The content of a module containing a list of titles, related to a package. */
export type ModuleContentPackageTitles = {
	__typename?: 'ModuleContentPackageTitles';
	/** The package group. */
	package: Package;
	/** The list of titles. */
	titles: Array<ModuleContentTitleEdge>;
	/** The total number of titles. */
	totalCount: Scalars['Int']['output'];
};

/** The content of a module containing an Sport. */
export type ModuleContentSports = {
	__typename?: 'ModuleContentSports';
	/** The list of sports events. */
	events: Array<SportEventV2>;
	/** The total number of events. */
	totalCount: Scalars['Int']['output'];
};

/** The content of a module containing a text recommendation. */
export type ModuleContentTextRecommendation = {
	__typename?: 'ModuleContentTextRecommendation';
	/** Author of the text recommendation. */
	authorProfile: Profile;
	/** The position of the text recommendation. */
	position: Scalars['Int']['output'];
	/** The text recommendation informations. */
	textRecommendation: TextRecommendation;
	/** Title ID */
	title: MovieOrShowOrSeason;
};

/** An edge object for the modules. */
export type ModuleContentTitleEdge = {
	__typename?: 'ModuleContentTitleEdge';
	/** The node itself. */
	node: MovieOrShowOrSeason;
	/** If the popularity of the title is trending. */
	trend?: Maybe<ModuleContentTitleTrend>;
	/** The offer (or one of the offers) that triggered the node to be returned. */
	triggerOffer?: Maybe<Offer>;
};

/** The evolution of a title in a list. */
export enum ModuleContentTitleTrend {
	/** The title is going down. */
	Down = 'DOWN',
	/** The title is new in the ranking. */
	New = 'NEW',
	/** The title is stable. */
	Stable = 'STABLE',
	/** The title is going up. */
	Up = 'UP',
}

/** The content of a module containing a flat list of titles. */
export type ModuleContentTitles = {
	__typename?: 'ModuleContentTitles';
	/** The list of titles. */
	titles: Array<ModuleContentTitleEdge>;
	/** The total number of titles. */
	totalCount: Scalars['Int']['output'];
};

/** The content of a module containing an Article. */
export type ModuleContentTitlesArticle = {
	__typename?: 'ModuleContentTitlesArticle';
	/** The list of titles. */
	article: Article;
	/** The list of titles. */
	titles: Array<ModuleContentTitleEdge>;
	/** The total number of titles. */
	totalCount: Scalars['Int']['output'];
};

/** The content of a module containing a list of titles, grouped by package. */
export type ModuleContentTitlesPerPackage = {
	__typename?: 'ModuleContentTitlesPerPackage';
	/** The list of package groups. */
	packages: Array<ModuleContentPackageTitles>;
};

/** The content of a module containing a list of titles, related to a title. */
export type ModuleContentTitlesRelatedToTitle = {
	__typename?: 'ModuleContentTitlesRelatedToTitle';
	/** The title that the list is related to. */
	relatedTo: ModuleContentTitleEdge;
	/** The list of titles. */
	titles: Array<ModuleContentTitleEdge>;
	/** The total number of titles. */
	totalCount: Scalars['Int']['output'];
};

/** The different content types of discovery modules. */
export enum ModuleContentType {
	/** The modules contains sports events */
	Sports = 'SPORTS',
	/** The modules contains a text recommendation */
	TextRecommendation = 'TEXT_RECOMMENDATION',
	/** The module contains a flat list of titles. */
	Titles = 'TITLES',
	/** The module contains an article */
	TitlesArticle = 'TITLES_ARTICLE',
	/** The module contains lists of titles, grouped by package. */
	TitlesPerPackage = 'TITLES_PER_PACKAGE',
	/** The module contains lists of titles, related to a title. */
	TitlesRelatedToTitle = 'TITLES_RELATED_TO_TITLE',
}

/** The different promoted content modes. */
export enum ModulePromotionMode {
	/** Returns the 'MAIN_WATCHED_BY_SIMILAR_USERS' module in the sponsored slot. */
	SponsoredRecommendation = 'SPONSORED_RECOMMENDATION',
}

/** A discovery module template is a description of the 'type' of the module. It's an empty module shell. */
export type ModuleTemplate = {
	__typename?: 'ModuleTemplate';
	/** The anchor is a subtype of a template in a way. e.g technicalName='MOVIES_GENRE'-> anchor='act' */
	anchor?: Maybe<Scalars['String']['output']>;
	/** The content type of the module. */
	contentType: ModuleContentType;
	/** TechnicalName uniquely identifies a module template. */
	technicalName: Scalars['String']['output'];
};

/** Traslated/localized module informations. */
export type ModuleTranslations = {
	__typename?: 'ModuleTranslations';
	/** Additional badge on the module. */
	badge?: Maybe<Scalars['String']['output']>;
	/** The title of the module. */
	title?: Maybe<Scalars['String']['output']>;
};

/** The way an offer of monetized. */
export enum MonetizationType {
	/** Offer is watchable for free but with ads. */
	Ads = 'ADS',
	/** Offer is buyable. */
	Buy = 'BUY',
	/** Offer is watchable at the cinema. */
	Cinema = 'CINEMA',
	/** Offer is watchable only over a short period of time on a provider. */
	Fast = 'FAST',
	/** Offer is watchable if subscribed to the package for a flat fee. */
	Flatrate = 'FLATRATE',
	/** Offer is watchable if subscribed to the package for a flat fee, but also needs an extra payment. */
	FlatrateAndBuy = 'FLATRATE_AND_BUY',
	/** Offer is watchable for free. */
	Free = 'FREE',
	/** Offer is rentable. */
	Rent = 'RENT',
}

/** Personalized movie aggregating user data and static Content data. */
export type Movie = MovieOrSeason &
	MovieOrShow &
	MovieOrShowOrSeason &
	MovieOrShowOrSeasonOrEpisode &
	Node &
	Title & {
		__typename?: 'Movie';
		/** The date where the title is leaving the users provider. */
		availableTo: Array<AvailableTo>;
		/** The bundles for this title. Calculated from the offers. Depends on the country being set. */
		bundles: Array<PromotedBundleEdge>;
		/** contains information about various clips like trailers */
		clips?: Maybe<Array<Clip>>;
		/** The title data. It is localized by country and language. */
		content: MovieContent;
		/** The entries in the user's customlists. */
		customlistEntries: Array<TitleListEntry>;
		/** The entry for the title in the dislikelist. */
		dislikelistEntry?: Maybe<TitleListEntry>;
		/**
		 * The global node ID of the title. For titles, it is defined by Content and made from object type + object ID.
		 *
		 * e.g. 'tm21' -> 'movie' + 21, 'tss11' -> 'show_season' + 11
		 */
		id: Scalars['ID']['output'];
		/** The entry for the title in the likelist. */
		likelistEntry?: Maybe<TitleListEntry>;
		/** The recent updated time on the packages for the title. Depends on the country being set.  */
		maxOfferUpdatedAt?: Maybe<Scalars['Time']['output']>;
		/** The Content ID of the title. */
		objectId: Scalars['Int']['output'];
		/** The Content type of the title. */
		objectType: ObjectType;
		/** The offer count available on packages for the title. Depends on the country being set.  */
		offerCount: Scalars['Int']['output'];
		/** The offers available on packages for the title. Depends on the country being set. */
		offers: Array<Offer>;
		/** A list of parent permanent audience IDs the title is part of. */
		parentPermanentAudiences: Array<Scalars['String']['output']>;
		/** A list of permanent audience IDs the title is part of. */
		permanentAudiences: Array<Scalars['String']['output']>;
		/**
		 * The popularity rank and its trend popularity behaviour for the given title and its neighbouring titles.
		 * @deprecated Use the StreamingCharts :)
		 */
		popularityRank: PopularityRank;
		/** The bundles promoted for this title. Calculated from the offers. Depends on the country being set. */
		promotedBundles: Array<PromotedBundleEdge>;
		/** The offers promoted for this title. Depends on the country being set. */
		promotedOffers: Array<Offer>;
		/** The sum of how many times a text recommendation has been made for this entity */
		recommendedByCount: Scalars['Int']['output'];
		/** The entry for the movie in the seenlist. */
		seenlistEntry?: Maybe<TitleListEntry>;
		/**
		 * A list of similar titles. Not specific to the user, returning the same for all users.
		 * @deprecated Use the V2 :)
		 */
		similarTitles: Array<MovieOrShow>;
		/** A list of similar titles. Not specific to the user, returning the same for all users. */
		similarTitlesV2: SimilarTitlesConnection;
		/** The streaming chart rank and its trend popularity behaviour for the given title and its neighbouring titles. */
		streamingCharts: StreamingChartsConnection;
		/**
		 * The default offer that should be used to play the title. Can be null if there are no matching offers.
		 *
		 * A default monetization type filter is applied to only keep flatrate/free offers.
		 */
		watchNowOffer?: Maybe<Offer>;
		/** The sum of how many times this entity appears in seenlists */
		watchedByCount: Scalars['Int']['output'];
		/**
		 * The entry for the movie in the watchlist.
		 * @deprecated Please use the 'watchlistEntryV2' field
		 */
		watchlistEntry?: Maybe<TitleListEntry>;
		watchlistEntryV2?: Maybe<TitleListEntry>;
	};

/** Personalized movie aggregating user data and static Content data. */
export type MovieAvailableToArgs = {
	country: Scalars['Country']['input'];
	leavingSoonOnly?: Scalars['Boolean']['input'];
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	platform: Platform;
};

/** Personalized movie aggregating user data and static Content data. */
export type MovieBundlesArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized movie aggregating user data and static Content data. */
export type MovieContentArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
};

/** Personalized movie aggregating user data and static Content data. */
export type MovieMaxOfferUpdatedAtArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized movie aggregating user data and static Content data. */
export type MovieOfferCountArgs = {
	country: Scalars['Country']['input'];
	distinctPackages?: Scalars['Boolean']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized movie aggregating user data and static Content data. */
export type MovieOffersArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized movie aggregating user data and static Content data. */
export type MoviePopularityRankArgs = {
	category?: PopularityRankTypes;
	country: Scalars['Country']['input'];
	nextTitles?: Scalars['Int']['input'];
	previousTitles?: Scalars['Int']['input'];
};

/** Personalized movie aggregating user data and static Content data. */
export type MoviePromotedBundlesArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized movie aggregating user data and static Content data. */
export type MoviePromotedOffersArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized movie aggregating user data and static Content data. */
export type MovieSimilarTitlesArgs = {
	country: Scalars['Country']['input'];
	filter?: InputMaybe<TitleFilter>;
	limit?: Scalars['Int']['input'];
};

/** Personalized movie aggregating user data and static Content data. */
export type MovieSimilarTitlesV2Args = {
	allowSponsoredRecommendations?: InputMaybe<SponsoredRecommendationsInput>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<TitleFilter>;
	first?: Scalars['Int']['input'];
};

/** Personalized movie aggregating user data and static Content data. */
export type MovieStreamingChartsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country?: InputMaybe<Scalars['Country']['input']>;
	filter?: InputMaybe<StreamingChartsFilter>;
	first?: Scalars['Int']['input'];
};

/** Personalized movie aggregating user data and static Content data. */
export type MovieWatchNowOfferArgs = {
	country: Scalars['Country']['input'];
	filter?: WatchNowOfferFilter;
	platform: Platform;
};

/** Unpersonalized movie data common to all the users, as defined by Content. It is localized by country and language. */
export type MovieContent = MovieOrSeasonContent &
	MovieOrShowContent &
	MovieOrShowOrSeasonContent &
	MovieOrShowOrSeasonOrEpisodeContent & {
		__typename?: 'MovieContent';
		/** The age rating. e.g. PG-13 for movies, TV-PG for shows in the US */
		ageCertification?: Maybe<Scalars['String']['output']>;
		/**
		 * The main article about this title
		 * @deprecated Use the articles field :)
		 */
		article?: Maybe<Article>;
		articles: Array<Article>;
		/**
		 * A list of backdrops. It takes a desired size (called profile) and desired format as parameter.
		 *
		 * e.g. 's1440' + 'webp' -> '/backdrop/8711516/s1440/Spider-Man-Homecoming.webp'
		 */
		backdrops: Array<Backdrop>;
		/** The trailers/clips attached to the title. */
		clips: Array<Clip>;
		/** The credits of the title. The 'personId' filter can be used to check if a certain person was involved in the title. */
		credits: Array<Credit>;
		/** All the IDs of the title on external platforms. */
		externalIds: ExternalIds;
		/**
		 * The localized website detail page URL of the title. Depends on the country being set.
		 *
		 * e.g. '/us/movie/batman-the-dark-knight'
		 */
		fullPath: Scalars['String']['output'];
		/** The related genre objects. */
		genres: Array<Genre>;
		/** The JW user interactions for a title. */
		interactions: InteractionAttributes;
		/**
		 * Whether we consider the title as released or not.
		 * @deprecated Use the V2 instead :)
		 */
		isReleased: Scalars['Boolean']['output'];
		/** Whether we consider the title as released or not. */
		isReleasedV2: Scalars['Boolean']['output'];
		/** The date when the title was first released in the country. */
		localizedReleaseDate?: Maybe<Scalars['Date']['output']>;
		/** The date when the title was originally first released. */
		originalReleaseDate?: Maybe<Scalars['Date']['output']>;
		/** The year when the title was originally first released. */
		originalReleaseYear?: Maybe<Scalars['Int']['output']>;
		/** The original title of the title. */
		originalTitle: Scalars['String']['output'];
		/**
		 * The blur hash representation of the poster.
		 *
		 * Generated using https://github.com/woltapp/blurhash.
		 */
		posterBlurHash?: Maybe<Scalars['String']['output']>;
		/**
		 * The localized poster URL. Depends on the country and language being set.
		 * It takes a desired size (called profile) and desired format as parameter.
		 *
		 * e.g. 's166' + 'webp' -> '/poster/8711516/s166/memento.webp'
		 */
		posterUrl?: Maybe<Scalars['String']['output']>;
		/** The countries where the title was produced. */
		productionCountries: Array<Scalars['Country']['output']>;
		/** The duration of the title. For shows and seasons, it's the average runtime of an episode. */
		runtime?: Maybe<Scalars['Int']['output']>;
		/** All the scorings/ratings of the title. */
		scoring: Scoring;
		/** A short description of the title. */
		shortDescription?: Maybe<Scalars['String']['output']>;
		/** The related subgenres. */
		subgenres: Array<GenericTitleList>;
		tags?: Maybe<Array<Tag>>;
		/** The text recommendations related to this entity. */
		textRecommendations?: Maybe<Array<TextRecommendation>>;
		/** The localized title of the title. Depends on the language being set. */
		title: Scalars['String']['output'];
		/** The title upcoming releases on streaming or cinema. Sorted by release date. */
		upcomingReleases: Array<UpcomingRelease>;
	};

/** Unpersonalized movie data common to all the users, as defined by Content. It is localized by country and language. */
export type MovieContentArticlesArgs = {
	articleTypes?: InputMaybe<Array<ArticleType>>;
};

/** Unpersonalized movie data common to all the users, as defined by Content. It is localized by country and language. */
export type MovieContentBackdropsArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<BackdropProfile>;
};

/** Unpersonalized movie data common to all the users, as defined by Content. It is localized by country and language. */
export type MovieContentClipsArgs = {
	providers?: InputMaybe<Array<ClipProvider>>;
};

/** Unpersonalized movie data common to all the users, as defined by Content. It is localized by country and language. */
export type MovieContentCreditsArgs = {
	first?: InputMaybe<Scalars['Int']['input']>;
	personId?: InputMaybe<Scalars['Int']['input']>;
	role?: InputMaybe<CreditRole>;
};

/** Unpersonalized movie data common to all the users, as defined by Content. It is localized by country and language. */
export type MovieContentIsReleasedV2Args = {
	country: Scalars['Country']['input'];
};

/** Unpersonalized movie data common to all the users, as defined by Content. It is localized by country and language. */
export type MovieContentPosterUrlArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<PosterProfile>;
};

/** Unpersonalized movie data common to all the users, as defined by Content. It is localized by country and language. */
export type MovieContentTextRecommendationsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	filter?: InputMaybe<TextRecommendationFilter>;
	first?: Scalars['Int']['input'];
};

/** Unpersonalized movie data common to all the users, as defined by Content. It is localized by country and language. */
export type MovieContentUpcomingReleasesArgs = {
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	releaseTypes?: InputMaybe<Array<ReleaseType>>;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and season titles.
 */
export type MovieOrSeason = {
	/** The date where the title is leaving the users provider. */
	availableTo: Array<AvailableTo>;
	/** The bundles for this title. Calculated from the offers. Depends on the country being set. */
	bundles: Array<PromotedBundleEdge>;
	/** The title data. It is localized by country and language. */
	content: MovieOrSeasonContent;
	/** The entry for the title in the dislikelist. */
	dislikelistEntry?: Maybe<TitleListEntry>;
	/**
	 * The global node ID of the title. For titles, it is defined by Content and made from object type + object ID.
	 *
	 * e.g. 'tm21' -> 'movie' + 21, 'tss11' -> 'show_season' + 11
	 */
	id: Scalars['ID']['output'];
	/** The entry for the title in the likelist. */
	likelistEntry?: Maybe<TitleListEntry>;
	/** The recent updated time on the packages for the title. Depends on the country being set.  */
	maxOfferUpdatedAt?: Maybe<Scalars['Time']['output']>;
	/** The Content ID of the title. */
	objectId: Scalars['Int']['output'];
	/** The Content type of the title. */
	objectType: ObjectType;
	/** The offer count available on packages for the title. Depends on the country being set.  */
	offerCount: Scalars['Int']['output'];
	/** The offers available on packages for the title. Depends on the country being set. */
	offers: Array<Offer>;
	/** The bundles promoted for this title. Calculated from the offers. Depends on the country being set. */
	promotedBundles: Array<PromotedBundleEdge>;
	/** The offers promoted for this title. Depends on the country being set. */
	promotedOffers: Array<Offer>;
	/**
	 * The default offer that should be used to play the title. Can be null if there are no matching offers.
	 *
	 * A default monetization type filter is applied to only keep flatrate/free offers.
	 */
	watchNowOffer?: Maybe<Offer>;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and season titles.
 */
export type MovieOrSeasonAvailableToArgs = {
	country: Scalars['Country']['input'];
	leavingSoonOnly?: Scalars['Boolean']['input'];
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and season titles.
 */
export type MovieOrSeasonBundlesArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and season titles.
 */
export type MovieOrSeasonContentArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and season titles.
 */
export type MovieOrSeasonMaxOfferUpdatedAtArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and season titles.
 */
export type MovieOrSeasonOfferCountArgs = {
	country: Scalars['Country']['input'];
	distinctPackages?: Scalars['Boolean']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and season titles.
 */
export type MovieOrSeasonOffersArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and season titles.
 */
export type MovieOrSeasonPromotedBundlesArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and season titles.
 */
export type MovieOrSeasonPromotedOffersArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and season titles.
 */
export type MovieOrSeasonWatchNowOfferArgs = {
	country: Scalars['Country']['input'];
	filter?: WatchNowOfferFilter;
	platform: Platform;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and season title contents.
 */
export type MovieOrSeasonContent = {
	/**
	 * The main article about this title
	 * @deprecated Use the articles field :)
	 */
	article?: Maybe<Article>;
	articles: Array<Article>;
	/**
	 * A list of backdrops. It takes a desired size (called profile) and desired format as parameter.
	 *
	 * e.g. 's1440' + 'webp' -> '/backdrop/8711516/s1440/Spider-Man-Homecoming.webp'
	 */
	backdrops: Array<Backdrop>;
	/** The trailers/clips attached to the title. */
	clips: Array<Clip>;
	/** All the IDs of the title on external platforms. */
	externalIds: ExternalIds;
	/**
	 * The localized website detail page URL of the title. Depends on the country being set.
	 *
	 * e.g. '/us/movie/batman-the-dark-knight'
	 */
	fullPath: Scalars['String']['output'];
	/** The related genre objects. */
	genres: Array<Genre>;
	/** The JW user interactions for a title. */
	interactions: InteractionAttributes;
	/**
	 * Whether we consider the title as released or not.
	 * @deprecated Use the V2 instead :)
	 */
	isReleased: Scalars['Boolean']['output'];
	/** Whether we consider the title as released or not. */
	isReleasedV2: Scalars['Boolean']['output'];
	/** The date when the title was first released in the country. */
	localizedReleaseDate?: Maybe<Scalars['Date']['output']>;
	/** The date when the title was originally first released. */
	originalReleaseDate?: Maybe<Scalars['Date']['output']>;
	/** The year when the title was originally first released. */
	originalReleaseYear?: Maybe<Scalars['Int']['output']>;
	/**
	 * The blur hash representation of the poster.
	 *
	 * Generated using https://github.com/woltapp/blurhash.
	 */
	posterBlurHash?: Maybe<Scalars['String']['output']>;
	/**
	 * The localized poster URL. Depends on the country and language being set.
	 * It takes a desired size (called profile) and desired format as parameter.
	 *
	 * e.g. 's166' + 'webp' -> '/poster/8711516/s166/memento.webp'
	 */
	posterUrl?: Maybe<Scalars['String']['output']>;
	/** The duration of the title. For shows and seasons, it's the average runtime of an episode. */
	runtime?: Maybe<Scalars['Int']['output']>;
	/** All the scorings/ratings of the title. */
	scoring: Scoring;
	/** A short description of the title. */
	shortDescription?: Maybe<Scalars['String']['output']>;
	/** The related subgenres. */
	subgenres: Array<GenericTitleList>;
	/** The text recommendations related to this entity. */
	textRecommendations?: Maybe<Array<TextRecommendation>>;
	/** The localized title of the title. Depends on the language being set. */
	title: Scalars['String']['output'];
	/** The title upcoming releases on streaming or cinema. Sorted by release date. */
	upcomingReleases: Array<UpcomingRelease>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and season title contents.
 */
export type MovieOrSeasonContentArticlesArgs = {
	articleTypes?: InputMaybe<Array<ArticleType>>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and season title contents.
 */
export type MovieOrSeasonContentBackdropsArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<BackdropProfile>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and season title contents.
 */
export type MovieOrSeasonContentClipsArgs = {
	providers?: InputMaybe<Array<ClipProvider>>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and season title contents.
 */
export type MovieOrSeasonContentIsReleasedV2Args = {
	country: Scalars['Country']['input'];
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and season title contents.
 */
export type MovieOrSeasonContentPosterUrlArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<PosterProfile>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and season title contents.
 */
export type MovieOrSeasonContentTextRecommendationsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	filter?: InputMaybe<TextRecommendationFilter>;
	first?: Scalars['Int']['input'];
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and season title contents.
 */
export type MovieOrSeasonContentUpcomingReleasesArgs = {
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	releaseTypes?: InputMaybe<Array<ReleaseType>>;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and show titles.
 */
export type MovieOrShow = {
	/** The date where the title is leaving the users provider. */
	availableTo: Array<AvailableTo>;
	/** The bundles for this title. Calculated from the offers. Depends on the country being set. */
	bundles: Array<PromotedBundleEdge>;
	/** contains information about various clips like trailers */
	clips?: Maybe<Array<Clip>>;
	/** The title data. It is localized by country and language. */
	content: MovieOrShowContent;
	/** The entries in the user's customlists. */
	customlistEntries: Array<TitleListEntry>;
	/** The entry for the movie in the dislikelist. */
	dislikelistEntry?: Maybe<TitleListEntry>;
	/**
	 * The global node ID of the title. For titles, it is defined by Content and made from object type + object ID.
	 *
	 * e.g. 'tm21' -> 'movie' + 21, 'tss11' -> 'show_season' + 11
	 */
	id: Scalars['ID']['output'];
	/** The entry for the movie in the likelist. */
	likelistEntry?: Maybe<TitleListEntry>;
	/** The recent updated time on the packages for the title. Depends on the country being set.  */
	maxOfferUpdatedAt?: Maybe<Scalars['Time']['output']>;
	/** The Content ID of the title. */
	objectId: Scalars['Int']['output'];
	/** The Content type of the title. */
	objectType: ObjectType;
	/** The offer count available on packages for the title. Depends on the country being set.  */
	offerCount: Scalars['Int']['output'];
	/** The offers available on packages for the title. Depends on the country being set. */
	offers: Array<Offer>;
	/** A list of parent permanent audience IDs the title is part of. */
	parentPermanentAudiences: Array<Scalars['String']['output']>;
	/** A list of permanent audience IDs the title is part of. */
	permanentAudiences: Array<Scalars['String']['output']>;
	/**
	 * The popularity rank and its trend popularity behaviour for the given title and its neighbouring titles.
	 * @deprecated Use the StreamingCharts :)
	 */
	popularityRank: PopularityRank;
	/** The bundles promoted for this title. Calculated from the offers. Depends on the country being set. */
	promotedBundles: Array<PromotedBundleEdge>;
	/** The offers promoted for this title. Depends on the country being set. */
	promotedOffers: Array<Offer>;
	/**
	 * A list of similar titles. Not specific to the user, returning the same for all users.
	 * @deprecated Use the V2 :)
	 */
	similarTitles: Array<MovieOrShow>;
	/** A list of similar titles. Not specific to the user, returning the same for all users. */
	similarTitlesV2: SimilarTitlesConnection;
	/** The streaming chart rank and its trend popularity behaviour for the given title and its neighbouring titles. */
	streamingCharts: StreamingChartsConnection;
	/**
	 * The default offer that should be used to play the title. Can be null if there are no matching offers.
	 *
	 * A default monetization type filter is applied to only keep flatrate/free offers.
	 */
	watchNowOffer?: Maybe<Offer>;
	/**
	 * The entry for the movie in the watchlist.
	 * @deprecated Please use the 'watchlistEntryV2' field
	 */
	watchlistEntry?: Maybe<TitleListEntry>;
	watchlistEntryV2?: Maybe<TitleListEntry>;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and show titles.
 */
export type MovieOrShowAvailableToArgs = {
	country: Scalars['Country']['input'];
	leavingSoonOnly?: Scalars['Boolean']['input'];
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and show titles.
 */
export type MovieOrShowBundlesArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and show titles.
 */
export type MovieOrShowContentArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and show titles.
 */
export type MovieOrShowMaxOfferUpdatedAtArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and show titles.
 */
export type MovieOrShowOfferCountArgs = {
	country: Scalars['Country']['input'];
	distinctPackages?: Scalars['Boolean']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and show titles.
 */
export type MovieOrShowOffersArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and show titles.
 */
export type MovieOrShowPopularityRankArgs = {
	category?: PopularityRankTypes;
	country: Scalars['Country']['input'];
	nextTitles?: Scalars['Int']['input'];
	previousTitles?: Scalars['Int']['input'];
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and show titles.
 */
export type MovieOrShowPromotedBundlesArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and show titles.
 */
export type MovieOrShowPromotedOffersArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and show titles.
 */
export type MovieOrShowSimilarTitlesArgs = {
	country: Scalars['Country']['input'];
	filter?: InputMaybe<TitleFilter>;
	limit?: Scalars['Int']['input'];
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and show titles.
 */
export type MovieOrShowSimilarTitlesV2Args = {
	allowSponsoredRecommendations?: InputMaybe<SponsoredRecommendationsInput>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<TitleFilter>;
	first?: Scalars['Int']['input'];
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and show titles.
 */
export type MovieOrShowStreamingChartsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country?: InputMaybe<Scalars['Country']['input']>;
	filter?: InputMaybe<StreamingChartsFilter>;
	first?: Scalars['Int']['input'];
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie and show titles.
 */
export type MovieOrShowWatchNowOfferArgs = {
	country: Scalars['Country']['input'];
	filter?: WatchNowOfferFilter;
	platform: Platform;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and show title contents.
 */
export type MovieOrShowContent = {
	/** The age rating. e.g. PG-13 for movies, TV-PG for shows in the US */
	ageCertification?: Maybe<Scalars['String']['output']>;
	/**
	 * The main article about this title
	 * @deprecated Use the articles field :)
	 */
	article?: Maybe<Article>;
	articles: Array<Article>;
	/**
	 * A list of backdrops. It takes a desired size (called profile) and desired format as parameter.
	 *
	 * e.g. 's1440' + 'webp' -> '/backdrop/8711516/s1440/Spider-Man-Homecoming.webp'
	 */
	backdrops: Array<Backdrop>;
	/** The trailers/clips attached to the title. */
	clips: Array<Clip>;
	/** The credits of the title. The 'personId' filter can be used to check if a certain person was involved in the title. */
	credits: Array<Credit>;
	/** All the IDs of the title on external platforms. */
	externalIds: ExternalIds;
	/**
	 * The localized website detail page URL of the title. Depends on the country being set.
	 *
	 * e.g. '/us/movie/batman-the-dark-knight'
	 */
	fullPath: Scalars['String']['output'];
	/** The related genre objects. */
	genres: Array<Genre>;
	/** The JW user interactions for a title. */
	interactions: InteractionAttributes;
	/**
	 * Whether we consider the title as released or not.
	 * @deprecated Use the V2 instead :)
	 */
	isReleased: Scalars['Boolean']['output'];
	/** Whether we consider the title as released or not. */
	isReleasedV2: Scalars['Boolean']['output'];
	/** The date when the title was first released in the country. */
	localizedReleaseDate?: Maybe<Scalars['Date']['output']>;
	/** The date when the title was originally first released. */
	originalReleaseDate?: Maybe<Scalars['Date']['output']>;
	/** The year when the title was originally first released. */
	originalReleaseYear?: Maybe<Scalars['Int']['output']>;
	/** The original title of the title. */
	originalTitle: Scalars['String']['output'];
	/**
	 * The blur hash representation of the poster.
	 *
	 * Generated using https://github.com/woltapp/blurhash.
	 */
	posterBlurHash?: Maybe<Scalars['String']['output']>;
	/**
	 * The localized poster URL. Depends on the country and language being set.
	 * It takes a desired size (called profile) and desired format as parameter.
	 *
	 * e.g. 's166' + 'webp' -> '/poster/8711516/s166/memento.webp'
	 */
	posterUrl?: Maybe<Scalars['String']['output']>;
	/** The countries where the title was produced. */
	productionCountries: Array<Scalars['Country']['output']>;
	/** The duration of the title. For shows and seasons, it's the average runtime of an episode. */
	runtime?: Maybe<Scalars['Int']['output']>;
	/** All the scorings/ratings of the title. */
	scoring: Scoring;
	/** A short description of the title. */
	shortDescription?: Maybe<Scalars['String']['output']>;
	/** The related subgenres. */
	subgenres: Array<GenericTitleList>;
	/** The text recommendations related to this entity. */
	textRecommendations?: Maybe<Array<TextRecommendation>>;
	/** The localized title of the title. Depends on the language being set. */
	title: Scalars['String']['output'];
	/** The title upcoming releases on streaming or cinema. Sorted by release date. */
	upcomingReleases: Array<UpcomingRelease>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and show title contents.
 */
export type MovieOrShowContentArticlesArgs = {
	articleTypes?: InputMaybe<Array<ArticleType>>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and show title contents.
 */
export type MovieOrShowContentBackdropsArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<BackdropProfile>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and show title contents.
 */
export type MovieOrShowContentClipsArgs = {
	providers?: InputMaybe<Array<ClipProvider>>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and show title contents.
 */
export type MovieOrShowContentCreditsArgs = {
	first?: InputMaybe<Scalars['Int']['input']>;
	personId?: InputMaybe<Scalars['Int']['input']>;
	role?: InputMaybe<CreditRole>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and show title contents.
 */
export type MovieOrShowContentIsReleasedV2Args = {
	country: Scalars['Country']['input'];
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and show title contents.
 */
export type MovieOrShowContentPosterUrlArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<PosterProfile>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and show title contents.
 */
export type MovieOrShowContentTextRecommendationsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	filter?: InputMaybe<TextRecommendationFilter>;
	first?: Scalars['Int']['input'];
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie and show title contents.
 */
export type MovieOrShowContentUpcomingReleasesArgs = {
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	releaseTypes?: InputMaybe<Array<ReleaseType>>;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show and season titles.
 */
export type MovieOrShowOrSeason = {
	/** The date where the title is leaving the users provider. */
	availableTo: Array<AvailableTo>;
	/** The bundles for this title. Calculated from the offers. Depends on the country being set. */
	bundles: Array<PromotedBundleEdge>;
	/** The title data. It is localized by country and language. */
	content: MovieOrShowOrSeasonContent;
	/** The entry for the title in the dislikelist. */
	dislikelistEntry?: Maybe<TitleListEntry>;
	/**
	 * The global node ID of the title. For titles, it is defined by Content and made from object type + object ID.
	 *
	 * e.g. 'tm21' -> 'movie' + 21, 'tss11' -> 'show_season' + 11
	 */
	id: Scalars['ID']['output'];
	/** The entry for the title in the likelist. */
	likelistEntry?: Maybe<TitleListEntry>;
	/** The recent updated time on the packages for the title. Depends on the country being set.  */
	maxOfferUpdatedAt?: Maybe<Scalars['Time']['output']>;
	/** The Content ID of the title. */
	objectId: Scalars['Int']['output'];
	/** The Content type of the title. */
	objectType: ObjectType;
	/** The offer count available on packages for the title. Depends on the country being set.  */
	offerCount: Scalars['Int']['output'];
	/** The offers available on packages for the title. Depends on the country being set. */
	offers: Array<Offer>;
	/**
	 * The popularity rank and its trend popularity behaviour for the given title and its neighbouring titles.
	 * @deprecated Use the StreamingCharts :)
	 */
	popularityRank: PopularityRank;
	/** The bundles promoted for this title. Calculated from the offers. Depends on the country being set. */
	promotedBundles: Array<PromotedBundleEdge>;
	/** The offers promoted for this title. Depends on the country being set. */
	promotedOffers: Array<Offer>;
	/** The sum of how many times a text recommendation has been made for this entity */
	recommendedByCount: Scalars['Int']['output'];
	/** The streaming chart rank and its trend popularity behaviour for the given title and its neighbouring titles. */
	streamingCharts: StreamingChartsConnection;
	/**
	 * The default offer that should be used to play the title. Can be null if there are no matching offers.
	 *
	 * A default monetization type filter is applied to only keep flatrate/free offers.
	 */
	watchNowOffer?: Maybe<Offer>;
	/** The sum of how many times this entity appears in seenlists */
	watchedByCount: Scalars['Int']['output'];
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show and season titles.
 */
export type MovieOrShowOrSeasonAvailableToArgs = {
	country: Scalars['Country']['input'];
	leavingSoonOnly?: Scalars['Boolean']['input'];
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show and season titles.
 */
export type MovieOrShowOrSeasonBundlesArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show and season titles.
 */
export type MovieOrShowOrSeasonContentArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show and season titles.
 */
export type MovieOrShowOrSeasonMaxOfferUpdatedAtArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show and season titles.
 */
export type MovieOrShowOrSeasonOfferCountArgs = {
	country: Scalars['Country']['input'];
	distinctPackages?: Scalars['Boolean']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show and season titles.
 */
export type MovieOrShowOrSeasonOffersArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show and season titles.
 */
export type MovieOrShowOrSeasonPopularityRankArgs = {
	category?: PopularityRankTypes;
	country: Scalars['Country']['input'];
	nextTitles?: Scalars['Int']['input'];
	previousTitles?: Scalars['Int']['input'];
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show and season titles.
 */
export type MovieOrShowOrSeasonPromotedBundlesArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show and season titles.
 */
export type MovieOrShowOrSeasonPromotedOffersArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show and season titles.
 */
export type MovieOrShowOrSeasonStreamingChartsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country?: InputMaybe<Scalars['Country']['input']>;
	filter?: InputMaybe<StreamingChartsFilter>;
	first?: Scalars['Int']['input'];
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show and season titles.
 */
export type MovieOrShowOrSeasonWatchNowOfferArgs = {
	country: Scalars['Country']['input'];
	filter?: WatchNowOfferFilter;
	platform: Platform;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie, show and season title contents.
 */
export type MovieOrShowOrSeasonContent = {
	/**
	 * The main article about this title
	 * @deprecated Use the articles field :)
	 */
	article?: Maybe<Article>;
	articles: Array<Article>;
	/**
	 * A list of backdrops. It takes a desired size (called profile) and desired format as parameter.
	 *
	 * e.g. 's1440' + 'webp' -> '/backdrop/8711516/s1440/Spider-Man-Homecoming.webp'
	 */
	backdrops: Array<Backdrop>;
	/** The trailers/clips attached to the title. */
	clips: Array<Clip>;
	/** All the IDs of the title on external platforms. */
	externalIds: ExternalIds;
	/**
	 * The localized website detail page URL of the title. Depends on the country being set.
	 *
	 * e.g. '/us/movie/batman-the-dark-knight'
	 */
	fullPath: Scalars['String']['output'];
	/** The related genre objects. */
	genres: Array<Genre>;
	/** The JW user interactions for a title. */
	interactions: InteractionAttributes;
	/**
	 * Whether we consider the title as released or not.
	 * @deprecated Use the V2 instead :)
	 */
	isReleased: Scalars['Boolean']['output'];
	/** Whether we consider the title as released or not. */
	isReleasedV2: Scalars['Boolean']['output'];
	/** The date when the title was first released in the country. */
	localizedReleaseDate?: Maybe<Scalars['Date']['output']>;
	/** The date when the title was originally first released. */
	originalReleaseDate?: Maybe<Scalars['Date']['output']>;
	/** The year when the title was originally first released. */
	originalReleaseYear?: Maybe<Scalars['Int']['output']>;
	/**
	 * The blur hash representation of the poster.
	 *
	 * Generated using https://github.com/woltapp/blurhash.
	 */
	posterBlurHash?: Maybe<Scalars['String']['output']>;
	/**
	 * The localized poster URL. Depends on the country and language being set.
	 * It takes a desired size (called profile) and desired format as parameter.
	 *
	 * e.g. 's166' + 'webp' -> '/poster/8711516/s166/memento.webp'
	 */
	posterUrl?: Maybe<Scalars['String']['output']>;
	/** The duration of the title. For shows and seasons, it's the average runtime of an episode. */
	runtime?: Maybe<Scalars['Int']['output']>;
	/** All the scorings/ratings of the title. */
	scoring: Scoring;
	/** A short description of the title. */
	shortDescription?: Maybe<Scalars['String']['output']>;
	/** The related subgenres. */
	subgenres: Array<GenericTitleList>;
	/** The text recommendations related to this entity. */
	textRecommendations?: Maybe<Array<TextRecommendation>>;
	/** The localized title of the title. Depends on the language being set. */
	title: Scalars['String']['output'];
	/** The title upcoming releases on streaming or cinema. Sorted by release date. */
	upcomingReleases: Array<UpcomingRelease>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie, show and season title contents.
 */
export type MovieOrShowOrSeasonContentArticlesArgs = {
	articleTypes?: InputMaybe<Array<ArticleType>>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie, show and season title contents.
 */
export type MovieOrShowOrSeasonContentBackdropsArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<BackdropProfile>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie, show and season title contents.
 */
export type MovieOrShowOrSeasonContentClipsArgs = {
	providers?: InputMaybe<Array<ClipProvider>>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie, show and season title contents.
 */
export type MovieOrShowOrSeasonContentIsReleasedV2Args = {
	country: Scalars['Country']['input'];
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie, show and season title contents.
 */
export type MovieOrShowOrSeasonContentPosterUrlArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<PosterProfile>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie, show and season title contents.
 */
export type MovieOrShowOrSeasonContentTextRecommendationsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	filter?: InputMaybe<TextRecommendationFilter>;
	first?: Scalars['Int']['input'];
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie, show and season title contents.
 */
export type MovieOrShowOrSeasonContentUpcomingReleasesArgs = {
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	releaseTypes?: InputMaybe<Array<ReleaseType>>;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show, season and episode titles.
 */
export type MovieOrShowOrSeasonOrEpisode = {
	/** The bundles for this title. Calculated from the offers. Depends on the country being set. */
	bundles: Array<PromotedBundleEdge>;
	/** The title data. It is localized by country and language. */
	content: MovieOrShowOrSeasonOrEpisodeContent;
	/**
	 * The global node ID of the title. For titles, it is defined by Content and made from object type + object ID.
	 *
	 * e.g. 'tm21' -> 'movie' + 21, 'tss11' -> 'show_season' + 11
	 */
	id: Scalars['ID']['output'];
	/** The recent updated time on the packages for the title. Depends on the country being set.  */
	maxOfferUpdatedAt?: Maybe<Scalars['Time']['output']>;
	/** The Content ID of the title. */
	objectId: Scalars['Int']['output'];
	/** The Content type of the title. */
	objectType: ObjectType;
	/** The offer count available on packages for the title. Depends on the country being set.  */
	offerCount: Scalars['Int']['output'];
	/** The offers available on packages for the title. Depends on the country being set. */
	offers: Array<Offer>;
	/**
	 * The default offer that should be used to play the title. Can be null if there are no matching offers.
	 *
	 * A default monetization type filter is applied to only keep flatrate/free offers.
	 */
	watchNowOffer?: Maybe<Offer>;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show, season and episode titles.
 */
export type MovieOrShowOrSeasonOrEpisodeBundlesArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show, season and episode titles.
 */
export type MovieOrShowOrSeasonOrEpisodeContentArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show, season and episode titles.
 */
export type MovieOrShowOrSeasonOrEpisodeMaxOfferUpdatedAtArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show, season and episode titles.
 */
export type MovieOrShowOrSeasonOrEpisodeOfferCountArgs = {
	country: Scalars['Country']['input'];
	distinctPackages?: Scalars['Boolean']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show, season and episode titles.
 */
export type MovieOrShowOrSeasonOrEpisodeOffersArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between the movie, show, season and episode titles.
 */
export type MovieOrShowOrSeasonOrEpisodeWatchNowOfferArgs = {
	country: Scalars['Country']['input'];
	filter?: WatchNowOfferFilter;
	platform: Platform;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie, show, season and episode title contents.
 */
export type MovieOrShowOrSeasonOrEpisodeContent = {
	/**
	 * The main article about this title
	 * @deprecated Use the articles field :)
	 */
	article?: Maybe<Article>;
	articles: Array<Article>;
	/**
	 * Whether we consider the title as released or not.
	 * @deprecated Use the V2 instead :)
	 */
	isReleased: Scalars['Boolean']['output'];
	/** Whether we consider the title as released or not. */
	isReleasedV2: Scalars['Boolean']['output'];
	/** The date when the title was first released in the country. */
	localizedReleaseDate?: Maybe<Scalars['Date']['output']>;
	/** The date when the title was originally first released. */
	originalReleaseDate?: Maybe<Scalars['Date']['output']>;
	/** The year when the title was originally first released. */
	originalReleaseYear?: Maybe<Scalars['Int']['output']>;
	/** The duration of the title. For shows and seasons, it's the average runtime of an episode. */
	runtime?: Maybe<Scalars['Int']['output']>;
	/** A short description of the title. */
	shortDescription?: Maybe<Scalars['String']['output']>;
	/** The localized title of the title. Depends on the language being set. */
	title: Scalars['String']['output'];
	/** The title upcoming releases on streaming or cinema. Sorted by release date. */
	upcomingReleases: Array<UpcomingRelease>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie, show, season and episode title contents.
 */
export type MovieOrShowOrSeasonOrEpisodeContentArticlesArgs = {
	articleTypes?: InputMaybe<Array<ArticleType>>;
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie, show, season and episode title contents.
 */
export type MovieOrShowOrSeasonOrEpisodeContentIsReleasedV2Args = {
	country: Scalars['Country']['input'];
};

/**
 * Unpersonalized title data common to all the users, as defined by Content. It is localized by country and language.
 *
 * This interface contains the fields that are common between the movie, show, season and episode title contents.
 */
export type MovieOrShowOrSeasonOrEpisodeContentUpcomingReleasesArgs = {
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	releaseTypes?: InputMaybe<Array<ReleaseType>>;
};

/** A multi step sport event. */
export type MultiStepSportEvent = Node &
	SportEventV2 &
	SportTitle & {
		__typename?: 'MultiStepSportEvent';
		/** The closest happening event or the most interesting one. */
		activeChild?: Maybe<MultiStepSportEvent>;
		/** A list of children formula racing events. */
		children: Array<MultiStepSportEvent>;
		/** The competition the event belongs to. */
		competition?: Maybe<SportCompetitionV2>;
		/** The sport event data. It is localized by language. */
		content: MultiStepSportEventContent;
		/** The global node ID. */
		id: Scalars['ID']['output'];
		/** The entry for the sport event in the my sport list. */
		mysportlistEntry?: Maybe<TitleListEntry>;
		/** The Content ID of the title. */
		objectId: Scalars['Int']['output'];
		/** The Content type of the title. */
		objectType: ObjectType;
		/** The offer count available on packages for the event. Depends on the country being set.  */
		offerCount: Scalars['Int']['output'];
		/** The offers for the sport event. */
		offers: Array<SportOffer>;
		/** The parent racing events */
		parent?: Maybe<MultiStepSportEvent>;
		/** The sport of the event. */
		sport: SportType;
		/** The default offer that should be used to play the event. Can be null if there are no matching offers. */
		watchNowOffer?: Maybe<SportOffer>;
	};

/** A multi step sport event. */
export type MultiStepSportEventContentArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
};

/** A multi step sport event. */
export type MultiStepSportEventOfferCountArgs = {
	country: Scalars['Country']['input'];
	filter?: SportOfferFilter;
	platform: Platform;
};

/** A multi step sport event. */
export type MultiStepSportEventOffersArgs = {
	country: Scalars['Country']['input'];
	filter?: SportOfferFilter;
	platform: Platform;
};

/** A multi step sport event. */
export type MultiStepSportEventWatchNowOfferArgs = {
	country: Scalars['Country']['input'];
	filter?: WatchNowSportOfferFilter;
	platform: Platform;
};

/** A multi step event information. */
export type MultiStepSportEventContent = SportEventContentV2 & {
	__typename?: 'MultiStepSportEventContent';
	/**
	 * A list of backdrops. It takes a desired size (called profile) and desired format as parameter.
	 *
	 * e.g. 's1440' + 'webp' -> '/backdrop/8711516/s1440/Spider-Man-Homecoming.webp'
	 */
	backdrops: Array<Backdrop>;
	/** The stage of the competition the event is in. */
	competitionStage: SportCompetitionStageV2;
	/** When the event ends */
	endAt?: Maybe<Scalars['Time']['output']>;
	/**
	 * The localized website detail page URL of the sport event. Depends on the country being set.
	 *
	 * e.g. '/de/fussball/La-Liga/FC-Barcelona-Real-Madrid-2021-2022'
	 */
	fullPath?: Maybe<Scalars['String']['output']>;
	/** A localized name for the match */
	name: Scalars['String']['output'];
	/** The competition season during which the event took place */
	season?: Maybe<SportSeason>;
	/** When the event starts */
	startAt: Scalars['Time']['output'];
	/** General status of the event */
	status: SportEventStatus;
	/** The venue where the event was held */
	venue?: Maybe<SportVenue>;
};

/** A multi step event information. */
export type MultiStepSportEventContentBackdropsArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<BackdropProfile>;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type Mutation = {
	__typename?: 'Mutation';
	/** Cancels an active stripe subscription for the user */
	cancelStripeSubscription: CancelStripeSubscriptionPayload;
	/** Clears / empties a customlist by ID. */
	clearCustomlist: ClearCustomlistPayload;
	/** The third step of the TV login flow. While the TV is polling, a logged in user can use this endpoint to link their account to the TV. */
	connectTv: ConnectTvPayload;
	/** Creates a new customlist with a given name. */
	createCustomlist: CreateCustomlistPayload;
	/** To add a recommendation to a given jw-entity-id. This can be either a text recommendation, or just a recommendation. */
	createRecommendation: CreateRecommendationPayload;
	/** Creates a Stripe checkout session */
	createStripeCheckoutSession: StripeCheckoutSessionPayload;
	/** Exchanges a Firebase refresh token for Firebase custom token. Used to migrate between the old mobile app and the React Native app. */
	customTokenFromRefreshToken: CustomTokenFromRefreshTokenPayload;
	/** Deletes a customlist by ID. */
	deleteCustomlist: DeleteCustomlistPayload;
	/** Deletes an existing text recommendation */
	deleteTextRecommendation: DeleteTextRecommendationPayload;
	/** To get finalClickoutUrl from the jw-uct. */
	getFinalClickoutUrl: GetFinalClickoutUrlPayload;
	/**
	 * Fetches and return a new Device ID for the device.
	 *
	 * On mobile, we also pass the Google/Apple device identifier so the Device ID of users can stay the same across reinstalls.
	 */
	getNewDeviceId: GetNewDeviceIdPayload;
	/** The first step of the TV login flow. We return a code to display to the user. */
	getTvCode: GetTvCodePayload;
	/** Imports an IMDB List to a list */
	importIMDBList: ImportImdbListPayload;
	/**
	 * To add or remove a like to an existing text recommendation. Boolean return value is false when the user has already liked/unliked the recommendation, true otherwise
	 * @deprecated Please use the 'likeTextRecommendationV2' mutation
	 */
	likeTextRecommendation: Scalars['Boolean']['output'];
	/** To add or remove a like to an existing text recommendation. */
	likeTextRecommendationV2: LikeTextRecommendationPayload;
	/** To simulate a panic. */
	panic: Scalars['Boolean']['output'];
	/** Sends a title play event to devices listening on the 'playTitleReceived' subscription. */
	playTitle: PlayTitlePayload;
	/** The second step of the TV login flow. The TV should poll this endpoint, waiting for a Firebase JWT to be returned. */
	pollForTvJwt: PollForTvJwtPayload;
	/** Gets a device Id, given a justWatch-ClientId. */
	registerDeviceId: GetNewDeviceIdPayload;
	/** Registers/tracks a login operation into our internal ID system. */
	registerLogin: RegisterPayload;
	/** Registers/tracks a logout operation into our internal ID system. */
	registerLogout: RegisterPayload;
	/** Used at sign up. Registers a new Firebase user into our internal ID system. A login ID gets assigned to the user. We need to pass a Firebase JWT in the Authorization header. */
	registerNewUser: RegisterPayload;
	/** Registers/tracks a session start or when an app wakes up. */
	registerSessionStart: RegisterPayload;
	/** To report the feedback or issue about the specific content in the platform. */
	reportFeedback: ReportFeedbackPayload;
	/** Submits a bid for a Sponsored Recommendation Ad. The bid is then processed by the SRA engine and the user is served an ad. */
	sendSraBid: SendSraBidPayload;
	/** Sets or unsets a bundle in the user settings. */
	setBundle: UpdateSettingsPayload;
	/** Sets or unsets a title id in the given customlist. */
	setInCustomlist: SetInTitleListPayload;
	/** Sets or unsets a movie/show in the dislikelist. */
	setInDislikelist: SetInTitleListSeasonPayload;
	/** Sets or unsets a list id in the followed list. */
	setInFollowedList: SetInFollowedListPayload;
	/** Sets or  unsets a movie/show in the likelist. */
	setInLikelist: SetInTitleListSeasonPayload;
	/** Sets or unsets a sport_event in the sportlist. */
	setInMysportlist: SetInMysportlistPayload;
	/** Sets or unsets a movie/show/season/episode in the seenlist. */
	setInSeenlist: SetInSeenlistPayload;
	/** Sets or unsets a batch of movie/show/season/episode in the seenlist. */
	setInSeenlistBatch: SetInSeenlistBatchPayload;
	/** Sets or unsets a movie/show in the tv show tracking list. */
	setInTvShowTracking: SetInTitleListPayload;
	/**
	 * Sets or unsets a movie/show in the watchlist.
	 * @deprecated Please use the 'setInWatchlistV2' mutation
	 */
	setInWatchlist: SetInTitleListPayload;
	/** Sets or unsets a movie/show in the watchlist. */
	setInWatchlistV2: SetInTitleListPayload;
	/** Sets or unsets a package in the user settings. */
	setPackage: UpdateSettingsPayload;
	/** Reset the taste survey preference information. */
	tasteSurveyReset: TasteSurveyResetPayload;
	/** To track a single generic user event. */
	trackEvent: TrackEventPayload;
	/** To track multiple generic user events. */
	trackEvents: TrackEventPayload;
	/** To track an event related to the Sponsored Recommendation Ads. */
	trackSraEvent: TrackSraEventPayload;
	/** To track multiple events related to the Sponsored Recommendation Ads. */
	trackSraEvents: TrackSraEventsPayload;
	/** Updates a customlist by ID. */
	updateCustomlist: UpdateCustomlistPayload;
	/** To update the details of the current user's profile. */
	updateProfile: UpdateProfilePayload;
	/** Allows partial updates of the user settings. */
	updateSettings: UpdateSettingsPayload;
	/** Updates an existing text recommendation */
	updateTextRecommendation: UpdateTextRecommendationPayload;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationClearCustomlistArgs = {
	input: ClearCustomlistInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationConnectTvArgs = {
	input: ConnectTvInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationCreateCustomlistArgs = {
	input: CreateCustomlistInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationCreateRecommendationArgs = {
	input: CreateRecommendationInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationCreateStripeCheckoutSessionArgs = {
	input: StripeCheckoutSessionInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationCustomTokenFromRefreshTokenArgs = {
	input: CustomTokenFromRefreshTokenInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationDeleteCustomlistArgs = {
	input: DeleteCustomlistInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationDeleteTextRecommendationArgs = {
	input: DeleteTextRecommendationInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationGetFinalClickoutUrlArgs = {
	input?: InputMaybe<Scalars['String']['input']>;
	uct2input?: InputMaybe<Uct2Input>;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationGetNewDeviceIdArgs = {
	input: GetNewDeviceIdInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationImportImdbListArgs = {
	input: ImportImdbListInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationLikeTextRecommendationArgs = {
	input: LikeTextRecommendationInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationLikeTextRecommendationV2Args = {
	input: LikeTextRecommendationInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationPanicArgs = {
	input: Scalars['Boolean']['input'];
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationPlayTitleArgs = {
	input: PlayTitleInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationPollForTvJwtArgs = {
	input: PollForTvJwtInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationRegisterDeviceIdArgs = {
	input: Scalars['String']['input'];
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationRegisterSessionStartArgs = {
	input: RegisterSessionStartInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationReportFeedbackArgs = {
	input: ReportFeedbackInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationSendSraBidArgs = {
	input: SendSraBidInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationSetBundleArgs = {
	input: SetBundleInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationSetInCustomlistArgs = {
	input: SetInCustomlistInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationSetInDislikelistArgs = {
	input: SetInTitleListInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationSetInFollowedListArgs = {
	input: SetInFollowedListInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationSetInLikelistArgs = {
	input: SetInTitleListInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationSetInMysportlistArgs = {
	input: SetInTitleListInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationSetInSeenlistArgs = {
	input: SetInSeenlistInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationSetInSeenlistBatchArgs = {
	input: SetInSeenlistBatchInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationSetInTvShowTrackingArgs = {
	input: SetInTitleListInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationSetInWatchlistArgs = {
	input: SetInTitleListInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationSetInWatchlistV2Args = {
	input: SetInTitleListInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationSetPackageArgs = {
	input: SetPackageInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationTrackEventArgs = {
	input: TrackEventInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationTrackEventsArgs = {
	input: TrackEventsInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationTrackSraEventArgs = {
	input: TrackSraEventInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationTrackSraEventsArgs = {
	input: TrackSraEventsInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationUpdateCustomlistArgs = {
	input: UpdateCustomlistInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationUpdateProfileArgs = {
	input: UpdateProfileInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationUpdateSettingsArgs = {
	input: UpdateSettingsInput;
};

/** The mutation root of JustWatch's GraphQL interface. */
export type MutationUpdateTextRecommendationArgs = {
	input: UpdateTextRecommendationInput;
};

/** A connection object for the mySport event buckets. */
export type MySportEventBucketsConnection = {
	__typename?: 'MySportEventBucketsConnection';
	/** The edges for each of the buckets. */
	edges?: Maybe<Array<SportEventBucketsEdgeV2>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
};

/** A connection object for the mySport events. */
export type MySportEventsConnection = {
	__typename?: 'MySportEventsConnection';
	/** The edges for each of the items. */
	edges?: Maybe<Array<SportEventsEdgeV2>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** The total number of items. */
	totalCount: Scalars['Int']['output'];
};

/** The different ways for the new timeline upcoming & leaving soon buckets. Instead of a particular date, grouped by ranges. */
export enum NewDateRangeBucket {
	/** Titles belong to the next 7 days, excluding tomorrow content. */
	Next_7Days = 'NEXT_7_DAYS',
	/** Titles for next 30 days excluding the next 7 days content. */
	Next_30Days = 'NEXT_30_DAYS',
	/** Titles for today. */
	Today = 'TODAY',
	/** Titles for tomorrow. i.e, next 24 hours content. */
	Tomorrow = 'TOMORROW',
}

/** The different types of new page filter */
export enum NewPageType {
	/** Contents that are leaving soon from the package */
	LeavingSoon = 'LEAVING_SOON',
	/** New offer content that are currently available in the pacakages. */
	New = 'NEW',
	/** On-sale content on the selected packages which considers only buy/rent packages into account. */
	PriceDrop = 'PRICE_DROP',
	/** Upcoming content to be added to the package based on the offer available date. */
	Upcoming = 'UPCOMING',
}

/** The different ways of aggregating the new timeline results. */
export enum NewTitleAggregation {
	/** We only aggregate by date. */
	Date = 'DATE',
	/** We aggregate by date/package. */
	DatePackage = 'DATE_PACKAGE',
}

/** A list of the possible aggregation keys being returned, depending on the aggregation type. */
export type NewTitleAggregationKey =
	| BucketAggregationKey
	| BucketPackageAggregationKey
	| DateAggregationKey
	| DatePackageAggregationKey;

/** A connection object for the new title buckets. */
export type NewTitleBucketsConnection = {
	__typename?: 'NewTitleBucketsConnection';
	/** The edges for each of the buckets. */
	edges?: Maybe<Array<NewTitleBucketsEdge>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
};

/** An edge object for the new title buckets. */
export type NewTitleBucketsEdge = {
	__typename?: 'NewTitleBucketsEdge';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The bucket aggregation key. */
	key: NewTitleAggregationKey;
	/** The node represented by this edge. */
	node: NewTitlesConnection;
};

/** A connection object for the new titles. */
export type NewTitlesConnection = {
	__typename?: 'NewTitlesConnection';
	/**
	 * Indicates if a sponsored recommendation is currently active for the user.
	 * @deprecated Use 'sponsoredAd' instead.
	 */
	activeCampaign?: Maybe<SponsoredRecommendationCampaign>;
	/** The edges for each of the items. */
	edges?: Maybe<Array<NewTitlesEdge>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** Indicates if there's a Sponsored Recommendation Ad to display. */
	sponsoredAd?: Maybe<SponsoredRecommendationAd>;
	/** The total number of items. */
	totalCount: Scalars['Int']['output'];
};

/** An edge object for the new titles. */
export type NewTitlesEdge = {
	__typename?: 'NewTitlesEdge';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The new offer (or one of the new offers) that triggered the node to be there. */
	newOffer?: Maybe<Offer>;
	/** The node represented by this edge. */
	node: MovieOrSeason;
};

/** An edge object for the new titles. */
export type NewTitlesEdgeNewOfferArgs = {
	platform: Platform;
};

/** An object with an ID. */
export type Node = {
	/** The global node ID. */
	id: Scalars['ID']['output'];
};

/** Represents a node override for a campaign. */
export type NodeOverride = {
	__typename?: 'NodeOverride';
	/** The node ID. */
	nodeId: Scalars['ID']['output'];
	/** The image url. It's an absolute URL. */
	promotionalImageUrl?: Maybe<Scalars['String']['output']>;
	/** The watch now offer. */
	watchNowOffer?: Maybe<Offer>;
};

/** The type of a Content object. */
export enum ObjectType {
	/** That's an author. */
	Author = 'AUTHOR',
	/** That's a generic title list. */
	GenericTitleList = 'GENERIC_TITLE_LIST',
	/** That's a movie. */
	Movie = 'MOVIE',
	/** That's a static page on the website. */
	Page = 'PAGE',
	/** That's a person. */
	Person = 'PERSON',
	/** That's a show. */
	Show = 'SHOW',
	/** That's an episode. */
	ShowEpisode = 'SHOW_EPISODE',
	/** That's a season. */
	ShowSeason = 'SHOW_SEASON',
	/** That's a sport. */
	Sport = 'SPORT',
	/** That's a sport competition. */
	SportCompetition = 'SPORT_COMPETITION',
	/** That's a sport competitor. */
	SportCompetitor = 'SPORT_COMPETITOR',
	/** That's a sport event. */
	SportEvent = 'SPORT_EVENT',
}

/**
 * Defines the offering of a package for a certain Content title. Unique key is (jwEntityID, country, packageId, monetizationType, presentationType).
 *
 * e.g. 'The Matrix', 'US', 'Netflix', 'Flatrate', '4K'
 */
export type Offer = Node & {
	__typename?: 'Offer';
	/** The audio languages available for the offer. */
	audioLanguages: Array<Scalars['Language']['output']>;
	/** The audio technologies available for the offer. */
	audioTechnology: Array<AudioTechnology>;
	/** The time at which the offer will arrive on the package. */
	availableFromTime?: Maybe<Scalars['Time']['output']>;
	/** The date at which the offer will be leaving the package. */
	availableTo?: Maybe<Scalars['Date']['output']>;
	/** The time at which the offer will be leaving the package. */
	availableToTime?: Maybe<Scalars['Time']['output']>;
	/** The pricings currency. */
	currency?: Maybe<Scalars['String']['output']>;
	/** The date when the offer was created. In the case of aggregated offers, it becomes the max/last creation date of the aggregated offers. */
	dateCreated?: Maybe<Scalars['Date']['output']>;
	/** Deeplink URL directly playing an offer. */
	deeplinkURL?: Maybe<Scalars['String']['output']>;
	/** In the case of aggregated offers, the number of aggregated elements. */
	elementCount?: Maybe<Scalars['Int']['output']>;
	/** The global node ID. */
	id: Scalars['ID']['output'];
	/** The date of the last offer price change. */
	lastChangeDate?: Maybe<Scalars['Date']['output']>;
	/** The price difference in percentage between the previous offer and the current one. */
	lastChangePercent?: Maybe<Scalars['Float']['output']>;
	/** The formatted (price + currency) price that the offer had before it changed to the current one. */
	lastChangeRetailPrice?: Maybe<Scalars['String']['output']>;
	/** The raw price that the offer had before it changed to the current one. */
	lastChangeRetailPriceValue?: Maybe<Scalars['Float']['output']>;
	/** The lowest available price (WITH CURRENCY) of an episode in a season (or, in a show, the lowest available episode price across all available seasons). */
	minRetailPrice?: Maybe<Scalars['String']['output']>;
	/** TO DEPRECATE - The lowest available price of an episode, in a season (or, in a show, the lowest available episode price across all available seasons). */
	minRetailPriceV2: Scalars['Float']['output'];
	/** The lowest available price of an episode, in a season (or, in a show, the lowest available episode price across all available seasons). */
	minRetailPriceValue: Scalars['Float']['output'];
	/** The monetization of the offer. */
	monetizationType: MonetizationType;
	/** In the case of aggregated offers, the number of new aggregated elements, created at the 'dateCreated' date. */
	newElementCount?: Maybe<Scalars['Int']['output']>;
	/** The seasons which are included in the offer, for shows. */
	offerSeasons?: Maybe<Array<Scalars['String']['output']>>;
	/** The related package object. */
	package: Package;
	/** The StandardWebURL of the offer after pre-affiliation through UCT2. */
	preAffiliatedStandardWebURL?: Maybe<Scalars['String']['output']>;
	/** The presentation format of the offer. */
	presentationType: PresentationType;
	/** The formatted (price + currency) price of the offer (monetization type BUY/RENT). */
	retailPrice?: Maybe<Scalars['String']['output']>;
	/** The raw price of the offer (monetization type BUY/RENT). */
	retailPriceValue?: Maybe<Scalars['Float']['output']>;
	/** Website URL of the offer page. */
	standardWebURL?: Maybe<Scalars['String']['output']>;
	/** Stream Url of the offer. */
	streamUrl?: Maybe<Scalars['String']['output']>;
	/** The subtitle languages available for the offer. */
	subtitleLanguages: Array<Scalars['Language']['output']>;
	/** The parent title of the offer. */
	title: MovieOrShowOrSeasonOrEpisode;
	/** The nature of the offer. */
	type: OfferType;
	/** The time at which the offers were recently updated. */
	updatedAt?: Maybe<Scalars['Time']['output']>;
	/** The video technologies available for the offer. */
	videoTechnology: Array<VideoTechnology>;
};

/**
 * Defines the offering of a package for a certain Content title. Unique key is (jwEntityID, country, packageId, monetizationType, presentationType).
 *
 * e.g. 'The Matrix', 'US', 'Netflix', 'Flatrate', '4K'
 */
export type OfferAudioLanguagesArgs = {
	language?: InputMaybe<Scalars['Language']['input']>;
};

/**
 * Defines the offering of a package for a certain Content title. Unique key is (jwEntityID, country, packageId, monetizationType, presentationType).
 *
 * e.g. 'The Matrix', 'US', 'Netflix', 'Flatrate', '4K'
 */
export type OfferDeeplinkUrlArgs = {
	platform: Platform;
};

/**
 * Defines the offering of a package for a certain Content title. Unique key is (jwEntityID, country, packageId, monetizationType, presentationType).
 *
 * e.g. 'The Matrix', 'US', 'Netflix', 'Flatrate', '4K'
 */
export type OfferLastChangeRetailPriceArgs = {
	language: Scalars['Language']['input'];
};

/**
 * Defines the offering of a package for a certain Content title. Unique key is (jwEntityID, country, packageId, monetizationType, presentationType).
 *
 * e.g. 'The Matrix', 'US', 'Netflix', 'Flatrate', '4K'
 */
export type OfferMinRetailPriceArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
	platform: Platform;
};

/**
 * Defines the offering of a package for a certain Content title. Unique key is (jwEntityID, country, packageId, monetizationType, presentationType).
 *
 * e.g. 'The Matrix', 'US', 'Netflix', 'Flatrate', '4K'
 */
export type OfferMinRetailPriceV2Args = {
	country: Scalars['Country']['input'];
	platform: Platform;
};

/**
 * Defines the offering of a package for a certain Content title. Unique key is (jwEntityID, country, packageId, monetizationType, presentationType).
 *
 * e.g. 'The Matrix', 'US', 'Netflix', 'Flatrate', '4K'
 */
export type OfferMinRetailPriceValueArgs = {
	country: Scalars['Country']['input'];
	platform: Platform;
};

/**
 * Defines the offering of a package for a certain Content title. Unique key is (jwEntityID, country, packageId, monetizationType, presentationType).
 *
 * e.g. 'The Matrix', 'US', 'Netflix', 'Flatrate', '4K'
 */
export type OfferRetailPriceArgs = {
	language: Scalars['Language']['input'];
};

/**
 * Defines the offering of a package for a certain Content title. Unique key is (jwEntityID, country, packageId, monetizationType, presentationType).
 *
 * e.g. 'The Matrix', 'US', 'Netflix', 'Flatrate', '4K'
 */
export type OfferSubtitleLanguagesArgs = {
	language?: InputMaybe<Scalars['Language']['input']>;
};

/** This filter allows filtering of a list of offers. */
export type OfferFilter = {
	/** Only keep the best offer per package. */
	bestOnly?: InputMaybe<Scalars['Boolean']['input']>;
	/** Exclude the offers having the given packages. Short names are expected (e.g. 'nfx' for Netflix). */
	excludePackages?: InputMaybe<Array<Scalars['String']['input']>>;
	/** Keep only the offers having the given monetization types. */
	monetizationTypes?: InputMaybe<Array<MonetizationType>>;
	/** Keep only the offers having the given packages. Short names are expected (e.g. 'nfx' for Netflix). */
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	/** Pre-affiliate with uct2 */
	preAffiliate?: InputMaybe<Scalars['Boolean']['input']>;
	/** Keep only the offers having the given presentation types. */
	presentationTypes?: InputMaybe<Array<PresentationType>>;
};

/** Indicates if an offer is raw or aggregated from other offers. */
export enum OfferType {
	/**
	 * Aggerated offers exist on shows/seasons.
	 * Usually, we don't get any offers for shows, they're mostly set at the episode level.
	 * That's why for example we aggregate offers for shows: so we don't have to subquery all the episodes to find out where is it watchable.
	 */
	Aggregated = 'AGGREGATED',
	/** A normal raw offer. */
	Standard = 'STANDARD',
}

/** An offer package. Defined by Content. */
export type Package = Node & {
	__typename?: 'Package';
	/**
	 * The parent of this addon.
	 *
	 * e.g. 'Amazon Prime Starz' channel has an 'Amazon Prime' parent.
	 */
	addonParent?: Maybe<Package>;
	/**
	 * The addons under this package.
	 *
	 * e.g. 'Amazon Prime' has an 'Amazon Prime Starz' channel.
	 */
	addons: Array<Package>;
	/**
	 * The bundles that contains the package.
	 *
	 * e.g. 'The Sky TV + Ultimate TV' bundle contains 'Sky Go' and 'Netflix'.
	 */
	bundles: Array<Bundle>;
	/**
	 * The clear human readable name of the package.
	 *
	 * e.g. 'Netflix', 'Amazon Prime'
	 */
	clearName: Scalars['String']['output'];
	/** Indicates if the package has a rectangular icon */
	hasRectangularIcon: Scalars['Boolean']['output'];
	/** Indicates if the package contains sports. */
	hasSport?: Maybe<Scalars['Boolean']['output']>;
	/** Indicates if the package has title contents. */
	hasTitles?: Maybe<Scalars['Boolean']['output']>;
	/**
	 * The package icon URL.
	 * It takes a desired size (called profile) and desired format as parameter.
	 *
	 * e.g. 's40' + 'webp' -> '/icon/207360008/s40/netflix.webp'
	 */
	icon: Scalars['String']['output'];
	/**
	 * The blur hash representation of the icon.
	 *
	 * Generated using https://github.com/woltapp/blurhash.
	 */
	iconBlurHash?: Maybe<Scalars['String']['output']>;
	/**
	 * The package wide icon URL.
	 * It takes a desired size (called profile) and desired format as parameter.
	 *
	 * e.g. 's160' + 'webp' -> '/icon/207360008/s160/netflix.webp'
	 */
	iconWide: Scalars['String']['output'];
	/**
	 * The blur hash representation of the wide icon.
	 *
	 * Generated using https://github.com/woltapp/blurhash.
	 */
	iconWideBlurHash?: Maybe<Scalars['String']['output']>;
	/** The global node ID. */
	id: Scalars['ID']['output'];
	/** The monetization types supported by the package. */
	monetizationTypes: Array<MonetizationType>;
	/** The Content ID of the package. */
	packageId: Scalars['Int']['output'];
	/** Available Offers for this Package */
	planOffers: Array<PackagePlanOffer>;
	/** Indicates if the package was selected by the user. */
	selected: Scalars['Boolean']['output'];
	/**
	 * The short version of the technical name using 3 letters.
	 *
	 * e.g. 'netflix' -> 'nfx'
	 */
	shortName: Scalars['String']['output'];
	/** The slug of the package, used to build the package landing page urls. */
	slug: Scalars['String']['output'];
	/**
	 * A unique string identifier for the package.
	 *
	 * e.g. 'netflix', 'amazonprime'
	 */
	technicalName: Scalars['String']['output'];
};

/** An offer package. Defined by Content. */
export type PackageAddonParentArgs = {
	country: Scalars['Country']['input'];
	platform: Platform;
};

/** An offer package. Defined by Content. */
export type PackageAddonsArgs = {
	country: Scalars['Country']['input'];
	platform: Platform;
};

/** An offer package. Defined by Content. */
export type PackageBundlesArgs = {
	country: Scalars['Country']['input'];
	platform: Platform;
	selectedOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

/** An offer package. Defined by Content. */
export type PackageHasRectangularIconArgs = {
	country: Scalars['Country']['input'];
	platform: Platform;
};

/** An offer package. Defined by Content. */
export type PackageHasSportArgs = {
	country: Scalars['Country']['input'];
	platform: Platform;
};

/** An offer package. Defined by Content. */
export type PackageHasTitlesArgs = {
	country: Scalars['Country']['input'];
	platform: Platform;
};

/** An offer package. Defined by Content. */
export type PackageIconArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<IconProfile>;
};

/** An offer package. Defined by Content. */
export type PackageIconWideArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<IconWideProfile>;
};

/** An offer package. Defined by Content. */
export type PackagePlanOffersArgs = {
	country: Scalars['Country']['input'];
	platform: Platform;
};

/** An offer package bundle. Defined by Content. */
export type PackagePlanOffer = {
	__typename?: 'PackagePlanOffer';
	/** Available from timestamp. */
	availableFromTimestamp?: Maybe<Scalars['Time']['output']>;
	/** Available to timestamp. */
	availableToTimestamp?: Maybe<Scalars['Time']['output']>;
	/** Nested trial offers. */
	children: Array<PackagePlanOffer>;
	/** The pricings currency. */
	currency?: Maybe<Scalars['String']['output']>;
	/** Deeplink URL directly playing an offer. */
	deeplinkURL?: Maybe<Scalars['String']['output']>;
	/** The duration of the offer in days. */
	durationDays?: Maybe<Scalars['Int']['output']>;
	/** Has the offer download option? */
	hasDownload?: Maybe<Scalars['Boolean']['output']>;
	/** Is the offer a trial? */
	isTrial?: Maybe<Scalars['Boolean']['output']>;
	/** The presentation type of the offer. */
	presentationType?: Maybe<PresentationType>;
	/** The retail price of the offer. */
	retailPrice?: Maybe<Scalars['String']['output']>;
	/** The raw price of the offer (monetization type BUY/RENT). */
	retailPriceValue?: Maybe<Scalars['Float']['output']>;
	/** The short description of the offer. */
	shortDescription?: Maybe<Scalars['String']['output']>;
	/** Number of simultaneous streams allowed. */
	simultaneousStreams?: Maybe<Scalars['Int']['output']>;
	/** Website URL of the offer page. */
	standardWebURL?: Maybe<Scalars['String']['output']>;
	/** The Title of the offer. */
	title?: Maybe<Scalars['String']['output']>;
	/** Video technology of the offer. */
	videoTechnology: Array<VideoTechnology>;
};

/** An offer package bundle. Defined by Content. */
export type PackagePlanOfferDeeplinkUrlArgs = {
	platform: Platform;
};

/** An offer package bundle. Defined by Content. */
export type PackagePlanOfferRetailPriceArgs = {
	language: Scalars['Language']['input'];
};

/** A static page. Defined by Content. */
export type Page = Node & {
	__typename?: 'Page';
	/** The url of the page. */
	fullPath?: Maybe<Scalars['String']['output']>;
	/** The global node ID. */
	id: Scalars['ID']['output'];
	/**
	 * The static page technical name.
	 *
	 * e.g. 'VIEW_POPULAR'
	 */
	technicalName: Scalars['String']['output'];
	/** The translated name of the page. */
	translation?: Maybe<Scalars['String']['output']>;
};

/** The information for paginating a connection. */
export type PageInfo = {
	__typename?: 'PageInfo';
	/** The pagination cursor to use to fetch the next page. */
	endCursor: Scalars['String']['output'];
	/** Flag indicating if a next page in present. */
	hasNextPage: Scalars['Boolean']['output'];
	/** Flag indicating if a previous page in present. */
	hasPreviousPage: Scalars['Boolean']['output'];
	/** The pagination cursor to use to fetch the previous page. */
	startCursor: Scalars['String']['output'];
};

/** Personalized person aggregating user data and static Content data. */
export type Person = Node &
	Title & {
		__typename?: 'Person';
		/** The person data. It is localized by country and language. */
		content: PersonContent;
		/** The global node ID. */
		id: Scalars['ID']['output'];
		/** The Content ID of the title. */
		objectId: Scalars['Int']['output'];
		/** The Content type of the title. */
		objectType: ObjectType;
	};

/** Personalized person aggregating user data and static Content data. */
export type PersonContentArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
};

/** Unpersonalized person data common to all the users, as defined by Content. It is localized by country and language. */
export type PersonContent = {
	__typename?: 'PersonContent';
	/** The date of birth of the person. */
	dateOfBirth?: Maybe<Scalars['Date']['output']>;
	/** The name of the person. */
	fullName: Scalars['String']['output'];
	/** A short description of the person. */
	shortDescription?: Maybe<Scalars['String']['output']>;
};

/** The person filter. */
export type PersonFilter = {
	/** Fuzzy search using the given query string. */
	searchQuery?: InputMaybe<Scalars['String']['input']>;
};

/** The platform on which the app is running. */
export enum Platform {
	/** This is for Android. */
	Android = 'ANDROID',
	/** This is an Android TV. */
	AndroidTv = 'ANDROID_TV',
	/** This is a Fire TV. */
	FireTv = 'FIRE_TV',
	/** This is for iOS. */
	Ios = 'IOS',
	/** This is a PlayStation. */
	Playstation = 'PLAYSTATION',
	/** This is for Roku OS. */
	RokuOs = 'ROKU_OS',
	/** This is a Tizen based Smart TV (mainly Samsung). */
	Tizenos = 'TIZENOS',
	/** This is an Apple TV. */
	Tvos = 'TVOS',
	/** This is for the web app. */
	Web = 'WEB',
	/** This is a webOS based Smart TV (mainly LG). */
	Webos = 'WEBOS',
	/** This is a Xbox. */
	Xbox = 'XBOX',
}

/** The input needed to designate the title that will played. */
export type PlayTitleInput = {
	/** The optional id of the device we want to play the title on. We broadcast if not set. WARNING: The node ID is expected here. */
	deviceID?: InputMaybe<Scalars['ID']['input']>;
	/** The global title ID as defined by Content. */
	jwEntityID: Scalars['String']['input'];
};

/** The payload response of the play title instruction. */
export type PlayTitlePayload = {
	__typename?: 'PlayTitlePayload';
	/**
	 * The ids of the devices that received the event.
	 * @deprecated Please use the 'devices' field
	 */
	deviceIDs: Array<Scalars['ID']['output']>;
	/** The devices that received the event. */
	devices: Array<Device>;
	/** Indicates if the push was successful, i.e. if at least one device received the event. */
	success: Scalars['Boolean']['output'];
	/** The title object that the listening devices received. */
	title: MovieOrShowOrSeasonOrEpisode;
};

/** The code and UUID obtained from the TV code endpoint. */
export type PollForTvJwtInput = {
	/** The code we're displaying to the user. */
	code: Scalars['String']['input'];
	/** The security UUID. */
	uuid: Scalars['String']['input'];
};

/** The payload response of the TV JWT polling. */
export type PollForTvJwtPayload = {
	__typename?: 'PollForTvJwtPayload';
	/** The Firebase JWT of the linked account. Null if no account is connected yet. */
	jwt?: Maybe<Scalars['String']['output']>;
};

/** A connection object for the popular people. */
export type PopularPeopleConnection = {
	__typename?: 'PopularPeopleConnection';
	/** The edges for each of the items. */
	edges?: Maybe<Array<PopularPeopleEdge>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** The total number of items. */
	totalCount: Scalars['Int']['output'];
};

/** An edge object for the popular people. */
export type PopularPeopleEdge = {
	__typename?: 'PopularPeopleEdge';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The node represented by this edge. */
	node: Person;
};

/** The field/info to order titles by. */
export enum PopularPeopleSorting {
	/** Sorted by TMDB popularity. */
	TmdbPopularity = 'TMDB_POPULARITY',
}

/** A connection object for the popular titles. */
export type PopularTitlesConnection = {
	__typename?: 'PopularTitlesConnection';
	/**
	 * Indicates if a sponsored recommendation is currently active for the user.
	 * @deprecated Use 'sponsoredAd' instead.
	 */
	activeCampaign?: Maybe<SponsoredRecommendationCampaign>;
	/** The edges for each of the items. */
	edges?: Maybe<Array<PopularTitlesEdge>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** Indicates if there's a Sponsored Recommendation Ad to display. */
	sponsoredAd?: Maybe<SponsoredRecommendationAd>;
	/** The total number of items. */
	totalCount: Scalars['Int']['output'];
};

/** An edge object for the popular titles. */
export type PopularTitlesEdge = {
	__typename?: 'PopularTitlesEdge';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The node represented by this edge. */
	node: MovieOrShow;
};

/** The field/info to order titles by. */
export enum PopularTitlesSorting {
	/** Sorted by title. */
	Alphabetical = 'ALPHABETICAL',
	/** Sorted by offer Available To date. */
	AvailableTo = 'AVAILABLE_TO',
	/** Sorted by IMDB score. */
	ImdbScore = 'IMDB_SCORE',
	/** Sorted by JW popularity score. */
	Popular = 'POPULAR',
	/** Sorted by JW popularity, for a 7 day window. */
	Popular_7Days = 'POPULAR_7_DAYS',
	/** Sorted by JW popularity, for a 180 day window. */
	Popular_180Days = 'POPULAR_180_DAYS',
	/** Sorted randomly, based on the provided randomSeed. */
	Random = 'RANDOM',
	/** Sorted by release year. */
	ReleaseYear = 'RELEASE_YEAR',
	/** Sorted by User Taste. */
	Relevance = 'RELEVANCE',
	/** Sorted by runtime. */
	Runtime = 'RUNTIME',
	/** Sorted by TMDB popularity. */
	TmdbPopularity = 'TMDB_POPULARITY',
	/** Sorted by JW trending score. */
	Trending = 'TRENDING',
}

/** Popularity rank for each title and its related titles in the rankings. */
export type PopularityRank = {
	__typename?: 'PopularityRank';
	/** The popularity rank for the current object. */
	rank: Scalars['Int']['output'];
	/** List of neighboring popular title objects and its rank for the streaming chart. */
	titles: Array<MovieOrShowOrSeason>;
	/** The moving window of the current title popularity in the last 30 days. */
	trend: ModuleContentTitleTrend;
	/** Rank difference change popularity in the last 30 days. */
	trendDifference: Scalars['Int']['output'];
	/** Time at which the streaming chart data updated. */
	updatedAt?: Maybe<Scalars['Time']['output']>;
};

/** The type for which the popularity ranks are based on */
export enum PopularityRankTypes {
	/** Daily popularity titles for the same content type. for ex: if the title movie is chosen all the titles returned will be the movie type. */
	DailyPopularitySameContentType = 'DAILY_POPULARITY_SAME_CONTENT_TYPE',
	/** Monthly popularity titles for the same content type. */
	MonthlyPopularitySameContentType = 'MONTHLY_POPULARITY_SAME_CONTENT_TYPE',
	/** Weekly popularity titles for the same content type. for ex: if the title movie is chosen all the titles returned will be the movie type. */
	WeeklyPopularitySameContentType = 'WEEKLY_POPULARITY_SAME_CONTENT_TYPE',
}

/** A list of image sizes supported by the scaler for posters. */
export enum PosterProfile {
	/** 166x236 */
	S166 = 'S166',
	/** 276x392 */
	S276 = 'S276',
	/** 332x472 */
	S332 = 'S332',
	/** 592x841 */
	S592 = 'S592',
	/** 718x1024 */
	S718 = 'S718',
}

/** The quality/medium of an offer. */
export enum PresentationType {
	/** A Bluray medium. */
	Bluray = 'BLURAY',
	/** A canvas medium. Used for cinema offers. */
	Canvas = 'CANVAS',
	/** A DVD medium. */
	Dvd = 'DVD',
	/** An HD quality. */
	Hd = 'HD',
	/** An SD quality. */
	Sd = 'SD',
	/** A 4K or UHD quality. */
	'4K' = '_4K',
}

/**
 * A profile represents a user or author that has their own (public) profile page.
 * A profile can write text recommendations, and expose them and their lists on their profile page.
 */
export type Profile = {
	__typename?: 'Profile';
	/** The articles written by the profile, if applicable. */
	articles: ArticlesConnection;
	/** The author ID as it was in directus. Temporarily used to link to guide articles */
	authorId?: Maybe<Scalars['ID']['output']>;
	/** The profile's avatar url */
	avatarUrl?: Maybe<Scalars['String']['output']>;
	/** The bio text of the profile */
	bio?: Maybe<Scalars['String']['output']>;
	/** The linked content person ID. These are the profiles content generates for actors, directors, producers, etc. with all their related titles. */
	contentPersonId?: Maybe<Scalars['String']['output']>;
	/** The country of the profile. */
	country: Scalars['Country']['output'];
	/** If applicable, the credit relates to an actor or director's profile */
	credit?: Maybe<Credit>;
	/** The contents of this Profiles DislikeList */
	dislikeList: ProfileTitleListConnection;
	/** The user's display name */
	displayName: Scalars['String']['output'];
	/** Links to the users website, social media, etc... */
	externalUrls?: Maybe<Array<ProfileSpecificLink>>;
	/** The user's first name */
	firstName: Scalars['String']['output'];
	/** If the profile is complete. */
	isComplete: Scalars['Boolean']['output'];
	/** Whether the profile is public. */
	isPublic: Scalars['Boolean']['output'];
	/** The user's last name */
	lastName: Scalars['String']['output'];
	/** The contents of this Profiles LikeList */
	likeList: ProfileTitleListConnection;
	/** The location of the Profile, as shown on profile page */
	location?: Maybe<Scalars['String']['output']>;
	/** Whether this profile is owned by the currently logged in user. */
	ownedByUser: Scalars['Boolean']['output'];
	/** The profile type. */
	profileType: ProfileType;
	/** The url to the profile page */
	profileUrl: Scalars['String']['output'];
	/** Latest watched - this will be a combination of seenList and TVST */
	seenList: ProfileTitleListConnection;
	/** The Why-To-Watch texts written by this Profile */
	textRecommendations: ProfileTextRecommendationConnection;
	/** The profile's thumbnail url - like the avatar, but smaller */
	thumbnailUrl?: Maybe<Scalars['String']['output']>;
	/** The UUID of the profile, corresponding to the Profile ID in the db. */
	uuid: Scalars['String']['output'];
	/** Want to watch - possibly also a combination of seenList and TVST */
	watchList: ProfileTitleListConnection;
};

/**
 * A profile represents a user or author that has their own (public) profile page.
 * A profile can write text recommendations, and expose them and their lists on their profile page.
 */
export type ProfileArticlesArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<ArticleFilter>;
	first?: Scalars['Int']['input'];
};

/**
 * A profile represents a user or author that has their own (public) profile page.
 * A profile can write text recommendations, and expose them and their lists on their profile page.
 */
export type ProfileDislikeListArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	first?: Scalars['Int']['input'];
};

/**
 * A profile represents a user or author that has their own (public) profile page.
 * A profile can write text recommendations, and expose them and their lists on their profile page.
 */
export type ProfileLikeListArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	first?: Scalars['Int']['input'];
};

/**
 * A profile represents a user or author that has their own (public) profile page.
 * A profile can write text recommendations, and expose them and their lists on their profile page.
 */
export type ProfileSeenListArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	first?: Scalars['Int']['input'];
};

/**
 * A profile represents a user or author that has their own (public) profile page.
 * A profile can write text recommendations, and expose them and their lists on their profile page.
 */
export type ProfileTextRecommendationsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country: Scalars['Country']['input'];
	first?: Scalars['Int']['input'];
	language: Scalars['Language']['input'];
};

/**
 * A profile represents a user or author that has their own (public) profile page.
 * A profile can write text recommendations, and expose them and their lists on their profile page.
 */
export type ProfileWatchListArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	first?: Scalars['Int']['input'];
};

/**
 * The profile specific links type.
 * Can be used to link to the users social media, website, etc...
 */
export type ProfileSpecificLink = {
	__typename?: 'ProfileSpecificLink';
	/** Optional text to be shown on the link */
	name?: Maybe<Scalars['String']['output']>;
	/** The type of link this is, e.g. website, twitter... */
	type: ProfileSpecificLinkType;
	/** The link itself */
	url: Scalars['String']['output'];
};

/** The different types of profile-specific links */
export enum ProfileSpecificLinkType {
	Facebook = 'FACEBOOK',
	Imdb = 'IMDB',
	Instagram = 'INSTAGRAM',
	Letterboxd = 'LETTERBOXD',
	Linkedin = 'LINKEDIN',
	Other = 'OTHER',
	Tiktok = 'TIKTOK',
	Twitter = 'TWITTER',
	Website = 'WEBSITE',
	Youtube = 'YOUTUBE',
}

/** A list of text recommendations to be shown on a profile. */
export type ProfileTextRecommendationConnection = {
	__typename?: 'ProfileTextRecommendationConnection';
	/** An array of the text recommendations. */
	edges: Array<ProfileTextRecommendationEdge>;
	/** The total number of text recommendations about movies. */
	moviesCount: Scalars['Int']['output'];
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** The total number of text recommendations about shows. */
	showsCount: Scalars['Int']['output'];
	/** The total number of text recommendations. */
	totalCount: Scalars['Int']['output'];
};

/** An edge object for profile text recommendations. */
export type ProfileTextRecommendationEdge = {
	__typename?: 'ProfileTextRecommendationEdge';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The node represented by this edge. */
	node: TextRecommendation;
};

/** A title list as it is shown on a profile page. This can include watchlist, seenlist, etc.. */
export type ProfileTitleListConnection = {
	__typename?: 'ProfileTitleListConnection';
	/** An array of the titles in the list. */
	edges: Array<ProfileTitleListEdge>;
	/** The total number of movies in the list. */
	moviesCount: Scalars['Int']['output'];
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** The total number of shows in the list. */
	showsCount: Scalars['Int']['output'];
	/** The total number of entities in the list. */
	totalCount: Scalars['Int']['output'];
};

/** An edge object for profile title lists. */
export type ProfileTitleListEdge = {
	__typename?: 'ProfileTitleListEdge';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The node represented by this edge. */
	node: MovieOrShowOrSeason;
};

/** The type of a profile. */
export enum ProfileType {
	/** Actor profile type. */
	Actor = 'ACTOR',
	/** Director profile type. */
	Director = 'DIRECTOR',
	/** Influencer profile type. */
	Influencer = 'INFLUENCER',
	/** Justwatch Editor profile type. */
	JwEditor = 'JW_EDITOR',
	/**
	 * Justwatch employee profile type.
	 * @deprecated This profile type is deprecated and will be removed in the future.
	 */
	JwEmployee = 'JW_EMPLOYEE',
	/** Producer profile type. */
	Producer = 'PRODUCER',
	/** Justwatch Pro user */
	ProUser = 'PRO_USER',
	/** Default profile type. */
	User = 'USER',
	/** VIP profile type. */
	Vip = 'VIP',
}

/** An edge object for promoted bundles. */
export type PromotedBundleEdge = {
	__typename?: 'PromotedBundleEdge';
	/** The node represented by this edge. */
	node: Bundle;
	/** The offer that triggered the bundle promotion. */
	offer: Offer;
	/** The promotion url for the bundle. */
	promotionUrl: Scalars['String']['output'];
};

/** Represents an image url override for a campaign. */
export type PromotionalImageUrlOverride = {
	__typename?: 'PromotionalImageUrlOverride';
	/** The id of the node. */
	id: Scalars['String']['output'];
	/** The image url. It's an absolute URL. */
	promotionalImageUrl: Scalars['String']['output'];
};

/** Represents a promotional video. */
export type PromotionalVideo = {
	__typename?: 'PromotionalVideo';
	/** The video url. */
	url: Scalars['String']['output'];
};

export type ProviderUrl = {
	__typename?: 'ProviderUrl';
	/** The short name of the provider. */
	shortname: Scalars['String']['output'];
	/** This provider-specific URL, for whatever parent filters have been applied (e.g. subgenre) */
	url: Url;
};

/** The query root of JustWatch's GraphQL interface. */
export type Query = {
	__typename?: 'Query';
	/**
	 * Get the current active campaigns for the user.
	 * @deprecated We're now driving the holdout groups on the backend.
	 */
	activeSponsoredRecommendationCampaigns?: Maybe<Array<SponsoredRecommendationCampaign>>;
	/** Returns all the age certifications supported by JustWatch. */
	ageCertifications: Array<AgeCertification>;
	/** Runs a title search and aggregates the subgenres. Returns subgenres. */
	aggregatedSubgenres: Array<AggregatedSubgenre>;
	/** Search for articles written by authors. */
	articles: ArticlesConnection;
	/** Returns a list of discovery modules. */
	discoveryModules: DiscoveryModulesConnection;
	/** Returns a list of discovery modules. */
	discoveryModulesV2: DiscoveryModuleLayout;
	/** Get the Ephemeral module contents based on the technical and anchor mood type. */
	ephemeralModules: EphemeralModule;
	/** Public generic title lists. */
	genericTitleList: GenericTitleListConnection;
	/** My lists and the public title lists that I follow. */
	genericTitleLists: GenericTitleListsConnection;
	/** Returns all the genres supported by JustWatch. */
	genres: Array<Genre>;
	/** Checks if a user is a member of any of a list of audiences. Returns a map of listids and bools. */
	getAudienceMap: AudienceMapResponse;
	/** Gets all available appdevs for use with staging mobile app. */
	getAvailableAppDevs: Array<AppDev>;
	getConfig: Config;
	/** Gets the stripe checkout session status */
	getStripeCheckoutSessionStatus?: Maybe<StripeCheckoutSessionStatusType>;
	/** Returns all the languages supported by JustWatch. */
	languages: Array<LanguageState>;
	/** Returns all the locales/countries supported by JustWatch. */
	locales: Array<LocaleState>;
	/** Runs a mySport event search and returns an aggregated list of mySport events. */
	mySportEventBuckets: MySportEventBucketsConnection;
	/** Runs a mySport event search. */
	mySportEvents: MySportEventsConnection;
	/** Runs a title search and returns an aggregated list of titles. */
	newTitleBuckets: NewTitleBucketsConnection;
	/** Runs a title search for newly releases titles. Returns movies and seasons. */
	newTitles: NewTitlesConnection;
	/** Fetches an object given its ID. */
	node?: Maybe<Node>;
	/** Fetches multiple objects by IDs. */
	nodes: Array<Maybe<Node>>;
	/** Returns the packages available in your country on your platform. */
	packages: Array<Package>;
	/** Returns all the pages supported by JustWatch. */
	pages: Array<Page>;
	/** Returns a list of devices linked to the user account and ready to play a title. */
	playTitleDevices: Array<Device>;
	/** Runs a person search and returns a paginated list of results. Returns people. */
	popularPeople: PopularPeopleConnection;
	/** Runs a title search and returns a paginated list of results. Returns movies and shows. */
	popularTitles: PopularTitlesConnection;
	/** Get an (author) profile by their fullPath. The slug is expected e.g. /uk/author/boaty-mc-boatface */
	profile?: Maybe<Profile>;
	recommendedTitles: RecommendedTitlesConnection;
	/** Returns the user settings. */
	settings: Settings;
	/** Returns all the sport competitions */
	sportCompetitionsV2: SportCompetitionsConnectionV2;
	/** Runs a sport event search and returns an aggregated list of sport events. */
	sportEventBucketsV2: SportEventBucketsConnectionV2;
	/** Returns a sport event matrix. */
	sportEventMatrix: SportEventMatrix;
	/** Runs a sport event search. */
	sportEventsV2: SportEventsConnectionV2;
	/** Returns some user statistics. */
	stats: UserStats;
	/** Get the streaming charts titles based on the popularity rank range. */
	streamingCharts: StreamingChartsConnection;
	/** Returns all the subgenres supported by JustWatch, sorted by popularity in each objectType. Provider shortnames are expected */
	subgenres: Array<GenericTitleList>;
	/** Fetch the next title suggestions based on the taste survey input selections. */
	tasteSurveyNextTitle: TasteSurveyNextTitle;
	/** Get text recommendations associated to a given jw_entity_id */
	textRecommendations?: Maybe<Array<TextRecommendation>>;
	/**
	 * Returns the title list of a user.
	 * @deprecated Please use the 'titleListV2' query
	 */
	titleList: TitleListConnection;
	/** Returns the title list of a user. */
	titleListV2: TitleListConnectionV2;
	/** Returns the tv show tracking data for a user. */
	tvShowTracking: TvShowTrackingConnection;
	/**
	 * Looks up a URL by its full path (e.g. '/de/Film/Spider-Man-Far-from-Home').
	 * @deprecated No longer supported
	 */
	url: Url;
	/**
	 * Looks up a URL by its full path (e.g. '/de/Film/Spider-Man-Far-from-Home').
	 *
	 * By setting a node ID in an 'id' query parameter, the front-end can tell the api to fetch and attach the node to the url.
	 */
	urlV2: Url;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryActiveSponsoredRecommendationCampaignsArgs = {
	input: SponsoredRecommendationsInput;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryAgeCertificationsArgs = {
	country: Scalars['Country']['input'];
	objectType?: InputMaybe<AgeCertificationObjectType>;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryAggregatedSubgenresArgs = {
	country: Scalars['Country']['input'];
	filter?: InputMaybe<TitleFilter>;
	first?: Scalars['Int']['input'];
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryArticlesArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<ArticleFilter>;
	first?: Scalars['Int']['input'];
	offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryDiscoveryModulesArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	allowSponsoredRecommendations?: InputMaybe<SponsoredRecommendationsInput>;
	country: Scalars['Country']['input'];
	first?: Scalars['Int']['input'];
	forceNewDiscoverySession?: Scalars['Boolean']['input'];
	language: Scalars['Language']['input'];
	postFilter?: InputMaybe<DiscoveryModulesPostFilter>;
	sponsoredRecommendationMode?: Scalars['Boolean']['input'];
	supportedModuleTemplates: Array<Scalars['String']['input']>;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryDiscoveryModulesV2Args = {
	allowSponsoredRecommendations?: InputMaybe<SponsoredRecommendationsInput>;
	country: Scalars['Country']['input'];
	platform: Platform;
	promotionMode?: InputMaybe<ModulePromotionMode>;
	supportedContentTypes: Array<ModuleContentType>;
	testingMode?: Scalars['Boolean']['input'];
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryEphemeralModulesArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
	moduleTemplates: Array<EphemeralModuleTemplateInput>;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryGenericTitleListArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<TitleFilter>;
	first?: Scalars['Int']['input'];
	id: Scalars['ID']['input'];
	language?: InputMaybe<Scalars['Language']['input']>;
	sortBy?: GenericTitleListSorting;
	sortRandomSeed?: Scalars['Int']['input'];
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryGenericTitleListsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	first?: Scalars['Int']['input'];
	type: GenericTitleListsType;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryGetAudienceMapArgs = {
	input: AudienceCheckQueryInput;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryGetConfigArgs = {
	filter?: InputMaybe<Scalars['String']['input']>;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryGetStripeCheckoutSessionStatusArgs = {
	input: StripeCheckoutSessionStatusInput;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryMySportEventBucketsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	bucketSize?: InputMaybe<Scalars['Int']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<SportEventFilter>;
	first?: Scalars['Int']['input'];
	groupBy?: SportEventAggregation;
	sortBy?: SportEventSorting;
	sortOrder?: SortOrder;
	timezoneOffset: Scalars['Int']['input'];
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryMySportEventsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<SportEventFilter>;
	first?: Scalars['Int']['input'];
	sortOrder?: SortOrder;
	timezoneOffset: Scalars['Int']['input'];
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryNewTitleBucketsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	allowSponsoredRecommendations?: InputMaybe<SponsoredRecommendationsInput>;
	bucketSize?: InputMaybe<Scalars['Int']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<TitleFilter>;
	first?: Scalars['Int']['input'];
	groupBy?: NewTitleAggregation;
	pageType?: NewPageType;
	priceDrops?: Scalars['Boolean']['input'];
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryNewTitlesArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	allowSponsoredRecommendations?: InputMaybe<SponsoredRecommendationsInput>;
	bucketType?: InputMaybe<NewDateRangeBucket>;
	country: Scalars['Country']['input'];
	date?: InputMaybe<Scalars['Date']['input']>;
	filter?: InputMaybe<TitleFilter>;
	first?: Scalars['Int']['input'];
	offset?: InputMaybe<Scalars['Int']['input']>;
	package?: InputMaybe<Scalars['String']['input']>;
	pageType?: NewPageType;
	priceDrops?: Scalars['Boolean']['input'];
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryNodeArgs = {
	id: Scalars['ID']['input'];
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryNodesArgs = {
	ids: Array<Scalars['ID']['input']>;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryPackagesArgs = {
	country: Scalars['Country']['input'];
	hasSport?: InputMaybe<Scalars['Boolean']['input']>;
	hasTitles?: InputMaybe<Scalars['Boolean']['input']>;
	includeAddons?: InputMaybe<Scalars['Boolean']['input']>;
	iosPackages?: InputMaybe<Scalars['Boolean']['input']>;
	monetizationTypes?: InputMaybe<Array<MonetizationType>>;
	packageIds?: InputMaybe<Array<Scalars['Int']['input']>>;
	platform: Platform;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryPagesArgs = {
	country: Scalars['Country']['input'];
	language?: InputMaybe<Scalars['Language']['input']>;
	technicalNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryPopularPeopleArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<PersonFilter>;
	first?: Scalars['Int']['input'];
	sortBy?: PopularPeopleSorting;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryPopularTitlesArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	allowSponsoredRecommendations?: InputMaybe<SponsoredRecommendationsInput>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<TitleFilter>;
	first?: Scalars['Int']['input'];
	language?: InputMaybe<Scalars['Language']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	sortBy?: PopularTitlesSorting;
	sortRandomSeed?: Scalars['Int']['input'];
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryProfileArgs = {
	fullPath?: InputMaybe<Scalars['String']['input']>;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryRecommendedTitlesArgs = {
	affinityList: Array<RecommendationAffinity>;
	after?: InputMaybe<Scalars['String']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<TitleFilter>;
	first?: Scalars['Int']['input'];
};

/** The query root of JustWatch's GraphQL interface. */
export type QuerySportCompetitionsV2Args = {
	after?: InputMaybe<Scalars['String']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<SearchSportCompetitionFilter>;
	first?: Scalars['Int']['input'];
};

/** The query root of JustWatch's GraphQL interface. */
export type QuerySportEventBucketsV2Args = {
	after?: InputMaybe<Scalars['String']['input']>;
	bucketSize?: InputMaybe<Scalars['Int']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<SportEventFilter>;
	first?: Scalars['Int']['input'];
	groupBy?: SportEventAggregation;
	sortBy?: SportEventSorting;
	sortOrder?: SortOrder;
	timezoneOffset: Scalars['Int']['input'];
};

/** The query root of JustWatch's GraphQL interface. */
export type QuerySportEventMatrixArgs = {
	aggregation: SportEventMatrixAggregation;
	bucketSize?: Scalars['Int']['input'];
	country: Scalars['Country']['input'];
	filter?: InputMaybe<SportEventFilter>;
	first?: Scalars['Int']['input'];
	sport: SportType;
};

/** The query root of JustWatch's GraphQL interface. */
export type QuerySportEventsV2Args = {
	after?: InputMaybe<Scalars['String']['input']>;
	allowSponsoredRecommendations?: InputMaybe<SponsoredRecommendationsInput>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<SportEventFilter>;
	first?: Scalars['Int']['input'];
	sortOrder?: SortOrder;
	timezoneOffset: Scalars['Int']['input'];
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryStreamingChartsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country?: InputMaybe<Scalars['Country']['input']>;
	filter?: InputMaybe<StreamingChartsFilter>;
	first?: Scalars['Int']['input'];
};

/** The query root of JustWatch's GraphQL interface. */
export type QuerySubgenresArgs = {
	country?: InputMaybe<Scalars['Country']['input']>;
	objectType: Scalars['String']['input'];
	provider?: InputMaybe<Scalars['String']['input']>;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryTasteSurveyNextTitleArgs = {
	count: Scalars['Int']['input'];
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
	newLikedJWEntityIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryTextRecommendationsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<TextRecommendationFilter>;
	first?: Scalars['Int']['input'];
	jwEntityId: Scalars['String']['input'];
	language?: InputMaybe<Scalars['Language']['input']>;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryTitleListArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<TitleFilter>;
	first?: Scalars['Int']['input'];
	objectType: TitleListObjectType;
	sortBy?: TitleListSorting;
	sortRandomSeed?: Scalars['Int']['input'];
	subheading?: InputMaybe<TitleListSubheadingTechnicalName>;
	titleListType: TitleListType;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryTitleListV2Args = {
	after?: InputMaybe<Scalars['String']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<TitleFilter>;
	first?: Scalars['Int']['input'];
	language?: InputMaybe<Scalars['Language']['input']>;
	sortBy?: TitleListSortingV2;
	sortRandomSeed?: Scalars['Int']['input'];
	titleListType: TitleListTypeV2;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryTvShowTrackingArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<TitleFilter>;
	first?: Scalars['Int']['input'];
	language?: InputMaybe<Scalars['Language']['input']>;
	sortBy?: TvShowTrackingSorting;
	sortRandomSeed?: Scalars['Int']['input'];
	viewType: TvShowTrackingType;
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryUrlArgs = {
	fullPath: Scalars['String']['input'];
};

/** The query root of JustWatch's GraphQL interface. */
export type QueryUrlV2Args = {
	fullPath: Scalars['String']['input'];
};

/** An edge object for the recommended titles. */
export type RecommendationAffinity = {
	/** The affinity of the user with the title. e.g. 2.0 -> likes it, -2.0 -> dislikes it, 0.0 -> neutral */
	affinity: Scalars['Float']['input'];
	/** The id of a title. */
	id: Scalars['ID']['input'];
};

/** A connection object for the recommended titles. */
export type RecommendedTitlesConnection = {
	__typename?: 'RecommendedTitlesConnection';
	/** The edges for each of the items. */
	edges?: Maybe<Array<RecommendedTitlesEdge>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** The total number of items. */
	totalCount: Scalars['Int']['output'];
};

/** An edge object for the recommended titles. */
export type RecommendedTitlesEdge = {
	__typename?: 'RecommendedTitlesEdge';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The node represented by this edge. */
	node: MovieOrShow;
};

/** The payload response of an auth/identity action registration. */
export type RegisterPayload = {
	__typename?: 'RegisterPayload';
	/** The login id of the user. */
	loginId: Scalars['String']['output'];
};

/** The input when registering a new session start. */
export type RegisterSessionStartInput = {
	/** The user's platform. */
	platform: Platform;
};

/** The release type of a title. */
export enum ReleaseType {
	/** Released on streaming media. */
	Digital = 'DIGITAL',
	/** Released in cinema. */
	Theatrical = 'THEATRICAL',
}

/** The input needed to report the feedback. */
export type ReportFeedbackInput = {
	/** Any additional comment or description that can describe the issue breifly. */
	comment?: InputMaybe<Scalars['String']['input']>;
	/** The country of the user. */
	country: Scalars['Country']['input'];
	/** Email information of the user, if user wishes to receive the update on the reported issue. */
	email?: InputMaybe<Scalars['String']['input']>;
	/** Link to the external provider's page. */
	externalUrl?: InputMaybe<Scalars['String']['input']>;
	/** The node id. */
	id?: InputMaybe<Scalars['ID']['input']>;
	/** The user's preferred language in the platform. */
	language: Scalars['Language']['input'];
	/** The package that issue is related to. Short names are expected (e.g. 'nfx' for Netflix). */
	package?: InputMaybe<Scalars['String']['input']>;
	/** Platform information and its version. for e.g, 3.3.10-webapp */
	platformVersion: Scalars['String']['input'];
	/** The type of issue related to the content. */
	type: IssueReportType;
};

/** The payload response for the reported feedback. */
export type ReportFeedbackPayload = {
	__typename?: 'ReportFeedbackPayload';
	/** The result of the reported issue submission status. */
	isReported: Scalars['Boolean']['output'];
};

/** Defines the permissions of a user. */
export enum Role {
	/** A non logged in user. */
	Guest = 'GUEST',
	/** A logged in user. */
	LoggedIn = 'LOGGED_IN',
}

/** Score associated to each title */
export type Score = {
	__typename?: 'Score';
	providerType?: Maybe<Scalars['String']['output']>;
	value?: Maybe<Scalars['Float']['output']>;
};

/** A set of ratings/scorings associated to a title. */
export type Scoring = {
	__typename?: 'Scoring';
	/** If the title is certified fresh on Rotten Tomatoes */
	certifiedFresh?: Maybe<Scalars['Boolean']['output']>;
	/** The IMDB rating. */
	imdbScore?: Maybe<Scalars['Float']['output']>;
	/** The IMDB number of votes. */
	imdbVotes?: Maybe<Scalars['Float']['output']>;
	/** The official JustWatch rating! */
	jwRating?: Maybe<Scalars['Float']['output']>;
	/** The TMDB popularity. */
	tmdbPopularity?: Maybe<Scalars['Float']['output']>;
	/** The TMDB score. */
	tmdbScore?: Maybe<Scalars['Float']['output']>;
	/** The Rotten Tomatoes meter. */
	tomatoMeter?: Maybe<Scalars['Float']['output']>;
	/** The Rotten Tomatoes rating. */
	tomatoRating?: Maybe<Scalars['Float']['output']>;
	/** The Rotten Tomatoes score. */
	tomatoScore?: Maybe<Scalars['Float']['output']>;
	/** The virality score, between 0 and 1. */
	viral?: Maybe<Scalars['Float']['output']>;
};

/** Search Sport Competition filter. */
export type SearchSportCompetitionFilter = {
	/** Search sports filtered by time */
	activeOn?: InputMaybe<Scalars['Time']['input']>;
};

/** Personalized season aggregating user data and static Content data. */
export type Season = MovieOrSeason &
	MovieOrShowOrSeason &
	MovieOrShowOrSeasonOrEpisode &
	Node &
	Title & {
		__typename?: 'Season';
		/** The date where the title is leaving the users provider. */
		availableTo: Array<AvailableTo>;
		/** The bundles for this title. Calculated from the offers. Depends on the country being set. */
		bundles: Array<PromotedBundleEdge>;
		/** The title data. It is localized by country and language. */
		content: SeasonContent;
		/** The entry for the title in the dislikelist. */
		dislikelistEntry?: Maybe<TitleListEntry>;
		/** The episodes included in this season. */
		episodes: Array<Episode>;
		/**
		 * The global node ID of the title. For titles, it is defined by Content and made from object type + object ID.
		 *
		 * e.g. 'tm21' -> 'movie' + 21, 'tss11' -> 'show_season' + 11
		 */
		id: Scalars['ID']['output'];
		/** The entry for the title in the likelist. */
		likelistEntry?: Maybe<TitleListEntry>;
		/** The recent updated time on the packages for the title. Depends on the country being set.  */
		maxOfferUpdatedAt?: Maybe<Scalars['Time']['output']>;
		/** The Content ID of the title. */
		objectId: Scalars['Int']['output'];
		/** The Content type of the title. */
		objectType: ObjectType;
		/** The offer count available on packages for the title. Depends on the country being set.  */
		offerCount: Scalars['Int']['output'];
		/** The offers available on packages for the title. Depends on the country being set. */
		offers: Array<Offer>;
		/**
		 * The popularity rank and its trend popularity behaviour for the given title and its neighbouring titles.
		 * @deprecated Use the StreamingCharts :)
		 */
		popularityRank: PopularityRank;
		/** The bundles promoted for this title. Calculated from the offers. Depends on the country being set. */
		promotedBundles: Array<PromotedBundleEdge>;
		/** The offers promoted for this title. Depends on the country being set. */
		promotedOffers: Array<Offer>;
		/** The sum of how many times a text recommendation has been made for this entity */
		recommendedByCount: Scalars['Int']['output'];
		/**
		 * For seasons, knowing what is seen is a bit more complicated since the seen data is stored at the episode level.
		 * So we calculate it based on the episodes.
		 */
		seenState: ShowSeenState;
		/** The parent show of this season. */
		show: Show;
		/** The streaming chart rank and its trend popularity behaviour for the given title and its neighbouring titles. */
		streamingCharts: StreamingChartsConnection;
		/** The total number of existing episodes for this show. */
		totalEpisodeCount: Scalars['Int']['output'];
		/** Returns the Watch Next episode: the first unseen episode following the last seen episode (by timestamp). */
		watchNextEpisode?: Maybe<Episode>;
		/**
		 * The default offer that should be used to play the title. Can be null if there are no matching offers.
		 *
		 * A default monetization type filter is applied to only keep flatrate/free offers.
		 */
		watchNowOffer?: Maybe<Offer>;
		/** The sum of how many times this entity appears in seenlists */
		watchedByCount: Scalars['Int']['output'];
	};

/** Personalized season aggregating user data and static Content data. */
export type SeasonAvailableToArgs = {
	country: Scalars['Country']['input'];
	leavingSoonOnly?: Scalars['Boolean']['input'];
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	platform: Platform;
};

/** Personalized season aggregating user data and static Content data. */
export type SeasonBundlesArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized season aggregating user data and static Content data. */
export type SeasonContentArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
};

/** Personalized season aggregating user data and static Content data. */
export type SeasonEpisodesArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	releasedInCountry?: InputMaybe<Scalars['Country']['input']>;
	sortDirection?: SortDirection;
};

/** Personalized season aggregating user data and static Content data. */
export type SeasonMaxOfferUpdatedAtArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized season aggregating user data and static Content data. */
export type SeasonOfferCountArgs = {
	country: Scalars['Country']['input'];
	distinctPackages?: Scalars['Boolean']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized season aggregating user data and static Content data. */
export type SeasonOffersArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized season aggregating user data and static Content data. */
export type SeasonPopularityRankArgs = {
	category?: PopularityRankTypes;
	country: Scalars['Country']['input'];
	nextTitles?: Scalars['Int']['input'];
	previousTitles?: Scalars['Int']['input'];
};

/** Personalized season aggregating user data and static Content data. */
export type SeasonPromotedBundlesArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized season aggregating user data and static Content data. */
export type SeasonPromotedOffersArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized season aggregating user data and static Content data. */
export type SeasonSeenStateArgs = {
	country: Scalars['Country']['input'];
};

/** Personalized season aggregating user data and static Content data. */
export type SeasonStreamingChartsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country?: InputMaybe<Scalars['Country']['input']>;
	filter?: InputMaybe<StreamingChartsFilter>;
	first?: Scalars['Int']['input'];
};

/** Personalized season aggregating user data and static Content data. */
export type SeasonWatchNextEpisodeArgs = {
	country: Scalars['Country']['input'];
	includeUnreleasedEpisodes?: Scalars['Boolean']['input'];
};

/** Personalized season aggregating user data and static Content data. */
export type SeasonWatchNowOfferArgs = {
	country: Scalars['Country']['input'];
	filter?: WatchNowOfferFilter;
	platform: Platform;
};

/** Unpersonalized season data common to all the users, as defined by Content. It is localized by country and language. */
export type SeasonContent = MovieOrSeasonContent &
	MovieOrShowOrSeasonContent &
	MovieOrShowOrSeasonOrEpisodeContent & {
		__typename?: 'SeasonContent';
		/**
		 * The main article about this title
		 * @deprecated Use the articles field :)
		 */
		article?: Maybe<Article>;
		articles: Array<Article>;
		/**
		 * A list of backdrops. It takes a desired size (called profile) and desired format as parameter.
		 *
		 * e.g. 's1440' + 'webp' -> '/backdrop/8711516/s1440/Spider-Man-Homecoming.webp'
		 */
		backdrops: Array<Backdrop>;
		/** The trailers/clips attached to the title. */
		clips: Array<Clip>;
		/** All the IDs of the title on external platforms. */
		externalIds: ExternalIds;
		/**
		 * The localized website detail page URL of the title. Depends on the country being set.
		 *
		 * e.g. '/us/movie/batman-the-dark-knight'
		 */
		fullPath: Scalars['String']['output'];
		/** The related genre objects. */
		genres: Array<Genre>;
		/** The JW user interactions for a title. */
		interactions: InteractionAttributes;
		/**
		 * Whether we consider the title as released or not.
		 * @deprecated Use the V2 instead :)
		 */
		isReleased: Scalars['Boolean']['output'];
		/** Whether we consider the title as released or not. */
		isReleasedV2: Scalars['Boolean']['output'];
		/** The date when the title was first released in the country. */
		localizedReleaseDate?: Maybe<Scalars['Date']['output']>;
		/** The date when the title was originally first released. */
		originalReleaseDate?: Maybe<Scalars['Date']['output']>;
		/** The year when the title was originally first released. */
		originalReleaseYear?: Maybe<Scalars['Int']['output']>;
		/**
		 * The blur hash representation of the poster.
		 *
		 * Generated using https://github.com/woltapp/blurhash.
		 */
		posterBlurHash?: Maybe<Scalars['String']['output']>;
		/**
		 * The localized poster URL. Depends on the country and language being set.
		 * It takes a desired size (called profile) and desired format as parameter.
		 *
		 * e.g. 's166' + 'webp' -> '/poster/8711516/s166/memento.webp'
		 */
		posterUrl?: Maybe<Scalars['String']['output']>;
		/** The duration of the title. For shows and seasons, it's the average runtime of an episode. */
		runtime?: Maybe<Scalars['Int']['output']>;
		/** All the scorings/ratings of the title. */
		scoring: Scoring;
		/** The season number. */
		seasonNumber: Scalars['Int']['output'];
		/** A short description of the title. */
		shortDescription?: Maybe<Scalars['String']['output']>;
		/** The related subgenres. */
		subgenres: Array<GenericTitleList>;
		tags?: Maybe<Array<Tag>>;
		/** The text recommendations related to this entity. */
		textRecommendations?: Maybe<Array<TextRecommendation>>;
		/** The localized title of the title. Depends on the language being set. */
		title: Scalars['String']['output'];
		/** The title upcoming releases on streaming or cinema. Sorted by release date. */
		upcomingReleases: Array<UpcomingRelease>;
	};

/** Unpersonalized season data common to all the users, as defined by Content. It is localized by country and language. */
export type SeasonContentArticlesArgs = {
	articleTypes?: InputMaybe<Array<ArticleType>>;
};

/** Unpersonalized season data common to all the users, as defined by Content. It is localized by country and language. */
export type SeasonContentBackdropsArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<BackdropProfile>;
};

/** Unpersonalized season data common to all the users, as defined by Content. It is localized by country and language. */
export type SeasonContentClipsArgs = {
	providers?: InputMaybe<Array<ClipProvider>>;
};

/** Unpersonalized season data common to all the users, as defined by Content. It is localized by country and language. */
export type SeasonContentIsReleasedV2Args = {
	country: Scalars['Country']['input'];
};

/** Unpersonalized season data common to all the users, as defined by Content. It is localized by country and language. */
export type SeasonContentPosterUrlArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<PosterProfile>;
};

/** Unpersonalized season data common to all the users, as defined by Content. It is localized by country and language. */
export type SeasonContentTextRecommendationsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	filter?: InputMaybe<TextRecommendationFilter>;
	first?: Scalars['Int']['input'];
};

/** Unpersonalized season data common to all the users, as defined by Content. It is localized by country and language. */
export type SeasonContentUpcomingReleasesArgs = {
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	releaseTypes?: InputMaybe<Array<ReleaseType>>;
};

/** The input used to know which campaigns are currently active for the user. */
export type SendSraBidInput = {
	/** Extra data related to the placement, for contextual targeting. */
	placementContext: SraPlacementContext;
	/** The data necessary to bid for a Sponsored Recommendation Ad. */
	sraInput: SponsoredRecommendationsInput;
};

/** The payload response of the Sponsored Recommendation Ad bid. */
export type SendSraBidPayload = {
	__typename?: 'SendSraBidPayload';
	/** The SR ad to serve to the user. */
	ad?: Maybe<SponsoredRecommendationAd>;
};

/** The SessionEnvironmentContext to add to the SRA tracking event. */
export type SessionEnvironmentContextInput = {
	/** Environment of the event. */
	env?: InputMaybe<Scalars['String']['input']>;
	/** Has the user an adblocker. */
	hasAdblocker?: InputMaybe<Scalars['Boolean']['input']>;
	/** Origin of the event. */
	origin?: InputMaybe<Scalars['String']['input']>;
	/** Origin page referred. */
	originPageReferred?: InputMaybe<Scalars['String']['input']>;
	/** Origin page URL. */
	originPageURL?: InputMaybe<Scalars['String']['input']>;
	/** Origin referred medium. */
	originRefrMedium?: InputMaybe<Scalars['String']['input']>;
	/** Origin referred source. */
	originRefrSource?: InputMaybe<Scalars['String']['input']>;
};

export type SetBundleInput = {
	/** The bundle to add/remove from the settings. Short names are expected (e.g. 'npp' for Netflix+Prime). */
	bundle: Scalars['String']['input'];
	/** The country of the user. */
	country: Scalars['Country']['input'];
	/** The platform of the user. */
	platform: Platform;
	/** Whether we should add or remove the bundle. */
	state: Scalars['Boolean']['input'];
};

/** The title we want to add/remove in the custom list. Movies and shows can be manipulated. */
export type SetInCustomlistInput = {
	/** The node id. */
	id: Scalars['ID']['input'];
	/** The list id we want to add the node to. */
	listId: Scalars['ID']['input'];
	/** Whether we should add or remove a title from the list. */
	state: Scalars['Boolean']['input'];
};

/** The title list we want to add/remove to the followed lists. */
export type SetInFollowedListInput = {
	/** The node id. */
	id: Scalars['ID']['input'];
	/** An overwrite name for the followed list. */
	name: Scalars['String']['input'];
	/** Whether we should add or remove a list from the followed list. */
	state: Scalars['Boolean']['input'];
};

/** The payload when following/unfollowing a title list. */
export type SetInFollowedListPayload = {
	__typename?: 'SetInFollowedListPayload';
	/** The followed title list. */
	titleList: GenericTitleList;
};

/** The payload response for my sport list. */
export type SetInMysportlistPayload = {
	__typename?: 'SetInMysportlistPayload';
	/** The title that we just added/removed in the list. */
	title: SportTitle;
};

/** The titles we want to add/remove in the seenlist. Movies, shows, seasons and episodes can be manipulated. */
export type SetInSeenlistBatchInput = {
	/** The user's country. */
	country: Scalars['Country']['input'];
	/** The batch items. */
	items: Array<SetInSeenlistBatchItem>;
};

/** A title we want to add/remove in the seenlist in batch. Movies, shows, seasons and episodes can be manipulated. */
export type SetInSeenlistBatchItem = {
	/** The node id. */
	id: Scalars['ID']['input'];
	/** Whether we should add or remove a title from the list. */
	state: Scalars['Boolean']['input'];
};

/** The payload response of a seenlist batch addition/removal. */
export type SetInSeenlistBatchPayload = {
	__typename?: 'SetInSeenlistBatchPayload';
	/** The titles that we just added/removed in the list. */
	titles: Array<MovieOrShowOrSeasonOrEpisode>;
};

/** The title we want to add/remove in the seenlist. Movies, shows, seasons and episodes can be manipulated. */
export type SetInSeenlistInput = {
	/** The user's country. */
	country: Scalars['Country']['input'];
	/** The node id. */
	id: Scalars['ID']['input'];
	/** Whether we should add or remove a title from the list. */
	state: Scalars['Boolean']['input'];
};

/** The payload response of a seenlist addition/removal. */
export type SetInSeenlistPayload = {
	__typename?: 'SetInSeenlistPayload';
	/** The title that we just added/removed in the list. */
	title: MovieOrShowOrSeasonOrEpisode;
};

/** The title we want to add/remove in a standard title list. Only movies or shows can be manipulated. */
export type SetInTitleListInput = {
	/** The node id. */
	id: Scalars['ID']['input'];
	/** Whether we should add or remove a title from the list. */
	state: Scalars['Boolean']['input'];
};

/** The payload response of a title list addition/removal. */
export type SetInTitleListPayload = {
	__typename?: 'SetInTitleListPayload';
	/** The title that we just added/removed in the list. */
	title: MovieOrShow;
};

/** The payload response of a title list addition/removal, with season support. */
export type SetInTitleListSeasonPayload = {
	__typename?: 'SetInTitleListSeasonPayload';
	/** The title that we just added/removed in the list. */
	title: MovieOrShowOrSeason;
};

export type SetPackageInput = {
	/** In case of a package deselection that was part of a bundle, indicates if we should also deselect the parent bundle. */
	cascadeDeselectBundles?: InputMaybe<Scalars['Boolean']['input']>;
	/** The country of the user. */
	country: Scalars['Country']['input'];
	/** The package to add/remove from the settings. Short names are expected (e.g. 'nfx' for Netflix). */
	package?: InputMaybe<Scalars['String']['input']>;
	/** When batching, the packages to add/remove from the settings. Short names are expected (e.g. 'nfx' for Netflix). */
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	/** The platform of the user. */
	platform: Platform;
	/** Whether we should add or remove the package. */
	state: Scalars['Boolean']['input'];
};

/** The user settings. */
export type Settings = {
	__typename?: 'Settings';
	/** Used to store the timeline specials hidden by the user. */
	hiddenTimelineSpecials: Array<Scalars['String']['output']>;
	/** Stores the tracking consent data. */
	jwConsents?: Maybe<JwConsents>;
	/** The bundles selected by the user. */
	selectedBundles: Array<Bundle>;
	/** The packages selected by the user. Also includes the packages from the selected bundles. */
	selectedPackages: Array<Package>;
	/** Indicates if the user has been onboarded before going to the Home. */
	tasteSurveyCompleted: Scalars['Boolean']['output'];
	/** Indicates the Gefjon model that was used to recommend the onboarding titles. */
	tasteSurveyModelVersion?: Maybe<Scalars['String']['output']>;
	/** Indicates the type of onboarding the user took before going to the Home. */
	tasteSurveyType?: Maybe<TasteSurveyType>;
	/** Stores the UserCentrics consent data. */
	ucConsents: Array<UcConsent>;
};

/** The user settings. */
export type SettingsSelectedBundlesArgs = {
	country: Scalars['Country']['input'];
	platform: Platform;
};

/** The user settings. */
export type SettingsSelectedPackagesArgs = {
	country: Scalars['Country']['input'];
	hasSport?: InputMaybe<Scalars['Boolean']['input']>;
	hasTitles?: InputMaybe<Scalars['Boolean']['input']>;
	iosPackages?: InputMaybe<Scalars['Boolean']['input']>;
	platform: Platform;
};

/** Personalized show aggregating user data and static Content data. */
export type Show = MovieOrShow &
	MovieOrShowOrSeason &
	MovieOrShowOrSeasonOrEpisode &
	Node &
	Title & {
		__typename?: 'Show';
		/** The date where the title is leaving the users provider. */
		availableTo: Array<AvailableTo>;
		/** The bundles for this title. Calculated from the offers. Depends on the country being set. */
		bundles: Array<PromotedBundleEdge>;
		/** contains information about various clips like trailers */
		clips?: Maybe<Array<Clip>>;
		/** The title data. It is localized by country and language. */
		content: ShowContent;
		/** The entries in the user's customlists. */
		customlistEntries: Array<TitleListEntry>;
		/** The entry for the movie in the dislikelist. */
		dislikelistEntry?: Maybe<TitleListEntry>;
		/** All the episodes included in this show. */
		episodes: Array<Episode>;
		/** The global node ID. */
		id: Scalars['ID']['output'];
		/** The entry for the movie in the likelist. */
		likelistEntry?: Maybe<TitleListEntry>;
		/** The recent updated time on the packages for the title. Depends on the country being set.  */
		maxOfferUpdatedAt?: Maybe<Scalars['Time']['output']>;
		/** The Content ID of the title. */
		objectId: Scalars['Int']['output'];
		/** The Content type of the title. */
		objectType: ObjectType;
		/** The offer count available on packages for the title. Depends on the country being set.  */
		offerCount: Scalars['Int']['output'];
		/** The offers available on packages for the title. Depends on the country being set. */
		offers: Array<Offer>;
		/** A list of parent permanent audience IDs the title is part of. */
		parentPermanentAudiences: Array<Scalars['String']['output']>;
		/** A list of permanent audience IDs the title is part of. */
		permanentAudiences: Array<Scalars['String']['output']>;
		/**
		 * The popularity rank and its trend popularity behaviour for the given title and its neighbouring titles.
		 * @deprecated Use the StreamingCharts :)
		 */
		popularityRank: PopularityRank;
		/** The bundles promoted for this title. Calculated from the offers. Depends on the country being set. */
		promotedBundles: Array<PromotedBundleEdge>;
		/** The offers promoted for this title. Depends on the country being set. */
		promotedOffers: Array<Offer>;
		/** The sum of how many times a text recommendation has been made for this entity */
		recommendedByCount: Scalars['Int']['output'];
		/** The seasons included in this show. */
		seasons: Array<Season>;
		/**
		 * For shows, knowing what is seen is a bit more complicated since the seen data is stored at the episode level.
		 * So we calculate it based on the episodes.
		 */
		seenState: ShowSeenState;
		/**
		 * A list of similar titles. Not specific to the user, returning the same for all users.
		 * @deprecated Use the V2 :)
		 */
		similarTitles: Array<MovieOrShow>;
		/** A list of similar titles. Not specific to the user, returning the same for all users. */
		similarTitlesV2: SimilarTitlesConnection;
		/** The streaming chart rank and its trend popularity behaviour for the given title and its neighbouring titles. */
		streamingCharts: StreamingChartsConnection;
		/** The total number of existing seasons for this show. */
		totalSeasonCount: Scalars['Int']['output'];
		/** For shows, we can access the TV show tracking title list entry. */
		tvShowTrackingEntry?: Maybe<TitleListEntry>;
		/** Returns the Watch Next episode: the first unseen episode following the last seen episode (by timestamp). */
		watchNextEpisode?: Maybe<Episode>;
		/**
		 * The default offer that should be used to play the title. Can be null if there are no matching offers.
		 *
		 * A default monetization type filter is applied to only keep flatrate/free offers.
		 */
		watchNowOffer?: Maybe<Offer>;
		/** The sum of how many times this entity appears in seenlists */
		watchedByCount: Scalars['Int']['output'];
		/**
		 * The entry for the movie in the watchlist.
		 * @deprecated Please use the 'watchlistEntryV2' field
		 */
		watchlistEntry?: Maybe<TitleListEntry>;
		watchlistEntryV2?: Maybe<TitleListEntry>;
	};

/** Personalized show aggregating user data and static Content data. */
export type ShowAvailableToArgs = {
	country: Scalars['Country']['input'];
	leavingSoonOnly?: Scalars['Boolean']['input'];
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	platform: Platform;
};

/** Personalized show aggregating user data and static Content data. */
export type ShowBundlesArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized show aggregating user data and static Content data. */
export type ShowContentArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
};

/** Personalized show aggregating user data and static Content data. */
export type ShowEpisodesArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
	offset?: InputMaybe<Scalars['Int']['input']>;
	releasedInCountry?: InputMaybe<Scalars['Country']['input']>;
	sortDirection?: SortDirection;
};

/** Personalized show aggregating user data and static Content data. */
export type ShowMaxOfferUpdatedAtArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized show aggregating user data and static Content data. */
export type ShowOfferCountArgs = {
	country: Scalars['Country']['input'];
	distinctPackages?: Scalars['Boolean']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized show aggregating user data and static Content data. */
export type ShowOffersArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized show aggregating user data and static Content data. */
export type ShowPopularityRankArgs = {
	category?: PopularityRankTypes;
	country: Scalars['Country']['input'];
	nextTitles?: Scalars['Int']['input'];
	previousTitles?: Scalars['Int']['input'];
};

/** Personalized show aggregating user data and static Content data. */
export type ShowPromotedBundlesArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized show aggregating user data and static Content data. */
export type ShowPromotedOffersArgs = {
	country: Scalars['Country']['input'];
	filter?: OfferFilter;
	platform: Platform;
};

/** Personalized show aggregating user data and static Content data. */
export type ShowSeasonsArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>;
	releasedInCountry?: InputMaybe<Scalars['Country']['input']>;
	sortDirection?: SortDirection;
};

/** Personalized show aggregating user data and static Content data. */
export type ShowSeenStateArgs = {
	country: Scalars['Country']['input'];
};

/** Personalized show aggregating user data and static Content data. */
export type ShowSimilarTitlesArgs = {
	country: Scalars['Country']['input'];
	filter?: InputMaybe<TitleFilter>;
	limit?: Scalars['Int']['input'];
};

/** Personalized show aggregating user data and static Content data. */
export type ShowSimilarTitlesV2Args = {
	allowSponsoredRecommendations?: InputMaybe<SponsoredRecommendationsInput>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<TitleFilter>;
	first?: Scalars['Int']['input'];
};

/** Personalized show aggregating user data and static Content data. */
export type ShowStreamingChartsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	country?: InputMaybe<Scalars['Country']['input']>;
	filter?: InputMaybe<StreamingChartsFilter>;
	first?: Scalars['Int']['input'];
};

/** Personalized show aggregating user data and static Content data. */
export type ShowWatchNextEpisodeArgs = {
	country: Scalars['Country']['input'];
	includeUnreleasedEpisodes?: Scalars['Boolean']['input'];
};

/** Personalized show aggregating user data and static Content data. */
export type ShowWatchNowOfferArgs = {
	country: Scalars['Country']['input'];
	filter?: WatchNowOfferFilter;
	platform: Platform;
};

/** Unpersonalized show data common to all the users, as defined by Content. It is localized by country and language. */
export type ShowContent = MovieOrShowContent &
	MovieOrShowOrSeasonContent &
	MovieOrShowOrSeasonOrEpisodeContent & {
		__typename?: 'ShowContent';
		/** The age rating. e.g. PG-13 for movies, TV-PG for shows in the US */
		ageCertification?: Maybe<Scalars['String']['output']>;
		/**
		 * The main article about this title
		 * @deprecated Use the articles field :)
		 */
		article?: Maybe<Article>;
		articles: Array<Article>;
		/**
		 * A list of backdrops. It takes a desired size (called profile) and desired format as parameter.
		 *
		 * e.g. 's1440' + 'webp' -> '/backdrop/8711516/s1440/Spider-Man-Homecoming.webp'
		 */
		backdrops: Array<Backdrop>;
		/** The trailers/clips attached to the title. */
		clips: Array<Clip>;
		/** The credits of the title. The 'personId' filter can be used to check if a certain person was involved in the title. */
		credits: Array<Credit>;
		/** All the IDs of the title on external platforms. */
		externalIds: ExternalIds;
		/**
		 * The localized website detail page URL of the title. Depends on the country being set.
		 *
		 * e.g. '/us/movie/batman-the-dark-knight'
		 */
		fullPath: Scalars['String']['output'];
		/** The related genre objects. */
		genres: Array<Genre>;
		/** The JW user interactions for a title. */
		interactions: InteractionAttributes;
		/**
		 * Whether we consider the title as released or not.
		 * @deprecated Use the V2 instead :)
		 */
		isReleased: Scalars['Boolean']['output'];
		/** Whether we consider the title as released or not. */
		isReleasedV2: Scalars['Boolean']['output'];
		/** The date when the title was first released in the country. */
		localizedReleaseDate?: Maybe<Scalars['Date']['output']>;
		/** The date when the title was originally first released. */
		originalReleaseDate?: Maybe<Scalars['Date']['output']>;
		/** The year when the title was originally first released. */
		originalReleaseYear?: Maybe<Scalars['Int']['output']>;
		/** The original title of the title. */
		originalTitle: Scalars['String']['output'];
		/**
		 * The blur hash representation of the poster.
		 *
		 * Generated using https://github.com/woltapp/blurhash.
		 */
		posterBlurHash?: Maybe<Scalars['String']['output']>;
		/**
		 * The localized poster URL. Depends on the country and language being set.
		 * It takes a desired size (called profile) and desired format as parameter.
		 *
		 * e.g. 's166' + 'webp' -> '/poster/8711516/s166/memento.webp'
		 */
		posterUrl?: Maybe<Scalars['String']['output']>;
		/** The countries where the title was produced. */
		productionCountries: Array<Scalars['Country']['output']>;
		/** The duration of the title. For shows and seasons, it's the average runtime of an episode. */
		runtime?: Maybe<Scalars['Int']['output']>;
		/** All the scorings/ratings of the title. */
		scoring: Scoring;
		/** A short description of the title. */
		shortDescription?: Maybe<Scalars['String']['output']>;
		/** The related subgenres. */
		subgenres: Array<GenericTitleList>;
		tags?: Maybe<Array<Tag>>;
		/** The text recommendations related to this entity. */
		textRecommendations?: Maybe<Array<TextRecommendation>>;
		/** The localized title of the title. Depends on the language being set. */
		title: Scalars['String']['output'];
		/** The title upcoming releases on streaming or cinema. Sorted by release date. */
		upcomingReleases: Array<UpcomingRelease>;
	};

/** Unpersonalized show data common to all the users, as defined by Content. It is localized by country and language. */
export type ShowContentArticlesArgs = {
	articleTypes?: InputMaybe<Array<ArticleType>>;
};

/** Unpersonalized show data common to all the users, as defined by Content. It is localized by country and language. */
export type ShowContentBackdropsArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<BackdropProfile>;
};

/** Unpersonalized show data common to all the users, as defined by Content. It is localized by country and language. */
export type ShowContentClipsArgs = {
	providers?: InputMaybe<Array<ClipProvider>>;
};

/** Unpersonalized show data common to all the users, as defined by Content. It is localized by country and language. */
export type ShowContentCreditsArgs = {
	first?: InputMaybe<Scalars['Int']['input']>;
	personId?: InputMaybe<Scalars['Int']['input']>;
	role?: InputMaybe<CreditRole>;
};

/** Unpersonalized show data common to all the users, as defined by Content. It is localized by country and language. */
export type ShowContentIsReleasedV2Args = {
	country: Scalars['Country']['input'];
};

/** Unpersonalized show data common to all the users, as defined by Content. It is localized by country and language. */
export type ShowContentPosterUrlArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<PosterProfile>;
};

/** Unpersonalized show data common to all the users, as defined by Content. It is localized by country and language. */
export type ShowContentTextRecommendationsArgs = {
	after?: InputMaybe<Scalars['String']['input']>;
	filter?: InputMaybe<TextRecommendationFilter>;
	first?: Scalars['Int']['input'];
};

/** Unpersonalized show data common to all the users, as defined by Content. It is localized by country and language. */
export type ShowContentUpcomingReleasesArgs = {
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	releaseTypes?: InputMaybe<Array<ReleaseType>>;
};

/**
 * An aggregation of the seen state of a show/season.
 *
 * It is built out of seen episodes.
 */
export type ShowSeenState = {
	__typename?: 'ShowSeenState';
	/** True when the last released episode is seen. */
	caughtUp: Scalars['Boolean']['output'];
	/** The time of the last episode entry creation. */
	lastCreatedAt?: Maybe<Scalars['Time']['output']>;
	/** The episode number of the last episode entry creation. */
	lastSeenEpisodeNumber?: Maybe<Scalars['Int']['output']>;
	/** The season number of the last episode entry creation. */
	lastSeenSeasonNumber?: Maybe<Scalars['Int']['output']>;
	/** A progress bar between 0 and 100. */
	progress: Scalars['Int']['output'];
	/** A count of all released episodes. */
	releasedEpisodeCount: Scalars['Int']['output'];
	/** A count of all the seen episodes. */
	seenEpisodeCount: Scalars['Int']['output'];
};

/** A connection object for similar titles. */
export type SimilarTitlesConnection = {
	__typename?: 'SimilarTitlesConnection';
	/**
	 * Indicates if a sponsored recommendation is currently active for the user.
	 * @deprecated Use 'sponsoredAd' instead.
	 */
	activeCampaign?: Maybe<SponsoredRecommendationCampaign>;
	/** The edges for each of the items. */
	edges?: Maybe<Array<SimilarTitlesEdge>>;
	/** Indicates if there's a Sponsored Recommendation Ad to display. */
	sponsoredAd?: Maybe<SponsoredRecommendationAd>;
};

/** An edge object for similar titles. */
export type SimilarTitlesEdge = {
	__typename?: 'SimilarTitlesEdge';
	/** The node represented by this edge. */
	node: MovieOrShow;
};

/** One result/competitor for single step sports (tennis, football, handball, basketball...). */
export type SingleStepSportCompetitorV2 = {
	__typename?: 'SingleStepSportCompetitorV2';
	/** The competitor for a team sport. */
	competitors: Array<SportCompetitorV2>;
	/** The score that the team has. */
	score?: Maybe<Scalars['Int']['output']>;
	/** If the team won. */
	winner: Scalars['Boolean']['output'];
};

/** The result/competitors for single step sports (tennis, football, handball, basketball...). */
export type SingleStepSportCompetitorsV2 = {
	__typename?: 'SingleStepSportCompetitorsV2';
	/** The away team. */
	awayTeam: SingleStepSportCompetitorV2;
	/** The home team. */
	homeTeam: SingleStepSportCompetitorV2;
};

/** A single step sport match. */
export type SingleStepSportEvent = Node &
	SportEventV2 &
	SportTitle & {
		__typename?: 'SingleStepSportEvent';
		/** The competition the event belongs to. */
		competition?: Maybe<SportCompetitionV2>;
		competitors: SingleStepSportCompetitorsV2;
		/** The sport event data. It is localized by language. */
		content: SingleStepSportEventContent;
		/** The global node ID. */
		id: Scalars['ID']['output'];
		/** The entry for the sport event in the my sport list. */
		mysportlistEntry?: Maybe<TitleListEntry>;
		/** The Content ID of the title. */
		objectId: Scalars['Int']['output'];
		/** The Content type of the title. */
		objectType: ObjectType;
		/** The offer count available on packages for the event. Depends on the country being set.  */
		offerCount: Scalars['Int']['output'];
		/** The offers for the sport event. */
		offers: Array<SportOffer>;
		/** The sport of the event. */
		sport: SportType;
		/** The default offer that should be used to play the event. Can be null if there are no matching offers. */
		watchNowOffer?: Maybe<SportOffer>;
	};

/** A single step sport match. */
export type SingleStepSportEventContentArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
};

/** A single step sport match. */
export type SingleStepSportEventOfferCountArgs = {
	country: Scalars['Country']['input'];
	filter?: SportOfferFilter;
	platform: Platform;
};

/** A single step sport match. */
export type SingleStepSportEventOffersArgs = {
	country: Scalars['Country']['input'];
	filter?: SportOfferFilter;
	platform: Platform;
};

/** A single step sport match. */
export type SingleStepSportEventWatchNowOfferArgs = {
	country: Scalars['Country']['input'];
	filter?: WatchNowSportOfferFilter;
	platform: Platform;
};

/** A single step sport match information. */
export type SingleStepSportEventContent = SportEventContentV2 & {
	__typename?: 'SingleStepSportEventContent';
	/**
	 * A list of backdrops. It takes a desired size (called profile) and desired format as parameter.
	 *
	 * e.g. 's1440' + 'webp' -> '/backdrop/8711516/s1440/Spider-Man-Homecoming.webp'
	 */
	backdrops: Array<Backdrop>;
	/** The stage of the competition the event is in. */
	competitionStage: SportCompetitionStageV2;
	/** When the event ends */
	endAt?: Maybe<Scalars['Time']['output']>;
	/**
	 * The localized website detail page URL of the sport event. Depends on the country being set.
	 *
	 * e.g. '/de/fussball/La-Liga/FC-Barcelona-Real-Madrid-2021-2022'
	 */
	fullPath?: Maybe<Scalars['String']['output']>;
	/** A localized name for the match */
	name: Scalars['String']['output'];
	/** The competition season during which the event took place */
	season?: Maybe<SportSeason>;
	/** When the event starts */
	startAt: Scalars['Time']['output'];
	/** General status of the event */
	status: SportEventStatus;
	/** The venue where the event was held */
	venue?: Maybe<SportVenue>;
};

/** A single step sport match information. */
export type SingleStepSportEventContentBackdropsArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<BackdropProfile>;
};

/** In which direction we should sort a list. */
export enum SortDirection {
	/** Ascending */
	Asc = 'ASC',
	/** Descending */
	Desc = 'DESC',
}

/** Defines the sorting order */
export enum SortOrder {
	/** Ascending order */
	Asc = 'ASC',
	/** Descending Order */
	Desc = 'DESC',
}

/** A sponsored module is a module linked to an active sponsored recommendation campaign. */
export type SponsoredModule = {
	__typename?: 'SponsoredModule';
	/**
	 * Indicates the sponsored recommendation currently active for the user.
	 * @deprecated Use 'sponsoredAd' instead.
	 */
	activeCampaign: SponsoredRecommendationCampaign;
	/** Indicates the module that should be displayed in the sponsored slot. */
	module: Module;
	/** Indicates the ad to display in the sponsored slot. */
	sponsoredAd?: Maybe<SponsoredRecommendationAd>;
};

/** Represents a sponsored recommendation ad running on JustWatch. */
export type SponsoredRecommendationAd = {
	__typename?: 'SponsoredRecommendationAd';
	/** The unique ID of the ad. This is the ID that should be used to track SRA events. */
	bidId: Scalars['String']['output'];
	/** The parent campaign of the ad. */
	campaign?: Maybe<SponsoredRecommendationCampaign>;
	/** Indicates if the user is part of the holdout group. In that case, we don't show the ad to the user but we keep tracking SRA events. */
	holdoutGroup: Scalars['Boolean']['output'];
};

/** Represents a sponsored recommendation campaign running on JustWatch. */
export type SponsoredRecommendationCampaign = {
	__typename?: 'SponsoredRecommendationCampaign';
	/** The background images for the campaign. */
	backgroundImages?: Maybe<Array<Maybe<BackgroundImage>>>;
	/** The countdown timer for the campaign. */
	countdownTimer?: Maybe<Scalars['Time']['output']>;
	/** The creative type of the campaign. */
	creativeType?: Maybe<CreativeType>;
	/** The disclaimer text. */
	disclaimerText?: Maybe<Scalars['String']['output']>;
	/**
	 * A list of external impression tracker URLs.
	 * @deprecated Use the 'externalTrackers' field instead.
	 */
	externalImpressionTrackers: Array<Scalars['String']['output']>;
	/** A list of external trackers. */
	externalTrackers: Array<ExternalTracker>;
	/** If some of the content should be hidden by front-end, per campaign. */
	hideContent?: Maybe<Scalars['Boolean']['output']>;
	/** Whether the button leading to the detail page should be hidden from the user. */
	hideDetailPageButton: Scalars['Boolean']['output'];
	/** If the imdb score should be hidden. */
	hideImdbScore?: Maybe<Scalars['Boolean']['output']>;
	/** If the jw score should be hidden. */
	hideJwScore?: Maybe<Scalars['Boolean']['output']>;
	/** Whether the JW/IMDB ratings should be hidden from the user. */
	hideRatings: Scalars['Boolean']['output'];
	/** The name of the campaign. */
	name: Scalars['String']['output'];
	/** The node we're promoting. */
	node: Node;
	/** A list of alternative data per node ID. */
	nodeOverrides: Array<NodeOverride>;
	/** The poster override. */
	posterOverride?: Maybe<Scalars['String']['output']>;
	/** The image override, hardcoded for the campaign. It's an absolute URL. */
	promotionalImageUrl?: Maybe<Scalars['String']['output']>;
	/**
	 * A list of alternative poster/image per node ID.
	 * @deprecated Use the 'nodeOverrides' field instead.
	 */
	promotionalImageUrlOverrides: Array<PromotionalImageUrlOverride>;
	/** An override for the provider logo of the campaign. It's an absolute URL. */
	promotionalProviderLogo?: Maybe<Scalars['String']['output']>;
	/** The promotional provider wide logo override. */
	promotionalProviderWideLogo?: Maybe<Scalars['String']['output']>;
	/** An extra piece of text displayed on the ad (optional). */
	promotionalText?: Maybe<Scalars['String']['output']>;
	/** An override for the title of the movie/show of the ad (optional). */
	promotionalTitle?: Maybe<Scalars['String']['output']>;
	/** An optional promotional video for the campaign. */
	promotionalVideo?: Maybe<PromotionalVideo>;
	/** The watch now label override, hardcoded for the campaign. */
	watchNowLabel?: Maybe<Scalars['String']['output']>;
	/** The watch now offer override, hardcoded for the campaign. */
	watchNowOffer: Offer;
	/**
	 * A list of alternative offers per node ID.
	 * @deprecated Use the 'nodeOverrides' field instead.
	 */
	watchNowOfferOverrides: Array<WatchNowOfferOverride>;
};

/** Represents a sponsored recommendation campaign running on JustWatch. */
export type SponsoredRecommendationCampaignExternalTrackersArgs = {
	types?: InputMaybe<Array<ExternalTrackerType>>;
};

/** The input used to know which campaigns are currently active for the user. */
export type SponsoredRecommendationsInput = {
	/** Always return a bidID even when there is no sponsoredAd, for use with shadow impressions. */
	alwaysReturnBidID?: Scalars['Boolean']['input'];
	/** The app id of the frontend. e.g. 3.6.1-webapp */
	appId?: InputMaybe<Scalars['String']['input']>;
	/** The application context, containing information about app, version, build etc */
	applicationContext?: InputMaybe<ApplicationContextInput>;
	/** The app country of the user. */
	country: Scalars['Country']['input'];
	/** The geographical country of the user. */
	geoCountry?: InputMaybe<Scalars['Country']['input']>;
	/** The language of the user. */
	language?: InputMaybe<Scalars['Language']['input']>;
	/** The page type on which the user is. This is the technical name of the contentdb page, e.g. 'VIEW_POPULAR'. */
	pageType?: InputMaybe<Scalars['String']['input']>;
	/**
	 * The placement of the ad. e.g. HOME
	 * In general, the placement will have a default value set based on the query being used.
	 * However, if you want to override the default placement, you can.
	 */
	placement?: InputMaybe<SraPlacement>;
	/** The platform of the user. */
	platform: Platform;
	/** The session environment context, containing information about the session environment of the user. */
	sessionEnvironmentContext?: InputMaybe<SessionEnvironmentContextInput>;
	/**
	 * The creative format supported by the front-end.
	 * Defaults to IMAGE if not set.
	 */
	supportedFormats?: InputMaybe<Array<SraFormat>>;
	/**
	 * The object types supported by the front-end.
	 * Defaults to SHOW and MOVIE if not set.
	 */
	supportedObjectTypes?: InputMaybe<Array<ObjectType>>;
	/** Disables the contextual/user targeting. As long as there's a campaign active for the country/platform/placement, it will be returned. */
	testingMode?: Scalars['Boolean']['input'];
	/** The name of the campaign to force in testing mode. */
	testingModeCampaignName?: InputMaybe<Scalars['String']['input']>;
	/** Force a holdout group in testing mode. */
	testingModeForceHoldoutGroup?: Scalars['Boolean']['input'];
	/** The ID of a specific subcampaign to force in testing mode. */
	testingModeSubcampaignId?: InputMaybe<Scalars['String']['input']>;
};

/** A sport. */
export type Sport = {
	__typename?: 'Sport';
	/** The type of the sport. */
	type: SportType;
};

/** A sport competition content, common to all the sports. */
export type SportCompetitionContentV2 = {
	__typename?: 'SportCompetitionContentV2';
	/**
	 * A list of backdrops. It takes a desired size (called profile) and desired format as parameter.
	 *
	 * e.g. 's1440' + 'webp' -> '/backdrop/8711516/s1440/Spider-Man-Homecoming.webp'
	 */
	backdrops: Array<Backdrop>;
	/**
	 * The localized website detail page URL of the sport competition. Depends on the country being set.
	 *
	 * e.g. '/de/fussball/Bundesliga'
	 */
	fullPath: Scalars['String']['output'];
	/**
	 * The blur hash representation of the logo.
	 *
	 * Generated using https://github.com/woltapp/blurhash.
	 */
	iconBlurHash?: Maybe<Scalars['String']['output']>;
	/**
	 * The localized logo URL. Depends on the country and language being set.
	 * It takes a desired size (called profile) and desired format as parameter.
	 *
	 * e.g. 's166' + 'webp' -> '/logo/8711516/s166/memento.webp'
	 */
	iconUrl?: Maybe<Scalars['String']['output']>;
	/** The localized name of the competition */
	name: Scalars['String']['output'];
};

/** A sport competition content, common to all the sports. */
export type SportCompetitionContentV2BackdropsArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<BackdropProfile>;
};

/** A sport competition content, common to all the sports. */
export type SportCompetitionContentV2IconUrlArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<IconProfile>;
};

export enum SportCompetitionStageName {
	/** American football */
	AmericanFootballConferenceChampionship = 'AMERICAN_FOOTBALL_CONFERENCE_CHAMPIONSHIP',
	AmericanFootballConferenceDivisionalRound = 'AMERICAN_FOOTBALL_CONFERENCE_DIVISIONAL_ROUND',
	AmericanFootballConferenceWildCardRound = 'AMERICAN_FOOTBALL_CONFERENCE_WILD_CARD_ROUND',
	/** Tennis and Football */
	Final = 'FINAL',
	MatchDay = 'MATCH_DAY',
	NationalFootballConferenceChampionship = 'NATIONAL_FOOTBALL_CONFERENCE_CHAMPIONSHIP',
	NationalFootballConferenceDivisionalRound = 'NATIONAL_FOOTBALL_CONFERENCE_DIVISIONAL_ROUND',
	NationalFootballConferenceWildCardRound = 'NATIONAL_FOOTBALL_CONFERENCE_WILD_CARD_ROUND',
	PlayoffRound = 'PLAYOFF_ROUND',
	Practice = 'PRACTICE',
	Qualification = 'QUALIFICATION',
	QualificationFinal = 'QUALIFICATION_FINAL',
	QualificationRound_1 = 'QUALIFICATION_ROUND_1',
	QualificationRound_2 = 'QUALIFICATION_ROUND_2',
	Qualifying = 'QUALIFYING',
	Quarterfinal = 'QUARTERFINAL',
	/** Formula racing */
	Race = 'RACE',
	RoundOf_8 = 'ROUND_OF_8',
	RoundOf_16 = 'ROUND_OF_16',
	RoundOf_32 = 'ROUND_OF_32',
	RoundOf_64 = 'ROUND_OF_64',
	RoundOf_128 = 'ROUND_OF_128',
	Semifinal = 'SEMIFINAL',
	Sprint = 'SPRINT',
	SuperBowl = 'SUPER_BOWL',
	/** All sports */
	Undefined = 'UNDEFINED',
}

/** The stage of a sport competition. */
export type SportCompetitionStageV2 = {
	__typename?: 'SportCompetitionStageV2';
	/** The name of the stage. */
	name: SportCompetitionStageName;
	/** The number of the stage. */
	number?: Maybe<Scalars['Int']['output']>;
};

/** A sport competition, common to all the sports. */
export type SportCompetitionV2 = Node &
	SportTitle & {
		__typename?: 'SportCompetitionV2';
		/** The current or upcoming season of the competition. */
		activeSeason?: Maybe<SportSeason>;
		/** The sport competition data. It is localized by language. */
		content: SportCompetitionContentV2;
		/** The global node ID. */
		id: Scalars['ID']['output'];
		/** The Content ID of the title. */
		objectId: Scalars['Int']['output'];
		/** The Content type of the title. */
		objectType: ObjectType;
		/** The sport of the competition. */
		sport: SportType;
		/** Runs a sport event search and returns an aggregated list of sport events for the given competition. */
		sportEventBucketsV2: SportEventBucketsConnectionV2;
		/** Runs a sport event search for the given competition. */
		sportEventsV2: SportEventsConnectionV2;
	};

/** A sport competition, common to all the sports. */
export type SportCompetitionV2ActiveSeasonArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
};

/** A sport competition, common to all the sports. */
export type SportCompetitionV2ContentArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
};

/** A sport competition, common to all the sports. */
export type SportCompetitionV2SportEventBucketsV2Args = {
	after?: InputMaybe<Scalars['String']['input']>;
	bucketSize?: InputMaybe<Scalars['Int']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<SportEventFilter>;
	first?: Scalars['Int']['input'];
	groupBy: SportEventAggregation;
	sortBy?: SportEventSorting;
	sortOrder?: SortOrder;
	timezoneOffset: Scalars['Int']['input'];
};

/** A sport competition, common to all the sports. */
export type SportCompetitionV2SportEventsV2Args = {
	after?: InputMaybe<Scalars['String']['input']>;
	allowSponsoredRecommendations?: InputMaybe<SponsoredRecommendationsInput>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<SportEventFilter>;
	first?: Scalars['Int']['input'];
	sortOrder?: SortOrder;
	timezoneOffset: Scalars['Int']['input'];
};

/** A connection object for the sport event buckets. */
export type SportCompetitionsConnectionV2 = {
	__typename?: 'SportCompetitionsConnectionV2';
	/** The edges for each of the buckets. */
	edges?: Maybe<Array<SportCompetitionsEdgeV2>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** The total number of items. */
	totalCount: Scalars['Int']['output'];
};

/** An edge object for the sport event buckets. */
export type SportCompetitionsEdgeV2 = {
	__typename?: 'SportCompetitionsEdgeV2';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The node represented by this edge. */
	node: SportCompetitionV2;
};

/** A sport competitor content, common to all the sports. */
export type SportCompetitorContentV2 = {
	__typename?: 'SportCompetitorContentV2';
	/** The localized first name of the competitor. */
	firstName: Scalars['String']['output'];
	/**
	 * The localized website detail page URL of the sport competitor. Depends on the country being set.
	 *
	 * e.g. '/de/fussball/Bayern-München'
	 */
	fullPath?: Maybe<Scalars['String']['output']>;
	/**
	 * The blur hash representation of the logo.
	 *
	 * Generated using https://github.com/woltapp/blurhash.
	 */
	iconBlurHash?: Maybe<Scalars['String']['output']>;
	/**
	 * The localized logo URL. Depends on the country and language being set.
	 * It takes a desired size (called profile) and desired format as parameter.
	 *
	 * e.g. 's166' + 'webp' -> '/logo/8711516/s166/memento.webp'
	 */
	iconUrl?: Maybe<Scalars['String']['output']>;
	/** The localized last name of the competitor. */
	lastName: Scalars['String']['output'];
	/** The localized name of the competitor. */
	name: Scalars['String']['output'];
	/** A localized short description of the competitor. */
	shortDescription: Scalars['String']['output'];
};

/** A sport competitor content, common to all the sports. */
export type SportCompetitorContentV2IconUrlArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<IconProfile>;
};

/** A sport competitor, common to all the sports. */
export type SportCompetitorV2 = Node &
	SportTitle & {
		__typename?: 'SportCompetitorV2';
		/** The competitor children. */
		children: Array<SportCompetitorV2>;
		/** The sport competitor data. It is localized by language. */
		content: SportCompetitorContentV2;
		/** The global node ID. */
		id: Scalars['ID']['output'];
		/** The Content ID of the title. */
		objectId: Scalars['Int']['output'];
		/** The Content type of the title. */
		objectType: ObjectType;
		/** The sport of the competition. */
		sport: SportType;
		/** Runs a sport event search and returns an aggregated list of sport events for the given competition. */
		sportEventBucketsV2: SportEventBucketsConnectionV2;
		/** Runs a sport event search for the given competition. */
		sportEventsV2: SportEventsConnectionV2;
	};

/** A sport competitor, common to all the sports. */
export type SportCompetitorV2ContentArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
};

/** A sport competitor, common to all the sports. */
export type SportCompetitorV2SportEventBucketsV2Args = {
	after?: InputMaybe<Scalars['String']['input']>;
	bucketSize?: InputMaybe<Scalars['Int']['input']>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<SportEventFilter>;
	first?: Scalars['Int']['input'];
	groupBy: SportEventAggregation;
	sortBy?: SportEventSorting;
	sortOrder?: SortOrder;
	timezoneOffset: Scalars['Int']['input'];
};

/** A sport competitor, common to all the sports. */
export type SportCompetitorV2SportEventsV2Args = {
	after?: InputMaybe<Scalars['String']['input']>;
	allowSponsoredRecommendations?: InputMaybe<SponsoredRecommendationsInput>;
	country: Scalars['Country']['input'];
	filter?: InputMaybe<SportEventFilter>;
	first?: Scalars['Int']['input'];
	sortOrder?: SortOrder;
	timezoneOffset: Scalars['Int']['input'];
};

/** The different ways of aggregating the sport events. */
export enum SportEventAggregation {
	/** We aggregate by competition. */
	Competition = 'COMPETITION',
	/** We aggregate by competition stage. */
	CompetitionStage = 'COMPETITION_STAGE',
	/** We aggregate by date + competition. New timeline style. */
	DateCompetition = 'DATE_COMPETITION',
	/** We aggregate by sport event parent. */
	Parent = 'PARENT',
	/** We aggregate by sport. */
	Sport = 'SPORT',
}

/** A list of the possible aggregation keys being returned, depending on the aggregation type. */
export type SportEventAggregationKeyV2 = {
	__typename?: 'SportEventAggregationKeyV2';
	competition?: Maybe<SportCompetitionV2>;
	competitionStage?: Maybe<SportCompetitionStageV2>;
	parent?: Maybe<SportEventV2>;
	sport?: Maybe<Sport>;
	startAtDate?: Maybe<Scalars['Date']['output']>;
};

/** A connection object for the sport event buckets. */
export type SportEventBucketsConnectionV2 = {
	__typename?: 'SportEventBucketsConnectionV2';
	/** The edges for each of the buckets. */
	edges?: Maybe<Array<SportEventBucketsEdgeV2>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
};

/** An edge object for the sport event buckets. */
export type SportEventBucketsEdgeV2 = {
	__typename?: 'SportEventBucketsEdgeV2';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The bucket aggregation key. */
	key: SportEventAggregationKeyV2;
	/** The node represented by this edge. */
	node: SportEventsConnectionV2;
};

/** Generic interface for a sport event content, common to all the sports. */
export type SportEventContentV2 = {
	/**
	 * A list of backdrops. It takes a desired size (called profile) and desired format as parameter.
	 *
	 * e.g. 's1440' + 'webp' -> '/backdrop/8711516/s1440/Spider-Man-Homecoming.webp'
	 */
	backdrops: Array<Backdrop>;
	/** The stage of the competition the event is in. */
	competitionStage: SportCompetitionStageV2;
	/** When the event ends */
	endAt?: Maybe<Scalars['Time']['output']>;
	/**
	 * The localized website detail page URL of the sport event. Depends on the country being set.
	 *
	 * e.g. '/de/fussball/La-Liga/FC-Barcelona-Real-Madrid-2021-2022'
	 */
	fullPath?: Maybe<Scalars['String']['output']>;
	/** A localized name for the match */
	name: Scalars['String']['output'];
	/** The competition season during which the event took place */
	season?: Maybe<SportSeason>;
	/** When the event starts */
	startAt: Scalars['Time']['output'];
	/** General status of the event */
	status: SportEventStatus;
	/** The venue where the event was held */
	venue?: Maybe<SportVenue>;
};

/** Generic interface for a sport event content, common to all the sports. */
export type SportEventContentV2BackdropsArgs = {
	format?: InputMaybe<ImageFormat>;
	profile?: InputMaybe<BackdropProfile>;
};

/** The main sport event filter. */
export type SportEventFilter = {
	/** Keep only the events for the given competition ids (e.g. 'scn-foo-56'). */
	competitionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	/** Keep only the events for the given competition stage names. */
	competitionStageNames?: InputMaybe<Array<SportCompetitionStageName>>;
	/** When filtering by 'MATCH_DAY' football round for example, keep only the events for the given match day numbers. */
	competitionStageNumbers?: InputMaybe<Array<Scalars['Int']['input']>>;
	/** Keep only the events for the given competitor ids (e.g. 'scr-foo-32'). */
	competitorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	/** Keep only the given sport event ids (e.g. 'se-foo-33'). */
	eventIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	/** Keep only the events having an offer with the given packages. Short names are expected (e.g. 'nfx' for Netflix). */
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	/** Keep only the events having the given parent ids (e.g. 'scn-foo-56').. */
	parentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	/** Keep only the events for the given sports. */
	sports?: InputMaybe<Array<SportType>>;
	/** Keep only the events starting over the given timeframe. Only returning the upcoming events by default. */
	startAt?: InputMaybe<TimeFilter>;
};

/** The different possible matrix responses. */
export type SportEventMatrix = SportEventMatrixCompetitionColumns | SportEventMatrixPackageColumns;

/** The different ways of aggregating the sport events. */
export enum SportEventMatrixAggregation {
	/** We aggregate by competition + by package. */
	CompetitionPackage = 'COMPETITION_PACKAGE',
	/** We aggregate by package + by competition. */
	PackageCompetition = 'PACKAGE_COMPETITION',
}

/** A column of the matrix for the COMPETITION_PACKAGE aggregation. */
export type SportEventMatrixCompetitionColumn = {
	__typename?: 'SportEventMatrixCompetitionColumn';
	/** The competition of the column. */
	competition: SportCompetitionV2;
	/** The count of unique sport events for this column. */
	count: Scalars['Int']['output'];
	/** The package rows per competition. */
	packages: Array<SportEventMatrixPackageRow>;
};

/** The columns for the COMPETITION_PACKAGE aggregation. */
export type SportEventMatrixCompetitionColumns = {
	__typename?: 'SportEventMatrixCompetitionColumns';
	/** The competition columns. */
	competitions: Array<SportEventMatrixCompetitionColumn>;
};

/** A row of the matrix for the PACKAGE_COMPETITION aggregation. */
export type SportEventMatrixCompetitionRow = {
	__typename?: 'SportEventMatrixCompetitionRow';
	/** The competition of the row. */
	competition: SportCompetitionV2;
	/** The count of unique sport events for this row. */
	count: Scalars['Int']['output'];
};

/** A column of the matrix for the PACKAGE_COMPETITION aggregation. */
export type SportEventMatrixPackageColumn = {
	__typename?: 'SportEventMatrixPackageColumn';
	/** The competition rows per package. */
	competitions: Array<SportEventMatrixCompetitionRow>;
	/** The count of unique sport events for this column. */
	count: Scalars['Int']['output'];
	/** The package of the column. */
	package: Package;
};

/** The columns for the PACKAGE_COMPETITION aggregation. */
export type SportEventMatrixPackageColumns = {
	__typename?: 'SportEventMatrixPackageColumns';
	/** The package columns. */
	packages: Array<SportEventMatrixPackageColumn>;
};

/** A row of the matrix for the COMPETITION_PACKAGE aggregation. */
export type SportEventMatrixPackageRow = {
	__typename?: 'SportEventMatrixPackageRow';
	/** The count of unique sport events for this row. */
	count: Scalars['Int']['output'];
	/** The package of the row. */
	package: Package;
};

/** The different ways of sorting the sport events. */
export enum SportEventSorting {
	/** We sort by popularity, with a fallback to start time. */
	Popularity = 'POPULARITY',
	/** We sort by start time. */
	StartAt = 'START_AT',
}

/** Defines the possible statuses of a sport event. */
export enum SportEventStatus {
	/** The match has been cancelled and will not be played */
	Cancelled = 'CANCELLED',
	/** The match results have been confirmed */
	Closed = 'CLOSED',
	/** The match has been temporarily delayed and will be continued */
	Delayed = 'DELAYED',
	/** The match is over */
	Ended = 'ENDED',
	/** The match began, but coverage has stopped for a short time. Note that match scores may not be updated during this period, the last recorded match score will be displayed instead */
	Interrupted = 'INTERRUPTED',
	/** The match is currently in progress */
	Live = 'LIVE',
	/** The match is about to begin */
	MatchAboutToStart = 'MATCH_ABOUT_TO_START',
	/** The match is scheduled to be played */
	NotStarted = 'NOT_STARTED',
	/** The match has been postponed to a future date */
	Postponed = 'POSTPONED',
	/** The match has begun */
	Started = 'STARTED',
	/** The match has been suspended */
	Suspended = 'SUSPENDED',
}

/** Generic interface for a sport event, common to all the sports. */
export type SportEventV2 = {
	/** The competition the event belongs to. */
	competition?: Maybe<SportCompetitionV2>;
	/** The sport event data. It is localized by language. */
	content: SportEventContentV2;
	/** Node Id of the event. */
	id: Scalars['ID']['output'];
	/** The entry for the sport event in the my sport list. */
	mysportlistEntry?: Maybe<TitleListEntry>;
	/** The Content ID of the title. */
	objectId: Scalars['Int']['output'];
	/** The Content type of the title. */
	objectType: ObjectType;
	/** The offer count available on packages for the event. Depends on the country being set.  */
	offerCount: Scalars['Int']['output'];
	/** The offers for the sport event. */
	offers: Array<SportOffer>;
	/** The sport of the event. */
	sport: SportType;
	/** The default offer that should be used to play the event. Can be null if there are no matching offers. */
	watchNowOffer?: Maybe<SportOffer>;
};

/** Generic interface for a sport event, common to all the sports. */
export type SportEventV2ContentArgs = {
	country: Scalars['Country']['input'];
	language: Scalars['Language']['input'];
};

/** Generic interface for a sport event, common to all the sports. */
export type SportEventV2OfferCountArgs = {
	country: Scalars['Country']['input'];
	filter?: SportOfferFilter;
	platform: Platform;
};

/** Generic interface for a sport event, common to all the sports. */
export type SportEventV2OffersArgs = {
	country: Scalars['Country']['input'];
	filter?: SportOfferFilter;
	platform: Platform;
};

/** Generic interface for a sport event, common to all the sports. */
export type SportEventV2WatchNowOfferArgs = {
	country: Scalars['Country']['input'];
	filter?: WatchNowSportOfferFilter;
	platform: Platform;
};

/** A connection object for the sport events. */
export type SportEventsConnectionV2 = {
	__typename?: 'SportEventsConnectionV2';
	/** The edges for each of the items. */
	edges?: Maybe<Array<SportEventsEdgeV2>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** Indicates if there's a Sponsored Recommendation Ad to display. */
	sponsoredAd?: Maybe<SponsoredRecommendationAd>;
	/** The total number of items. */
	totalCount: Scalars['Int']['output'];
};

/** An edge object for the sport events. */
export type SportEventsEdgeV2 = {
	__typename?: 'SportEventsEdgeV2';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The node represented by this edge. */
	node: SportEventV2;
};

/** The type of a Sport object. */
export enum SportObjectType {
	/** Highlights of a sport event */
	Highlight = 'HIGHLIGHT',
	/** An interview related to a sport event */
	Interview = 'INTERVIEW',
	/** A full sport event */
	MainEvent = 'MAIN_EVENT',
}

/** Defines the offering of a package for a certain Content sport event. Unique key is (jwEntityID, country, packageId, monetizationType, presentationType, sportObjectType). */
export type SportOffer = {
	__typename?: 'SportOffer';
	/** Language when language specific stream offers are available. */
	audioLanguage?: Maybe<Scalars['String']['output']>;
	/**
	 * Language when language specific stream offers are available.
	 * @deprecated Please use the 'audioLanguage' field
	 */
	audioLanguages?: Maybe<Array<Scalars['String']['output']>>;
	/** The date where the title becomes available on the provider. */
	availableFrom?: Maybe<Scalars['Time']['output']>;
	/** The date where the title is leaving the provider. */
	availableTo?: Maybe<Scalars['Time']['output']>;
	/** The user's country */
	country: Scalars['Country']['output'];
	/** The pricings currency. */
	currency?: Maybe<Scalars['String']['output']>;
	/** Deeplink URL directly playing an offer. */
	deeplinkURL?: Maybe<Scalars['String']['output']>;
	/** The monetization of the offer. */
	monetizationType: MonetizationType;
	/** The related package object. */
	package: Package;
	/** The presentation format of the offer. */
	presentationType: PresentationType;
	/** The raw price of the offer (monetization type BUY/RENT). */
	retailPrice?: Maybe<Scalars['Float']['output']>;
	/** The raw price of the offer (monetization type BUY/RENT). */
	retailPriceValue?: Maybe<Scalars['Float']['output']>;
	/** The type of content contained in the offer */
	sportObjectType: SportObjectType;
	/** Website URL of the offer page. */
	standardWebURL?: Maybe<Scalars['String']['output']>;
	/** subTitle language filter when subtitle in multiple languages are available */
	subtitleLanguage?: Maybe<Scalars['String']['output']>;
	/**
	 * subTitle language filter when subtitle in multiple languages are available
	 * @deprecated Please use the 'subtitleLanguage' field
	 */
	subtitleLanguages?: Maybe<Array<Scalars['String']['output']>>;
};

/** Defines the offering of a package for a certain Content sport event. Unique key is (jwEntityID, country, packageId, monetizationType, presentationType, sportObjectType). */
export type SportOfferDeeplinkUrlArgs = {
	platform: Platform;
};

/** Defines the offering of a package for a certain Content sport event. Unique key is (jwEntityID, country, packageId, monetizationType, presentationType, sportObjectType). */
export type SportOfferRetailPriceArgs = {
	language: Scalars['Language']['input'];
};

/** This filter allows filtering of a list of offers. */
export type SportOfferFilter = {
	/** Only keep the best offer per package. */
	bestOnly?: InputMaybe<Scalars['Boolean']['input']>;
	/** Keep only the offers having the given packages. Short names are expected (e.g. 'nfx' for Netflix). */
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** A particular season for a sport competition. */
export type SportSeason = {
	__typename?: 'SportSeason';
	/** The localized name of the season */
	name: Scalars['String']['output'];
	/** ID of the season */
	seasonID: Scalars['Int']['output'];
};

/** Generic interface shared between all the sport entities. */
export type SportTitle = {
	/**
	 * The global node ID of the title. For titles, it is defined by Content and made from object type + object ID.
	 *
	 * e.g. 'se-foo-21' -> 'sport_event' + 'football' + 21
	 */
	id: Scalars['ID']['output'];
	/** The Content ID of the title. */
	objectId: Scalars['Int']['output'];
	/** The Content type of the title. */
	objectType: ObjectType;
	/** The sport of the competition. */
	sport: SportType;
};

/** The different sports being supported. */
export enum SportType {
	/** Not the foot-on-ball. */
	AmericanFootball = 'AMERICAN_FOOTBALL',
	/** The bat and ball sport. */
	Baseball = 'BASEBALL',
	/** Bouncing ball sport. */
	Basketball = 'BASKETBALL',
	/** Gentleman's ballet with bat, ball. */
	Cricket = 'CRICKET',
	/** eSport: csgo */
	EsportsCsgo = 'ESPORTS_CSGO',
	/** eSport: Dota2 */
	EsportsDota2 = 'ESPORTS_DOTA2',
	/** eSport: league of legends */
	EsportsLol = 'ESPORTS_LOL',
	/** That's the real football one. */
	Football = 'FOOTBALL',
	/** All the Formula 1, 2, 3 stuff. */
	FormulaRacing = 'FORMULA_RACING',
	/** Hockey on ice. */
	IceHockey = 'ICE_HOCKEY',
	/** A close contact team sport */
	Rugby = 'RUGBY',
	/** People exchanging a yellow ball. */
	Tennis = 'TENNIS',
}

/** A sport venue (e.g. stadium, arena...). */
export type SportVenue = {
	__typename?: 'SportVenue';
	/** The country of the venue */
	country: Scalars['Country']['output'];
	/** The localized name of the venue */
	name: Scalars['String']['output'];
	/** ID of the venue */
	venueID: Scalars['Int']['output'];
};

/** The different types of event actions that can be tracked. */
export enum SraEventAction {
	/** To track impression once they are rendered */
	AdRender = 'AD_RENDER',
	/** To track when the user clicked out from the detail page, right after clicking on the ad. */
	BuyboxClickout = 'BUYBOX_CLICKOUT',
	/** To track when the card of the ad is clicked. */
	Click = 'CLICK',
	/** To track when the user clicked out to an external site. */
	Clickout = 'CLICKOUT',
	/** To track when the user added the title to their dislikelist. */
	DislikelistClicked = 'DISLIKELIST_CLICKED',
	/** To track the result of a external tracker on clickout events */
	ExternalClickoutTrackerFired = 'EXTERNAL_CLICKOUT_TRACKER_FIRED',
	/** To track the result of a external tracker on impression events */
	ExternalImpressionTrackerFired = 'EXTERNAL_IMPRESSION_TRACKER_FIRED',
	/** To track when the user clicked on the follow button (title lists) of the ad. */
	FollowClicked = 'FOLLOW_CLICKED',
	/** To track when there's an impression of the ad. */
	Impression = 'IMPRESSION',
	/** To track when the user added the title to their likelist. */
	LikelistClicked = 'LIKELIST_CLICKED',
	/** To track when the user clicked out to a title list detail page. */
	ListClickout = 'LIST_CLICKOUT',
	/** To track when the user clicked on the more info button of the ad. */
	MoreInfoClicked = 'MORE_INFO_CLICKED',
	/** To track when the user clicked on the play trailer button of the ad. */
	PlayTrailerClicked = 'PLAY_TRAILER_CLICKED',
	/** It should be fired when the user click in an individual title within a title list in SR */
	PosterClick = 'POSTER_CLICK',
	/** To track when the user clicked on the rating of the ad. */
	RatingClicked = 'RATING_CLICKED',
	/** To track when the user clicked on the ringbell button of the ad. */
	RingbellClicked = 'RINGBELL_CLICKED',
	/** To track when the user added the title to their seenlist. */
	SeenlistClicked = 'SEENLIST_CLICKED',
	/** To track when the user viewed a SR video, along with the progress. */
	VideoView = 'VIDEO_VIEW',
	/** To track when the user viewed a SR video, along with the seconds. */
	VideoViewSeconds = 'VIDEO_VIEW_SECONDS',
	/** The new definition of an impression, matching google criteria. */
	ViewableImpression = 'VIEWABLE_IMPRESSION',
	/** To track when the user added the title to their watchlist. */
	WatchlistClicked = 'WATCHLIST_CLICKED',
	/** To track when the watch now button of the ad is clicked. */
	WatchnowClicked = 'WATCHNOW_CLICKED',
}

/** The format of a sponsored recommendation creative. */
export enum SraFormat {
	Image = 'IMAGE',
	Video = 'VIDEO',
}

/** The placement used to calculate the best sponsored recommendation ad for the user. */
export enum SraPlacement {
	AllSportsPage = 'ALL_SPORTS_PAGE',
	DetailPage = 'DETAIL_PAGE',
	Home = 'HOME',
	NewTimeline = 'NEW_TIMELINE',
	PopularView = 'POPULAR_VIEW',
	SearchBox = 'SEARCH_BOX',
	SearchPage = 'SEARCH_PAGE',
	SportCompetitionPage = 'SPORT_COMPETITION_PAGE',
	SportCompetitorPage = 'SPORT_COMPETITOR_PAGE',
	SportEventPage = 'SPORT_EVENT_PAGE',
	SportOverviewPage = 'SPORT_OVERVIEW_PAGE',
}

/** Additional data related to the placement. */
export type SraPlacementContext = {
	/**
	 * For the detail page and the search page contextual targeting.
	 * It indicates the entity ID of the title that the user is currently viewing.
	 */
	jwEntityID?: InputMaybe<Scalars['ID']['input']>;
	/**
	 * For the different sport pages contextual targeting.
	 * It indicates the sports that the user is currently viewing.
	 */
	sports?: InputMaybe<Array<SportType>>;
};

/** Streaming chart rank for each title and its related information. */
export type StreamingChartInfo = {
	__typename?: 'StreamingChartInfo';
	/** The number of days the title has been in the top 3 ranks. */
	daysInTop3: Scalars['Int']['output'];
	/** The number of days the title has been in the top 10 ranks. */
	daysInTop10: Scalars['Int']['output'];
	/** The number of days the title has been in the top 100 ranks. */
	daysInTop100: Scalars['Int']['output'];
	/** The number of days the title has been in the top 1000 ranks. */
	daysInTop1000: Scalars['Int']['output'];
	/** The popularity rank for the current object. */
	rank: Scalars['Int']['output'];
	/** The maximum rank reached by the title. */
	topRank: Scalars['Int']['output'];
	/** The moving window of the current title popularity in the last 30 days. */
	trend: ModuleContentTitleTrend;
	/** Rank difference change popularity in the last 30 days. */
	trendDifference: Scalars['Int']['output'];
	/** Time at which the streaming chart data updated. */
	updatedAt?: Maybe<Scalars['Time']['output']>;
};

/** The object type of the streaming chart titles. */
export enum StreamingChartObjectType {
	/** That's a movie. */
	Movie = 'MOVIE',
	/** That's a show. */
	Show = 'SHOW',
	/** That's a season. */
	ShowSeason = 'SHOW_SEASON',
}

/** A connection object for the streaming charts. */
export type StreamingChartsConnection = {
	__typename?: 'StreamingChartsConnection';
	/** The edges for each of the items. */
	edges?: Maybe<Array<StreamingChartsTitlesEdge>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** The total number of items. */
	totalCount: Scalars['Int']['output'];
};

/** The streaming charts filter. */
export type StreamingChartsFilter = {
	/** The category of the streaming chart type */
	category?: PopularityRankTypes;
	/** The title which the streaming chart rank is required. Used in the detail page. */
	jwEntityID?: InputMaybe<Scalars['String']['input']>;
	/** Number of next titles required along with the requested one. */
	nextTitles?: InputMaybe<Scalars['Int']['input']>;
	/** The object type for the trending titles - movie, show or show_season */
	objectType?: StreamingChartObjectType;
	/** Keep only the titles having an offer with the given packages. Short names are expected (e.g. 'nfx' for Netflix). Rankings are not valid when using packages filter, just use the trends. */
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	/** Number of previous titles required along with the requested one. */
	previousTitles?: InputMaybe<Scalars['Int']['input']>;
};

/** An edge object for the streaming chart titles. */
export type StreamingChartsTitlesEdge = {
	__typename?: 'StreamingChartsTitlesEdge';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The node represented by this edge. */
	node: MovieOrShowOrSeason;
	/** Streaming chart information for the title. */
	streamingChartInfo?: Maybe<StreamingChartInfo>;
};

/** The input needed to create a Stripe checkout session. */
export type StripeCheckoutSessionInput = {
	/** Stripe session ID */
	baseURL: Scalars['String']['input'];
	/** Stripe product id based on the ab test variant. If not provided default gets chosen. */
	stripeProductID?: InputMaybe<Scalars['String']['input']>;
};

/** The payload response of the Stripe checkout session. */
export type StripeCheckoutSessionPayload = {
	__typename?: 'StripeCheckoutSessionPayload';
	/** The checkout url received from Stripe. */
	checkoutURL: Scalars['String']['output'];
};

/** The type of Stripe checkout session. */
export enum StripeCheckoutSessionStatus {
	/** Stripe checkout session is completed. */
	Complete = 'COMPLETE',
	/** Stripe checkout session has expired. */
	Expired = 'EXPIRED',
	/** Stripe checkout session is ongoing. */
	Open = 'OPEN',
}

/** The stripe checkout session query for payments. */
export type StripeCheckoutSessionStatusInput = {
	/** The Stripe checkout session id to be checked. */
	checkoutSessionId: Scalars['String']['input'];
};

/** The status of Stripe checkout session.. */
export type StripeCheckoutSessionStatusType = {
	__typename?: 'StripeCheckoutSessionStatusType';
	/** The return type for checking the Stripe checkout session status. */
	status: StripeCheckoutSessionStatus;
};

/** The subscription root of JustWatch's GraphQL interface. */
export type Subscription = {
	__typename?: 'Subscription';
	/**
	 * Listens to a title play event stream coming from the same logged in account.
	 * @deprecated Name is now mandatory. Use the last version.
	 */
	playTitleReceived: MovieOrShowOrSeasonOrEpisode;
	/** Listens to a title play event stream coming from the same logged in account. */
	playTitleReceivedV2: MovieOrShowOrSeasonOrEpisode;
	/** Listens to a user settings change event stream coming from the same logged in account. */
	settingsReceived: Settings;
};

/** The subscription root of JustWatch's GraphQL interface. */
export type SubscriptionPlayTitleReceivedV2Args = {
	deviceName: Scalars['String']['input'];
};

export type Tag = {
	__typename?: 'Tag';
	/** Technical name of the tag */
	technicalName: Scalars['String']['output'];
	/** The translatedName of the tag */
	translatedName: Scalars['String']['output'];
};

export enum TargetType {
	/** existing Customlist */
	ExistingCustomlist = 'EXISTING_CUSTOMLIST',
	/** New Customlist */
	NewCustomlist = 'NEW_CUSTOMLIST',
}

/** Generated list of taste survey titles based on the liked enitities input provided. */
export type TasteSurveyNextTitle = {
	__typename?: 'TasteSurveyNextTitle';
	/** List of liked entity information submitted by the user. */
	likedJWEntityIDs?: Maybe<Array<Scalars['String']['output']>>;
	/** Model version that was used to recommend the recommended titles. */
	modelVersion: Scalars['String']['output'];
	/** List of title object related to the content for the display. */
	nextTitles: Array<MovieOrShow>;
};

export type TasteSurveyResetPayload = {
	__typename?: 'TasteSurveyResetPayload';
	/** Current user session information best effort ID - user or device ID */
	bestEffortID: Scalars['String']['output'];
};

/** The different subheadings possible on the edges of a watch next list. */
export enum TasteSurveyType {
	/** Gefjon based onboarding, giving propositions based on the likes of the user and storing a temporary state in the user profile api. */
	AutoDigger = 'AUTO_DIGGER',
	/** Legacy onboarding using a static json. */
	DivergingTitles = 'DIVERGING_TITLES',
	/** Legacy onboarding used for a gamified onboarding. */
	FinderGame = 'FINDER_GAME',
	/** Used when bypassing the onboarding altogether for our last version. */
	GlobalClickedCta = 'GLOBAL_CLICKED_CTA',
	/** Gefjon based onboarding, giving propositions based on the likes of the user, but stateless. */
	StaticDigger = 'STATIC_DIGGER',
	/** Gefjon based onboarding, giving static propositions based on what is displayed to the user. */
	StaticMappings = 'STATIC_MAPPINGS',
	/** Legacy onboarding used as a hack by the TV app to bypass the onboarding. */
	TvAppBlank = 'TV_APP_BLANK',
}

/** These are written reviews by editors or personalities. Used in the why-to-watch feature. */
export type TextRecommendation = {
	__typename?: 'TextRecommendation';
	/** Main body text of the text recommendation. Might be automatically translated */
	body: Scalars['String']['output'];
	/** Custom profile type */
	customProfileType?: Maybe<Scalars['String']['output']>;
	/** Headline, short-text of the text recommendation. Might be automatically translated */
	headline?: Maybe<Scalars['String']['output']>;
	/** Unique ID of the text recommendation */
	id: Scalars['ID']['output'];
	/** The jwEntityID that this text recommendation is about */
	jwEntityID: Scalars['String']['output'];
	/** How many thumbs up this recommendation has */
	likeCount: Scalars['Int']['output'];
	/** Whether the current user has liked this text recommendation */
	likedByUser: Scalars['Boolean']['output'];
	/** Main body text of the text recommendation of the original text recommendation. Only present when the headline is an automatic translation */
	originalBody?: Maybe<Scalars['String']['output']>;
	/** Headline, short-text of the text recommendation of the original text recommendation. Only present when the headline is an automatic translation */
	originalHeadline?: Maybe<Scalars['String']['output']>;
	/** Whether the current user has access to edit this text recommendation */
	ownedByUser: Scalars['Boolean']['output'];
	/** Who wrote it */
	profile: Profile;
	/** Tags associated with the text recommendation */
	tags?: Maybe<Array<Tag>>;
	/** The title that this text recommendation is about */
	title: MovieOrShowOrSeason;
	/** Date/time when the text recommendation was last updated. Truncated to date as per FE request */
	updatedAt: Scalars['Date']['output'];
	/** Date when the entity was watched */
	watchedAt: Scalars['Date']['output'];
	/** What package the entity was watched on */
	watchedOn?: Maybe<Package>;
};

export type TextRecommendationFilter = {
	/** Filter by jw entity id. Expected input is jw entity ID */
	JwEntityIDs?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** A Time range Title filter. . */
export type TimeFilter = {
	/** The from value. */
	from?: InputMaybe<Scalars['Time']['input']>;
	/** The to value. */
	to?: InputMaybe<Scalars['Time']['input']>;
};

/**
 * Personalized title aggregating user data and static Content data.
 *
 * This interface contains the fields that are common between all the different titles.
 */
export type Title = {
	/**
	 * The global node ID of the title. For titles, it is defined by Content and made from object type + object ID.
	 *
	 * e.g. 'tm21' -> 'movie' + 21, 'tss11' -> 'show_season' + 11
	 */
	id: Scalars['ID']['output'];
	/** The Content ID of the title. */
	objectId: Scalars['Int']['output'];
	/** The Content type of the title. */
	objectType: ObjectType;
};

/** The main title filter. */
export type TitleFilter = {
	/** Keep only the titles having at least one of the given age certifications. Technical names are expected (e.g. 'PG-13' for PG-13). */
	ageCertifications?: InputMaybe<Array<Scalars['String']['input']>>;
	/** Keep only the offer date of titles leaving soon. */
	availableToDate?: InputMaybe<DateFilter>;
	/** Only return titles that have clips with the following technical provider */
	clipTechnicalProvider?: InputMaybe<Scalars['String']['input']>;
	/** Only return titles that have clips with the following type */
	clipType?: InputMaybe<Scalars['String']['input']>;
	/** Exclude the titles having at least one of the given genres. Short names are expected (e.g. 'act' for Action). */
	excludeGenres?: InputMaybe<Array<Scalars['String']['input']>>;
	/** If set to true, irrelevant titles will get exluded from the result (e.g seen and disliked titles). */
	excludeIrrelevantTitles?: InputMaybe<Scalars['Boolean']['input']>;
	/** Exclude the titles having at least one of the given production countries. */
	excludeProductionCountries?: InputMaybe<Array<Scalars['Country']['input']>>;
	/** Exclude the titles having at least one of the given subgenres. Short names are expected (e.g. 'drmy' for drama mystery (?)). */
	excludeSubgenres?: InputMaybe<Array<Scalars['String']['input']>>;
	/** Keep only the titles having at least one of the given genres. Short names are expected (e.g. 'act' for Action). */
	genres?: InputMaybe<Array<Scalars['String']['input']>>;
	/** Keep only the titles having an IMDB score within the given range. */
	imdbScore?: InputMaybe<FloatFilter>;
	/** Keep only the titles having a number of IMDB votes within the given range. */
	imdbVotes?: InputMaybe<IntFilter>;
	/** Include titles which dont have a URL. Fullpath will be generated as /title?id=<jwentityid> */
	includeTitlesWithoutUrl?: InputMaybe<Scalars['Boolean']['input']>;
	/**
	 * If set to true, only keeping upcoming titles.
	 *
	 * Since they don't have offers, the 'packages' filter also changes its behavior: it can be used to get titles upcoming on certain packages.
	 */
	isUpcoming?: InputMaybe<Scalars['Boolean']['input']>;
	/** Keep only the titles having an offer with the given monetization types. */
	monetizationTypes?: InputMaybe<Array<MonetizationType>>;
	/** Keep only the titles having these object types. */
	objectTypes?: InputMaybe<Array<ObjectType>>;
	/** Keep only the offers created within the given range. */
	offerDateCreated?: InputMaybe<DateFilter>;
	/** Keep only the offers that had their price changed within the given range. */
	offerLastChangeDate?: InputMaybe<DateFilter>;
	/** Keep only the titles having an offer with the given packages. Short names are expected (e.g. 'nfx' for Netflix). */
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	/** Keep only the titles having this person ID in the credits. */
	personId?: InputMaybe<Scalars['ID']['input']>;
	/** Only valid in combination with personId. Keep only the titles having the person with a specific role in the credits */
	personRole?: InputMaybe<CreditRole>;
	/** Keep only the titles having an offer with the given presentation types. */
	presentationTypes?: InputMaybe<Array<PresentationType>>;
	/** Keep only the titles having an offer priced within the given range. */
	price?: InputMaybe<FloatFilter>;
	/** Keep only the titles having at least one of the given production countries. */
	productionCountries?: InputMaybe<Array<Scalars['Country']['input']>>;
	/** Keep only the titles having a release year within the given range. */
	releaseYear?: InputMaybe<IntFilter>;
	/** Keep only the titles having a runtime within the given range. */
	runtime?: InputMaybe<IntFilter>;
	/** Fuzzy search using the given query string. */
	searchQuery?: InputMaybe<Scalars['String']['input']>;
	/** Keep only the titles having at least one of the given subgenres. Short names are expected (e.g. 'drmy'). */
	subgenres?: InputMaybe<Array<Scalars['String']['input']>>;
	/** Keep only the titles having a Rotten Tomatoes Meter scores within the given range. */
	tomatoMeter?: InputMaybe<FloatFilter>;
	/** If set to true, we will internally fetch and use the user settings packages. */
	useUserPackages?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A connection object for a title list. */
export type TitleListConnection = {
	__typename?: 'TitleListConnection';
	/** The edges for each of the items. */
	edges?: Maybe<Array<TitleListEdge>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** The total number of items. */
	totalCount: Scalars['Int']['output'];
};

/** A connection object for a title list. */
export type TitleListConnectionV2 = {
	__typename?: 'TitleListConnectionV2';
	/** The edges for each of the items. */
	edges?: Maybe<Array<TitleListEdgeV2>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** The total number of items. */
	totalCount: Scalars['Int']['output'];
};

/** An edge object for a title list node. */
export type TitleListEdge = {
	__typename?: 'TitleListEdge';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The node represented by this edge. */
	node: MovieOrShow;
	/** The subheading the node belongs to. */
	subheading?: Maybe<TitleListSubheading>;
};

/** An edge object for a title list node. */
export type TitleListEdgeV2 = {
	__typename?: 'TitleListEdgeV2';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The node represented by this edge. */
	node: MovieOrShow;
};

/** An entry in a user title list. */
export type TitleListEntry = {
	__typename?: 'TitleListEntry';
	/** The time at which the entry was created. */
	createdAt: Scalars['Time']['output'];
	/** The parent generic title list. */
	genericTitleList: GenericTitleList;
};

/** The content types supported by the watchlist queries. */
export enum TitleListObjectType {
	/** That's a movie. */
	Movie = 'MOVIE',
	/** That's a show. */
	Show = 'SHOW',
}

/** The field/info to order titles by. */
export enum TitleListSorting {
	/** Sorted by title. */
	Alphabetical = 'ALPHABETICAL',
	/** Sorted by IMDB score. */
	ImdbScore = 'IMDB_SCORE',
	/** Sorted by last addition to the list. */
	LastAdded = 'LAST_ADDED',
	/** Sorted by JW popularity score. */
	Popular = 'POPULAR',
	/** Sorted randomly, based on the provided randomSeed. */
	Random = 'RANDOM',
	/** Sorted by release year. */
	ReleaseYear = 'RELEASE_YEAR',
	/** Could also be called 'BY_SUBHEADING'. Sorted by last list interaction. */
	Relevance = 'RELEVANCE',
	/** Sorted by runtime. */
	Runtime = 'RUNTIME',
	/** Sorted by TMDB popularity. */
	TmdbPopularity = 'TMDB_POPULARITY',
	/** Sorted by JW trending score. */
	Trending = 'TRENDING',
}

/** The field/info to order titles by. */
export enum TitleListSortingV2 {
	/** Sorted by title. */
	Alphabetical = 'ALPHABETICAL',
	/** Sorted by IMDB score. */
	ImdbScore = 'IMDB_SCORE',
	/** Sorted by last addition to the list. */
	LastAdded = 'LAST_ADDED',
	/** Sorted by JW popularity score. */
	Popular = 'POPULAR',
	/** Sorted randomly, based on the provided randomSeed. */
	Random = 'RANDOM',
	/** Sorted by release year. */
	ReleaseYear = 'RELEASE_YEAR',
	/** Sorted by last list interaction. */
	Relevance = 'RELEVANCE',
	/** Sorted by runtime. */
	Runtime = 'RUNTIME',
	/** Sorted by TMDB popularity. */
	TmdbPopularity = 'TMDB_POPULARITY',
	/** Sorted by JW trending score. */
	Trending = 'TRENDING',
}

/** A list of the possible subheadings being returned, depending on the list type. */
export type TitleListSubheading = CaughtUpSubheading | WatchNextSubheading;

/** The different subheadings possible on the edges of a title list. */
export enum TitleListSubheadingTechnicalName {
	/** The shows under this category have been partly seen already. */
	ContinueWatching = 'CONTINUE_WATCHING',
	/** The show is not airing anymore. */
	Finished = 'FINISHED',
	/** The shows under this category haven't been started. */
	NotStarted = 'NOT_STARTED',
	/** The show is coming back (new episode or season). */
	Upcoming = 'UPCOMING',
}

/** The different readable list types. */
export enum TitleListType {
	/** The list of the titles we're done watching. */
	CaughtUp = 'CAUGHT_UP',
	/** The list showing the disliked titles of the user. */
	Dislikelist = 'DISLIKELIST',
	/** The list showing the liked titles of the user. */
	Likelist = 'LIKELIST',
	/** The list of our watchlisted and not fully seen titles. */
	WatchNext = 'WATCH_NEXT',
}

/** The different readable list types. */
export enum TitleListTypeV2 {
	/** The list showing the disliked titles of the user. */
	Dislikelist = 'DISLIKELIST',
	/** The list showing the liked titles of the user. */
	Likelist = 'LIKELIST',
	/** The list of our seen titles. */
	Seenlist = 'SEENLIST',
	/** The list showing the watchlisted titles of the user. */
	Watchlist = 'WATCHLIST',
}

/** The input needed to track a single user event. */
export type TrackEventInput = {
	/** The request body encoded as a query string */
	event: Scalars['String']['input'];
};

/** The payload response for a single tracked user event. */
export type TrackEventPayload = {
	__typename?: 'TrackEventPayload';
	/** A string response */
	response: Scalars['String']['output'];
};

/** The input need to track batched user event sent from client. */
export type TrackEventsInput = {
	events: Array<TrackEventInput>;
};

/** The payload response for multiple tracked user events. */
export type TrackEventsPayload = {
	__typename?: 'TrackEventsPayload';
	responses: Array<TrackEventPayload>;
};

/** The input needed to track a Sponsored Recommendation Ad event. */
export type TrackSraEventInput = {
	/** The action to be tracked */
	action: SraEventAction;
	/** The bid id linked to an Ad. */
	bidId: Scalars['String']['input'];
	/** External Tracker Status */
	externalTrackerStatus?: InputMaybe<Scalars['Int']['input']>;
	/** External Tracker URL */
	externalTrackerURL?: InputMaybe<Scalars['String']['input']>;
	/** If the event was influenced by a previous seen sponsored recommendation. */
	influenced?: Scalars['Boolean']['input'];
	/** Flag to identify if this is a shadow event. */
	isShadowEvent?: Scalars['Boolean']['input'];
	/** Flag to identify if this the sr has been tampered with. */
	isSrTampered?: Scalars['Boolean']['input'];
	/** The entity id of the title that the event is related to, if relevant. */
	jwEntityID?: InputMaybe<Scalars['String']['input']>;
	/** Page URL */
	pageUrl?: InputMaybe<Scalars['String']['input']>;
	/** The position of the event in a list, if relevant. */
	position?: InputMaybe<Scalars['Int']['input']>;
	/** The watch progress of a video, if relevant. */
	progress?: InputMaybe<Scalars['Int']['input']>;
	/** General information about the context of the tracking. */
	sraInput: SponsoredRecommendationsInput;
};

/** The payload response for the tracked Sponsored Recommendation Ad event. */
export type TrackSraEventPayload = {
	__typename?: 'TrackSraEventPayload';
	/** The tracked bid ID. */
	bidId: Scalars['String']['output'];
};

/** The event to track when batching. */
export type TrackSraEventsEvent = {
	/** The action to be tracked */
	action: SraEventAction;
	/** External Tracker Status */
	externalTrackerStatus?: InputMaybe<Scalars['Int']['input']>;
	/** External Tracker URL */
	externalTrackerURL?: InputMaybe<Scalars['String']['input']>;
	/** If the event is a shadow event */
	isShadowEvent?: Scalars['Boolean']['input'];
	/** The entity id of the title that the event is related to, if relevant. */
	jwEntityID?: InputMaybe<Scalars['String']['input']>;
	/** The position of the event in a list, if relevant. */
	position?: InputMaybe<Scalars['Int']['input']>;
	/** The watch progress of a video, if relevant. */
	progress?: InputMaybe<Scalars['Int']['input']>;
};

/** The input needed to track multiple Sponsored Recommendation Ad events. */
export type TrackSraEventsInput = {
	/** The bid id linked to an Ad. */
	bidId: Scalars['String']['input'];
	/** The events to track. */
	events: Array<TrackSraEventsEvent>;
	/** General information about the context of the tracking. */
	sraInput: SponsoredRecommendationsInput;
};

/** The payload response for multiple tracked Sponsored Recommendation Ad events. */
export type TrackSraEventsPayload = {
	__typename?: 'TrackSraEventsPayload';
	payloads: Array<TrackSraEventPayload>;
};

/** A connection object for tv show tracking. */
export type TvShowTrackingConnection = {
	__typename?: 'TvShowTrackingConnection';
	/** The edges for each of the items. */
	edges?: Maybe<Array<TvShowTrackingEdge>>;
	/** Information for paginating this connection. */
	pageInfo: PageInfo;
	/** The total number of items. */
	totalCount: Scalars['Int']['output'];
};

/** An edge object for a tv show tracking node. */
export type TvShowTrackingEdge = {
	__typename?: 'TvShowTrackingEdge';
	/** A cursor used for pagination. */
	cursor: Scalars['String']['output'];
	/** The node represented by this edge. */
	node: Show;
};

/** The field/info to order titles by. */
export enum TvShowTrackingSorting {
	/** Sorted by title. */
	Alphabetical = 'ALPHABETICAL',
	/** Sorted by IMDB score. */
	ImdbScore = 'IMDB_SCORE',
	/** Sorted by last addition to the list. */
	LastAdded = 'LAST_ADDED',
	/** Sorted by JW popularity score. */
	Popular = 'POPULAR',
	/** Sorted randomly, based on the provided randomSeed. */
	Random = 'RANDOM',
	/** Sorted by release year. */
	ReleaseYear = 'RELEASE_YEAR',
	/** Sorted by last list interaction or upcoming date. */
	Relevance = 'RELEVANCE',
	/** Sorted by runtime. */
	Runtime = 'RUNTIME',
	/** Sorted by TMDB popularity. */
	TmdbPopularity = 'TMDB_POPULARITY',
	/** Sorted by JW trending score. */
	Trending = 'TRENDING',
}

/** The different types of tv show tracking view. */
export enum TvShowTrackingType {
	/** The shows that we're all done with. */
	CaughtUp = 'CAUGHT_UP',
	/** The shows that have been partly seen already. */
	ContinueWatching = 'CONTINUE_WATCHING',
	/** The shows that haven't been started. */
	NotStarted = 'NOT_STARTED',
}

/** A UserCentrics (GDPR consent manager) consent. */
export type UcConsent = {
	__typename?: 'UCConsent';
	/** Whether the user consented or not. */
	consent: Scalars['Boolean']['output'];
	/** The id of the UserCentrics resource. */
	id: Scalars['String']['output'];
};

/** The user centric consent. */
export type UcConsentsInput = {
	/** Denotes whether the user consented or not. */
	consent?: InputMaybe<Scalars['Boolean']['input']>;
	/** The ID of the UserCentrics resource. */
	id?: InputMaybe<Scalars['String']['input']>;
};

/** The input needed to get an affiliated link with or without tracking url from uct2. */
export type Uct2Input = {
	/** The monetization type of the offer. */
	MonetizationType?: InputMaybe<MonetizationType>;
	/** The clickid of a click. */
	clickID?: InputMaybe<Scalars['String']['input']>;
	/** The country of the website. */
	country: Scalars['String']['input'];
	/** Ct parameter for the affiliated link. */
	ct?: InputMaybe<Scalars['String']['input']>;
	/** The experiment groups for the user link. */
	experimentsGroups?: InputMaybe<Array<ExperimentGroup>>;
	/** If it's a free trial, as in was a free trial link was clicked. This can be a fallback offer or a regular offer. */
	isFreeTrial?: Scalars['Boolean']['input'];
	/** The entity id of the title that we are generating an affiliated link for. */
	jwEntityID: Scalars['String']['input'];
	/** The index of the item in the module for use in module snowplow context. */
	moduleIndex?: InputMaybe<Scalars['Int']['input']>;
	/** The name of the module for use in module snowplow context. */
	moduleName?: InputMaybe<Scalars['String']['input']>;
	/** The package id of the title that we are generating an affiliated link for. */
	packageID: Scalars['Int']['input'];
	/** The clear name of the package. */
	packageName?: InputMaybe<Scalars['String']['input']>;
	/** The page type from which the click originates. */
	pageType?: InputMaybe<Scalars['String']['input']>;
	/** The placement of the title we are generating an affiliated link for. */
	placement?: InputMaybe<Scalars['String']['input']>;
	/** The platform of the website. */
	platform: Platform;
	/** Target URL */
	targetURL?: InputMaybe<Scalars['String']['input']>;
	/** UserAgent of the user defined by the frontend. */
	userAgent?: InputMaybe<Scalars['String']['input']>;
};

/** Upcoming release data for a title. */
export type UpcomingRelease = {
	__typename?: 'UpcomingRelease';
	/** The release date display instructions. */
	label: UpcomingReleaseLabel;
	/** The package on which the title is being released. Null if unknown. */
	package?: Maybe<Package>;
	/** The number of days for the content to be available. */
	releaseCountDown?: Maybe<Scalars['Int']['output']>;
	/** The release date. Null if we don't know. */
	releaseDate?: Maybe<Scalars['Date']['output']>;
	/** The release type. */
	releaseType: ReleaseType;
};

/** Upcoming release data for a title. */
export type UpcomingReleaseReleaseCountDownArgs = {
	country: Scalars['Country']['input'];
};

/** The information that we should display to the users. */
export enum UpcomingReleaseLabel {
	/** Will be released over the given autumn. */
	Autumn = 'AUTUMN',
	/** Will be released on this precise date. */
	Date = 'DATE',
	/** Will be released over the given month. */
	Month = 'MONTH',
	/** Will be released over the given spring. */
	Spring = 'SPRING',
	/** Will be released over the given summer. */
	Summer = 'SUMMER',
	/** Will be released today. */
	Today = 'TODAY',
	/** Will be released tomorrow. */
	Tomorrow = 'TOMORROW',
	/** We know that it's upcoming but we don't know when yet. */
	ToBeAnnounced = 'TO_BE_ANNOUNCED',
	/** Will be released over the given winter. */
	Winter = 'WINTER',
	/** Will be released over the given year. */
	Year = 'YEAR',
}

/** The input when updating a custom list. */
export type UpdateCustomlistInput = {
	/** The list id to delete. */
	id: Scalars['ID']['input'];
	/** The name of the custom list. */
	name?: InputMaybe<Scalars['String']['input']>;
	/** The visibility of the list, i.e is it public or private. */
	visibility?: InputMaybe<GenericTitleListVisibility>;
};

/** The payload response of a custom list update. */
export type UpdateCustomlistPayload = {
	__typename?: 'UpdateCustomlistPayload';
	/** The updated title list. */
	titleList: GenericTitleList;
};

export type UpdateProfileInput = {
	/** The profile's avatar image, base64 encoded */
	avatarBase64?: InputMaybe<Scalars['String']['input']>;
	/** The bio text of the profile */
	bio?: InputMaybe<Scalars['String']['input']>;
	/** The country the user is based in. Used on the first update (create) of the profile */
	country?: InputMaybe<Scalars['Country']['input']>;
	/** Links to the users social media and other publications */
	externalUrls?: InputMaybe<Array<Scalars['String']['input']>>;
	/** The first name of the user */
	firstName?: InputMaybe<Scalars['String']['input']>;
	/** The last name of the user */
	lastName?: InputMaybe<Scalars['String']['input']>;
	/** The location of the Profile, as shown on profile page */
	location?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProfilePayload = {
	__typename?: 'UpdateProfilePayload';
	/** The created or updated profile */
	profile?: Maybe<Profile>;
};

/** The settings we want to update. If a field is null/undefined, it is ignored and the setting remains unchanged. */
export type UpdateSettingsInput = {
	/** Used to store the timeline specials hidden by the user. */
	hiddenTimelineSpecials?: InputMaybe<Array<Scalars['String']['input']>>;
	/** Stores the tracking consent data. */
	jwConsents?: InputMaybe<JwConsentsInput>;
	/** Indicates if the user has been onboarded before going to the Home. */
	tasteSurveyCompleted?: InputMaybe<Scalars['Boolean']['input']>;
	/** Indicates the Gefjon model that was used to recommend the onboarding titles. */
	tasteSurveyModelVersion?: InputMaybe<Scalars['String']['input']>;
	/** Indicates the type of onboarding the user took before going to the Home. */
	tasteSurveyType?: InputMaybe<TasteSurveyType>;
	/** Stores the UserCentrics concent data. */
	ucConsents?: InputMaybe<Array<UcConsentsInput>>;
};

/** The payload response of a settings update. */
export type UpdateSettingsPayload = {
	__typename?: 'UpdateSettingsPayload';
	/** Returns the packages available in your country on your platform. */
	packages: Array<Package>;
	/** The new state of the settings. */
	settings: Settings;
};

/** The payload response of a settings update. */
export type UpdateSettingsPayloadPackagesArgs = {
	country: Scalars['Country']['input'];
	hasSport?: InputMaybe<Scalars['Boolean']['input']>;
	hasTitles?: InputMaybe<Scalars['Boolean']['input']>;
	includeAddons?: InputMaybe<Scalars['Boolean']['input']>;
	iosPackages?: InputMaybe<Scalars['Boolean']['input']>;
	monetizationTypes?: InputMaybe<Array<MonetizationType>>;
	packageIds?: InputMaybe<Array<Scalars['Int']['input']>>;
	platform: Platform;
};

export type UpdateTextRecommendationInput = {
	/** Main body text of the text recommendation */
	body?: InputMaybe<Scalars['String']['input']>;
	/** Country - used to determine the locale for tag translations. Defaults to US */
	country?: InputMaybe<Scalars['Country']['input']>;
	/** Headline, short-text of the text recommendation */
	headline?: InputMaybe<Scalars['String']['input']>;
	/** The ID of the text recommendation being mutated */
	id: Scalars['String']['input'];
	/** Language - used to determine the locale for tag translations. Defaults to en */
	language?: InputMaybe<Scalars['Language']['input']>;
	/** Tags for the text recommendation. technicalNames are expected */
	tags?: InputMaybe<Array<Scalars['String']['input']>>;
	/** Time at which the entity was watched. If ommitted, defaults to now. Expected format is 2006-01-02T15:04:05.000Z */
	watchedAt?: InputMaybe<Scalars['Date']['input']>;
	/** ID of the package on which this was watched. Note, if ommitted this defaults to netflix */
	watchedOn?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTextRecommendationPayload = {
	__typename?: 'UpdateTextRecommendationPayload';
	/** The ID of the text recommendation being mutated - TO BE DEPRECATED */
	id: Scalars['String']['output'];
	/** The newly updated text recommendation */
	textRecommendation: TextRecommendation;
};

/** A URL. Defined by Content. */
export type Url = Node & {
	__typename?: 'Url';
	/**
	 * The full path of the URL.
	 *
	 * e.g. '/de/Film/Spider-Man-Far-from-Home'
	 */
	fullPath: Scalars['String']['output'];
	/** The h1 text. */
	heading1?: Maybe<Scalars['String']['output']>;
	/** The h2 text. */
	heading2?: Maybe<Scalars['String']['output']>;
	/** The href lang tags. They indicate the variants of the same content in the different locales. */
	hrefLangTags: Array<HrefLangTag>;
	/** A non typed json object allowing some hacks in the front-end. */
	htmlContent?: Maybe<Scalars['Json']['output']>;
	/** The global node ID. */
	id: Scalars['ID']['output'];
	/** The locale of the URL. */
	locale: Scalars['String']['output'];
	/** The meta description for search engines. */
	metaDescription?: Maybe<Scalars['String']['output']>;
	/** The meta keywords for search engines. */
	metaKeywords?: Maybe<Scalars['String']['output']>;
	/**
	 * The meta robots field for search engines.
	 *
	 * e.g. 'index,follow'
	 */
	metaRobots?: Maybe<Scalars['String']['output']>;
	/** The meta title for search engines. */
	metaTitle?: Maybe<Scalars['String']['output']>;
	/** The node linked behind the URL. */
	node: Node;
	/** The type of the object linked behind the URL. */
	objectType: ObjectType;
};

/** A URL. Defined by Content. */
export type UrlHrefLangTagsArgs = {
	locale?: InputMaybe<Scalars['String']['input']>;
};

export type UserMap = {
	__typename?: 'UserMap';
	lists: Array<ListMap>;
	user: Scalars['String']['output'];
};

/** Defines the permissions of a profile. */
export enum UserRole {
	/** Reserved for members of the JW team */
	Admin = 'ADMIN',
	/** A writer/author who can edit content */
	Editor = 'EDITOR',
	/** A prestigious user profile - they pay us so they earn more love */
	Pro = 'PRO',
	/** Your average user profile - nothing special but still loved */
	User = 'USER',
}

/** The user statistics. */
export type UserStats = {
	__typename?: 'UserStats';
	/** Indicates if the user has JW pro subscription. */
	hasJWProSubscription: Scalars['Boolean']['output'];
	/** Indicates if the user used his title lists already. */
	knowsAboutTitleLists: Scalars['Boolean']['output'];
	/** The max of custom lists the user can create as a normal user. */
	maxCustomListsDefault: Scalars['Int']['output'];
	/** The max of custom lists the user can create as a pro user. */
	maxCustomListsPro: Scalars['Int']['output'];
	/** The PAs that the user is part of. */
	parentPermanentAudiences: Array<Scalars['String']['output']>;
	/** The PAs that the user is part of. */
	permanentAudiences: Array<Scalars['String']['output']>;
	/** If the user's profile is complete. */
	profileIsComplete: Scalars['Boolean']['output'];
	/** The user's role(s). */
	userRole?: Maybe<UserRole>;
};

/** Video technologies. */
export enum VideoTechnology {
	/** Dolby Vision HDR video. */
	DolbyVision = 'DOLBY_VISION',
	/** Generic high dynamic range video. It could mean any HDR standard. */
	Hdr = 'HDR',
	/** HDR10 video. */
	Hdr10 = 'HDR10',
	/** HDR10+ video. */
	Hdr10Plus = 'HDR10_PLUS',
}

/** A subheading of the watch next list. */
export type WatchNextSubheading = {
	__typename?: 'WatchNextSubheading';
	/** The unique id of the subheading. */
	technicalName: WatchNextSubheadingTechnicalName;
	/** The translatable of the subheading. */
	translatable: Scalars['String']['output'];
};

/** The different subheadings possible on the edges of a watch next list. */
export enum WatchNextSubheadingTechnicalName {
	/** The shows under this category have been partly seen already. */
	ContinueWatching = 'CONTINUE_WATCHING',
	/** The shows under this category haven't been started. */
	NotStarted = 'NOT_STARTED',
}

/** This filter allows filtering of a list of offers. */
export type WatchNowOfferFilter = {
	/** Exclude the offers having the given packages. Short names are expected (e.g. 'nfx' for Netflix). */
	excludePackages?: InputMaybe<Array<Scalars['String']['input']>>;
	/**
	 * Keep only the offers that had their last price drop on that date.
	 *
	 * When used in the context of the NewTitles query, this filter is set by default to the date of the bucket. Null otherwise.
	 */
	lastChangeDate?: InputMaybe<Scalars['Date']['input']>;
	/**
	 * Keep only the offers having the given monetization types.
	 *
	 * In general, we set the default to only keep flatrate/free offers to make sure a Watch Now is "seamless".
	 */
	monetizationTypes?: InputMaybe<Array<MonetizationType>>;
	/** Keep only the offers having the given packages. Short names are expected (e.g. 'nfx' for Netflix). */
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
	/** Keep only the offers having the given presentation types. */
	presentationTypes?: InputMaybe<Array<PresentationType>>;
	/** WithFallback will return the first offer of the first package if no offer is found with the filter. */
	withFallback?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Represents a watch now offer override for a campaign. */
export type WatchNowOfferOverride = {
	__typename?: 'WatchNowOfferOverride';
	/** The id of the node. */
	id: Scalars['String']['output'];
	/** The watch now offer. */
	watchNowOffer: Offer;
};

/** This filter allows filtering of a list of offers. */
export type WatchNowSportOfferFilter = {
	/** Keep only the offers having the given packages. Short names are expected (e.g. 'nfx' for Netflix). */
	packages?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** The payload response of the Stripe subscription cancelation. */
export type CancelStripeSubscriptionPayload = {
	__typename?: 'cancelStripeSubscriptionPayload';
	/** The result for the stripe subscription cancelation request. */
	isSubscriptionCanceled: Scalars['Boolean']['output'];
};
