import { getVm } from '@/helpers/vm-helper';
import { ExperimentTrackEventOptions } from '../Core/types';
import AppleBuyboxPromotionExp, { AppleBuyboxPromotionExpName, AppleBuyboxPromotionExpVariants } from '.';

export const trackAppleBuyboxPromotionExp = (
	TrackingOptions: ExperimentTrackEventOptions<AppleBuyboxPromotionExpVariants>,
	lastRoute = false
) => {
	const vm = getVm();
	const activeVariant =
		vm?.$store.getters['experiment/activeVariantsWithControlGroup'][AppleBuyboxPromotionExpName] ?? null;
	const isUserLoggedIN = !!vm?.$store.getters['user/isLoggedIn'];
	if (activeVariant && !isUserLoggedIN) {
		AppleBuyboxPromotionExp.trackEvent({
			property: 'apple_buybox_exp',
			...TrackingOptions,
		});
	}
};
