import { computed } from 'vue';

import { useLanguageStore, useUserStore } from '@/helpers/composables/useStores';
import { useQuery } from '@/helpers/composables/useApollo';

import { captureMessageForSentry } from '@/helpers/sentry-helper';
import { GetUserStatsDocument, GetUserStatsQuery } from '@/graphql/queries/GetUserStats.query';
import type { DeclareComposableReturns } from '@/helpers/composables/types';
import { paToParentIDMap } from '../tracking/util/paParentMapping';

import {
	GetGenericListCountDocument,
	GetGenericListCountQuery,
	GetGenericListCountQueryVariables,
} from '@/graphql/queries/GetGenericListCount.query';
import { GenericTitleListsType, ProfileSpecificLinkType, UserRole } from '@/@types/graphql-types';
import {
	GetProfileByUrlDocument,
	GetProfileByUrlQuery,
	GetProfileByUrlQueryVariables,
} from '@/features/user-profile/graphql/queries/GetUserProfileByUrl.query';

export type DeclareUserStats = DeclareComposableReturns<typeof useUserStats>;

export function useUserStats() {
	const { jwId, isLoggedIn, isPremium, setPremiumStatus, setUserRole, setProfileInfo, setProfileIsComplete } =
		useUserStore();
	const { country, language } = useLanguageStore();

	const { onError, onResult, result, refetch, loading } = useQuery<GetUserStatsQuery>(GetUserStatsDocument, null, {
		errorPolicy: 'all',
		enabled: jwId.value != null,
	});

	onError(error =>
		captureMessageForSentry(
			'[GraphQL Get User Stats Data]:',
			{ error: error, where: 'Composable: useUserStats' },
			'error'
		)
	);

	onResult(({ data }) => {
		if (data?.stats) setPremiumStatus(data.stats.hasJWProSubscription);
		if (data?.stats?.userRole) setUserRole(data.stats.userRole);
	});

	if (isLoggedIn.value) {
		// SET PROFILE INFO IN USER STORE
		const profileInfoQuery = useQuery<GetProfileByUrlQuery, GetProfileByUrlQueryVariables>(
			GetProfileByUrlDocument,
			() => ({
				fullPath: null,
				country: country.value,
				language: language.value,
				firstArticles: 20,
				firstWtw: 10,
			})
		);

		profileInfoQuery.onError(error =>
			captureMessageForSentry(
				'[GraphQL Get User Profile Info Data]:',
				{ error: error, where: 'Composable: useUserProfile' },
				'error'
			)
		);

		profileInfoQuery.onResult(({ data }) => {
			if (data?.profile) {
				const profile = data?.profile;

				const profileInfo = {
					uuid: profile?.id,
					firstName: profile?.firstName,
					lastName: profile?.lastName,
					city: profile?.location,
					country: profile?.country,
					bio: profile?.bio,
					avatarUrl: profile?.avatarUrl,
					website: profile?.externalUrls?.find(item => item.type === ProfileSpecificLinkType.Website),
					altWebsite: profile?.externalUrls?.find(item => item.type === ProfileSpecificLinkType.Other),
					instagram: profile?.externalUrls?.find(item => item.type === ProfileSpecificLinkType.Instagram),
					imdb: profile?.externalUrls?.find(item => item.type === ProfileSpecificLinkType.Imdb),
					x: profile?.externalUrls?.find(item => item.type === ProfileSpecificLinkType.Twitter),
					tiktok: profile?.externalUrls?.find(item => item.type === ProfileSpecificLinkType.Tiktok),
					linkedin: profile?.externalUrls?.find(item => item.type === ProfileSpecificLinkType.Linkedin),
					facebook: profile?.externalUrls?.find(item => item.type === ProfileSpecificLinkType.Facebook),
				};

				setProfileInfo(profileInfo);
				setProfileIsComplete(profile?.isComplete);
			}
		});
	}

	const userStats = computed<GetUserStatsQuery['stats'] | undefined>(() => {
		if (!jwId.value || result.value?.stats == null) return undefined;
		return result.value.stats;
	});

	const maxCustomListsPro = computed(() => userStats.value?.maxCustomListsPro ?? 100);
	const maxCustomListsDefault = computed(() => userStats.value?.maxCustomListsDefault ?? 2);
	const customListsLimit = computed(() => (isPremium.value ? maxCustomListsPro.value : maxCustomListsDefault.value));

	const hasJWProSubscription = computed(() => userStats.value?.hasJWProSubscription ?? false);
	const userRole = computed(() => userStats.value?.userRole ?? UserRole.User);
	const knowsAboutTitleLists = computed(() => userStats.value?.knowsAboutTitleLists ?? false);

	const permanentAudiences = computed(() => userStats.value?.permanentAudiences ?? []);
	const permanentAudiencesWithoutPrefix = computed(
		() => userStats.value?.permanentAudiences.map(audienceID => audienceID.split('pa:')?.at(-1) || '') ?? []
	);

	const parentPermanentAudiences = computed(() => {
		return (
			permanentAudiencesWithoutPrefix.value.flatMap(audienceID =>
				(paToParentIDMap[audienceID] || []).map(pa => `pa:${pa.listId}`)
			) ?? []
		);
	});

	const genericListCountOptions = computed(
		() => ({ fetchPolicy: 'network-only', enabled: isLoggedIn.value } as const)
	);

	const customListsCountQuery = useQuery<GetGenericListCountQuery, GetGenericListCountQueryVariables>(
		GetGenericListCountDocument,
		{ type: GenericTitleListsType.CustomLists },
		genericListCountOptions
	);

	const customListsCount = computed(() => customListsCountQuery.result.value?.genericTitleLists.totalCount ?? 0);

	const followedListsCountQuery = useQuery<GetGenericListCountQuery, GetGenericListCountQueryVariables>(
		GetGenericListCountDocument,
		{ type: GenericTitleListsType.FollowedLists },
		genericListCountOptions
	);

	const followedListsCount = computed(() => followedListsCountQuery.result.value?.genericTitleLists.totalCount ?? 0);

	return {
		userRole,
		maxCustomListsPro,
		maxCustomListsDefault,
		customListsLimit,
		hasJWProSubscription,
		knowsAboutTitleLists,
		permanentAudiences,
		permanentAudiencesWithoutPrefix,
		parentPermanentAudiences,
		refetchUserStats: refetch,
		loading,

		customListsCount,
		refetchCustomListsCount: customListsCountQuery.refetch,

		followedListsCount,
		refetchFollowedListsCount: followedListsCountQuery.refetch,
	};
}
