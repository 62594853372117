import { MonetizationType } from '@/@types/graphql-types';
import { TitleOfferFragment } from '@/components/buybox/graphql/fragments/Offer.fragment';
import { WebLocale } from '@/enums/web-locale';
import { TitleDetail } from '@/interfaces/title-details-graphql';
import { MaybeRefOrGetter, toValue } from '@vueuse/core';
import { useTitleDetailsStore } from './composables/useStores';
import { computed, onMounted, onServerPrefetch } from 'vue';

type PromotionProviders = 9 | 119 | 337 | 1773 | 1899 | 531;

const promotionProviderIcons: Record<PromotionProviders, string> = {
	9: `${JW_CONFIG.IMAGESCALER_URL}/icon/52449539/s100/amazonprime.webp`,
	119: `${JW_CONFIG.IMAGESCALER_URL}/icon/52449539/s100/amazonprime.webp`,
	337: `${JW_CONFIG.IMAGESCALER_URL}/icon/313118777/s100/disneyplus.webp`,
	1773: `${JW_CONFIG.IMAGESCALER_URL}/icon/300772362/s100/skyshowtime.webp`,
	1899: `${JW_CONFIG.IMAGESCALER_URL}/icon/305458112/s100/max.webp`,
	531: `${JW_CONFIG.IMAGESCALER_URL}/icon/242706661/s100/paramountplus.webp`,
};

/** Provider promotions are only for titles with Flatrate and free offers of the promo provider. */
const flatrateOrFree = [MonetizationType.Flatrate, MonetizationType.Free];

interface PromoText {
	/** Set `null` to disable. */
	ribbon: string | null;
	/** Set `null` to disable. */
	offerText: string | null;
	/** Set `null` to disable. */
	longText: string | null;
	/** Optional promotion start date in `YYYY-MM-DD` format. */
	startDate?: ISO8601;
	/** `YYYY-MM-DD` format. */
	endDate: ISO8601;
	/** The ID of the promoted provider. */
	provider: PromotionProviders;
	/** The Url of the promoted provider which overrides the regular offer link. */
	url?: string;
}

/** Normal PromoText config with the Provider Icon URL */
export type PromoTextWithIcon = PromoText & { icon: string };

type PromotionCountries =
	| 'BO'
	| 'BR'
	| 'CL'
	| 'CO'
	| 'CZ'
	| 'DO'
	| 'DK'
	| 'EC'
	| 'ES'
	| 'FI'
	| 'GR'
	| 'GT'
	| 'HN'
	| 'HU'
	| 'MX'
	| 'NL'
	| 'NO'
	| 'NI'
	| 'PA'
	| 'PE'
	| 'PL'
	| 'PY'
	| 'RO'
	| 'SE'
	| 'SK'
	| 'SV'
	| 'TR'
	| 'US'
	| 'VE';

const promotions: Record<PromotionCountries, PromoText[]> = {
	BO: [
		{
			ribbon: 'OFERTA',
			offerText: 'Oferta por tiempo limitado',
			longText: 'Desde $3.99/mes por 4 meses',
			startDate: '2025-03-04',
			endDate: '2025-03-30',
			provider: 337,
		},
	],
	BR: [
		{
			ribbon: 'OFERTA',
			offerText: 'Oferta por tempo limitado',
			longText: 'A partir de R$9,90/mes por 4 meses',
			startDate: '2025-03-07',
			endDate: '2025-03-30',
			provider: 337,
		},
	],
	CL: [
		{
			ribbon: 'OFERTA',
			offerText: 'Oferta por tiempo limitado',
			longText: 'Desde CLP 3.900/mes por 4 meses',
			startDate: '2025-03-04',
			endDate: '2025-03-30',
			provider: 337,
		},
	],
	CO: [
		{
			ribbon: 'OFERTA',
			offerText: 'Oferta por tiempo limitado',
			longText: 'Desde COP 8.990/mes por 4 meses',
			startDate: '2025-03-07',
			endDate: '2025-03-30',
			provider: 337,
		},
	],
	CZ: [
		{
			ribbon: 'SLEVA',
			offerText: 'SLEVA',
			longText: 'SLEVA',
			startDate: '2025-03-10',
			endDate: '2025-03-31',
			provider: 337,
		},
	],
	DK: [
		{
			ribbon: 'SE NU',
			offerText: 'Stream nu',
			longText: 'Fra 49kr/måned',
			startDate: '2025-03-27',
			endDate: '2025-03-31',
			provider: 1773,
		},
	],
	DO: [
		{
			ribbon: 'OFERTA',
			offerText: 'Oferta por tiempo limitado',
			longText: 'Desde $3.99/mes por 4 meses',
			startDate: '2025-03-04',
			endDate: '2025-03-30',
			provider: 337,
		},
	],
	EC: [
		{
			ribbon: 'OFERTA',
			offerText: 'Oferta por tiempo limitado',
			longText: 'Desde $3.99/mes por 4 meses',
			startDate: '2025-03-04',
			endDate: '2025-03-30',
			provider: 337,
		},
	],
	ES: [
		{
			ribbon: 'VER',
			offerText: 'Ver ahora',
			longText: 'Desde 4,99€/mes',
			startDate: '2025-03-27',
			endDate: '2025-03-31',
			provider: 1773,
		},
	],
	FI: [
		{
			ribbon: 'KATSO',
			offerText: 'Katso nyt',
			longText: 'Alkaen 4,99 €/kk',
			startDate: '2025-03-27',
			endDate: '2025-03-31',
			provider: 1773,
		},
	],
	GR: [
		{
			ribbon: 'Offer',
			offerText: 'Offer',
			longText: 'Offer',
			startDate: '2025-03-10',
			endDate: '2025-03-31',
			provider: 337,
		},
	],
	GT: [
		{
			ribbon: 'OFERTA',
			offerText: 'Oferta por tiempo limitado',
			longText: 'Desde $3.99/mes por 4 meses',
			startDate: '2025-03-04',
			endDate: '2025-03-30',
			provider: 337,
		},
	],
	HN: [
		{
			ribbon: 'OFERTA',
			offerText: 'Oferta por tiempo limitado',
			longText: 'Desde $3.99/mes por 4 meses',
			startDate: '2025-03-04',
			endDate: '2025-03-30',
			provider: 337,
		},
	],
	HU: [
		{
			ribbon: 'AJÁNLAT',
			offerText: 'AJÁNLAT',
			longText: 'AJÁNLAT',
			startDate: '2025-03-10',
			endDate: '2025-03-31',
			provider: 337,
		},
	],
	MX: [
		{
			ribbon: 'OFERTA',
			offerText: 'Oferta por tiempo limitado',
			longText: 'Desde $59/mes por 4 meses',
			startDate: '2025-03-04',
			endDate: '2025-03-30',
			provider: 337,
		},
	],
	NI: [
		{
			ribbon: 'OFERTA',
			offerText: 'Oferta por tiempo limitado',
			longText: 'Desde $3.99/mes por 4 meses',
			startDate: '2025-03-04',
			endDate: '2025-03-30',
			provider: 337,
		},
	],
	NL: [
		{
			ribbon: 'WATCH',
			offerText: 'Stream nu',
			longText: 'Vanaf €5,99 p.m',
			startDate: '2025-03-27',
			endDate: '2025-03-31',
			provider: 1773,
		},
	],
	NO: [
		{
			ribbon: 'SE NÅ',
			offerText: 'Støm nå',
			longText: 'Fra 59 kr/måned',
			startDate: '2025-03-27',
			endDate: '2025-03-31',
			provider: 1773,
		},
	],
	PA: [
		{
			ribbon: 'OFERTA',
			offerText: 'Oferta por tiempo limitado',
			longText: 'Desde $3.99/mes por 4 meses',
			startDate: '2025-03-04',
			endDate: '2025-03-30',
			provider: 337,
		},
	],
	PE: [
		{
			ribbon: 'OFERTA',
			offerText: 'Oferta por tiempo limitado',
			longText: 'Desde s/15,90/mes por 4 meses',
			startDate: '2025-03-04',
			endDate: '2025-03-30',
			provider: 337,
		},
	],
	PL: [
		{
			ribbon: 'WATCH',
			offerText: 'Obejrzyj teraz',
			longText: 'Od 19,99 zł/miesięcznie',
			startDate: '2025-03-27',
			endDate: '2025-03-31',
			provider: 1773,
		},
	],
	PY: [
		{
			ribbon: 'OFERTA',
			offerText: 'Oferta por tiempo limitado',
			longText: 'Desde $3.99/mes por 4 meses',
			startDate: '2025-03-04',
			endDate: '2025-03-30',
			provider: 337,
		},
	],
	RO: [
		{
			ribbon: 'OFERTĂ',
			offerText: 'OFERTĂ',
			longText: 'OFERTĂ',
			startDate: '2025-03-10',
			endDate: '2025-03-31',
			provider: 337,
		},
	],
	SE: [
		{
			ribbon: 'SE NU',
			offerText: 'Streama nu',
			longText: 'Från 59kr/mån',
			startDate: '2025-03-27',
			endDate: '2025-03-31',
			provider: 1773,
		},
	],
	SK: [
		{
			ribbon: 'ZĽAVA',
			offerText: 'ZĽAVA',
			longText: 'ZĽAVA',
			startDate: '2025-03-10',
			endDate: '2025-03-31',
			provider: 337,
		},
	],
	SV: [
		{
			ribbon: 'OFERTA',
			offerText: 'Oferta por tiempo limitado',
			longText: 'Desde $3.99/mes por 4 meses',
			startDate: '2025-03-04',
			endDate: '2025-03-30',
			provider: 337,
		},
	],
	TR: [
		{
			ribbon: 'İndirim',
			offerText: 'İndirim',
			longText: 'İndirim',
			startDate: '2025-03-10',
			endDate: '2025-04-09',
			provider: 337,
		},
	],
	US: [
		{
			ribbon: 'Offer',
			offerText: 'Stream with free trial',
			longText: 'Stream with free trial',
			startDate: '2025-04-01',
			endDate: '2025-04-01',
			provider: 531,
		},
	],
	VE: [
		{
			ribbon: 'OFERTA',
			offerText: 'Oferta por tiempo limitado',
			longText: 'Desde $3.99/mes por 4 meses',
			startDate: '2025-03-04',
			endDate: '2025-03-30',
			provider: 337,
		},
	],
};

/** SSR Safe way to get active provider promotions. */
export function useProviderPromotion(title?: MaybeRefOrGetter<TitleDetail>) {
	const { setProviderPromotion, providerPromotion } = useTitleDetailsStore();

	onServerPrefetch(async () => await setProviderPromotion(toValue(title)));

	// refetch on client (no hydration error) to get the updated status
	onMounted(async () => await setProviderPromotion(toValue(title)));

	const titleHasProviderPromotion = computed(() => providerPromotion.value != null);

	function isPromotionOffer(offer: TitleOfferFragment) {
		return (
			flatrateOrFree.includes(offer.monetizationType) &&
			(providerPromotion.value as PromoTextWithIcon | null)?.provider === offer.package.packageId
		);
	}

	return {
		/** Is a provider promotion applicable to the current title. */
		titleHasProviderPromotion,

		/** Check if the offer is under the current title's provider promotion. */
		isPromotionOffer,

		/** The applicable promotion itself. */
		providerPromotion,
	};
}

export function getPromoText(
	country: string,
	title: TitleDetail | null = null,
	currentDate: Date = new Date()
): PromoTextWithIcon | null {
	const texts = promotions[country as PromotionCountries];

	if (!texts || texts.length === 0 || title == null || title.offers.length === 0) return null;

	const promoText = texts.find(promo =>
		title.offers.some(
			offer => flatrateOrFree.includes(offer.monetizationType) && offer.package.packageId === promo.provider
		)
	);

	if (promoText == null) return null;
	// Checking if we're within the promo dates
	if (promoText.startDate && +currentDate < new Date(promoText.startDate).setUTCHours(0, 0, 0, 0)) return null;
	if (promoText.endDate && +currentDate > new Date(promoText.endDate).setUTCHours(23, 59, 59, 999)) return null;

	return { ...promoText, icon: promotionProviderIcons[promoText.provider] };
}

// Disney External Pixel Tracking
const oceaniaLocales = new Set([WebLocale.au, WebLocale.nz]);
const noramLocales = new Set([WebLocale.us, WebLocale.ca]);
const latamLocales = new Set([
	WebLocale.br,
	WebLocale.mx,
	WebLocale.ar,
	WebLocale.cl,
	WebLocale.co,
	WebLocale.pe,
	WebLocale.uy,
	WebLocale.ec,
	WebLocale.pa,
	WebLocale.cr,
	WebLocale.py,
	WebLocale.bo,
	WebLocale.gt,
	WebLocale.ni,
	WebLocale.do,
	WebLocale.sv,
	WebLocale.hn,
	WebLocale.ve,
]);

export function getDisneyImpressionUrl(packageId: number | null | undefined, webLocale: WebLocale) {
	if (packageId === 337 /* Disney+ */) {
		if (oceaniaLocales.has(webLocale) || noramLocales.has(webLocale))
			return 'https://imp.pxf.io/i/1206980/1833790/9358';

		if (latamLocales.has(webLocale)) return 'https://imp.pxf.io/i/1206980/1848147/9358';
	}

	if (packageId === 619 /* Star+ */) {
		if (latamLocales.has(webLocale)) return 'https://imp.pxf.io/i/1206980/1584316/13591';
	}

	return null;
}

// TYPES
type digits = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
type day = `${'0'}${Exclude<digits, '0'>}` | `${'1' | '2'}${digits}` | '30' | '31';
type month = `${'0'}${Exclude<digits, '0'>}` | '10' | '11' | '12';
type year = `20${digits}${digits}`;
type ISO8601 = `${year}-${month}-${day}`;
